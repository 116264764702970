import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  SvgIcon,
  MobileStepper,
  Paper,
  SvgIconProps,
  Typography,
} from '@mui/material';
import SewageLocalizationForm from '@forms/SewageLocalizationForm';
import { styled } from '@mui/material/styles';
import TankDataForm from '@forms/TankDataForm';
import ContractsComponent from '@components/elements/ContractsComponent';
import TitleBar from '@components/elements/TitleBar';
import {
  CustomPaper,
  ErrorGradientButton,
  GradientButton,
} from '@styles/styled';
import { useFormik } from 'formik';
import CustomTextField from '@components/elements/CustomTextField';

import arrow from '../../../../public/icons/angle-down-solid.svg';
import edit from '../../../../public/icons/pen-to-square-solid.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, UserTanksInterface } from '@redux/interfaces';
import {
  addNewTankAction,
  deleteTankAction,
  disableLoading,
  getTankDetails,
  setNewTankName,
  setSelectedTankIdAction,
  updateTankLocalization,
} from '@redux/userCesspools/actions';
import { DeleteTankModal } from '@components/elements/DeleteTankModal';
import { getClientProperty } from '@redux/userCesspools/actions';
import { WarningModal } from '../../elements/CancelAddTankModal/WarningModal';
import validator from '@components/layouts/MySewageLayout/validator';
import { WarningModalContext } from '../../../context/MySewageContext';
import { mySewageInitialValues } from '@components/layouts/MySewageLayout/defaultValue';
import AssignVirtualControl from '@components/elements/AssignVirtualControle';
import VirtualControlStepper from '@components/modules/VirtualControl';
import { useLocation } from 'react-router-dom';
import ControlDetailsModal from '@components/elements/ControlDetailsModal';

interface IRotatedArrow extends SvgIconProps {
  rotated: number;
  component: any;
}

const RotatedArrow = styled(SvgIcon)<IRotatedArrow>`
  font-size: 40px;
  transition: 0.3s ease-in-out;
  transform: rotate(
    ${(p: { rotated: number }) => (p.rotated ? '90deg' : '270deg')}
  );
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: revert;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const PaperBox = styled(Paper)`
  margin: 32px 20px 0 0;
  padding: 24px;
  border-radius: 25px;
  box-shadow: 0px 16px 24px rgba(10, 60, 70, 0.16);
  position: relative;

  @media (max-width: 905px) {
    margin-right: 20px;
  }

  @media (max-width: 660px) {
    margin-left: 20px;
  }
`;

const PaperRowMobile = styled(CustomPaper)`
  flex-direction: column;
  display: flex;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const TankNameWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  margin-left: 20px;

  @media (max-width: 660px) {
    width: 100%;
    justify-content: center;
    margin-left: 0;
  }
`;

type ActionType = 'PREV' | 'NEXT' | 'ADD';

const MySewageLayout = () => {
  const [currentTankNumber, setCurrentTankNumber] = useState(0);
  const [deleteTankVisible, setDeleteTankVisible] = useState(false);
  const [actionToCall, setActionToCall] = useState<ActionType>('PREV');
  const [isNameValid, setIsNameValid] = useState(true);
  const [lockDelete, setLockDelete] = useState<boolean>(false);
  const [warningModalVisible, setWarningModalVisible] =
    useState<boolean>(false);

  const { clientProperty, currentTank, selectedTankId, newName } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  const { can_delete } = currentTank;

  const { state: params } = useLocation();
  const dispatch = useDispatch();

  const {
    tankLocationDifferent,
    tankDataDifferent,
    setTankNameDifferent,
    controlDetailsModalVisible,
    setControlDetailsModalVisible,
  } = useContext(WarningModalContext);

  const formik = useFormik({
    initialValues: mySewageInitialValues,
    validationSchema: validator,
    onSubmit: (values) => {},
  });

  const checkIfItCanByRemoveOrEdited = () => {
    const index = clientProperty.findIndex(
      (tank) => tank.id === selectedTankId
    );
    const property = clientProperty[index];
    setLockDelete(property?.has_order || property?.has_order_to_accept);
  };

  useEffect(() => {
    dispatch(getClientProperty());
    setControlDetailsModalVisible(false);
    document.body.style.removeProperty('overflow-y');
  }, []);

  useEffect(() => {
    if (clientProperty.length >= 1) {
      checkIfItCanByRemoveOrEdited();
    }
  }, [selectedTankId, clientProperty]);

  useEffect(() => {
    if (clientProperty.length >= 1) {
      if (params?.id) {
        dispatch(getTankDetails(`${params.id}/`));
        dispatch(setSelectedTankIdAction(params.id));
        const tankIndex = clientProperty.findIndex((p) => p.id === params.id);
        setCurrentTankNumber(tankIndex);
      } else {
        if (
          currentTankNumber !== undefined &&
          ![undefined, 0, null].includes(clientProperty[currentTankNumber]?.id)
        ) {
          dispatch(getTankDetails(`${clientProperty[currentTankNumber].id}/`));
        }
        dispatch(setSelectedTankIdAction(clientProperty[currentTankNumber].id));
      }
    } else {
      dispatch(disableLoading());
    }
  }, [clientProperty, currentTankNumber, params]);

  useEffect(() => {
    if (clientProperty.length > 0) {
      const newState = { currentTank: currentTank };

      formik.resetForm({ values: newState });
    }
    if (clientProperty.length === 0 || selectedTankId === 0) {
      const newState = {
        currentTank: { ...currentTank, cesspool_name: newName },
      };
      formik.resetForm({ values: newState });
    }
  }, [currentTank]);

  useEffect(() => {
    const { dirty } = formik;
    setTankNameDifferent(dirty);
  }, [formik]);

  const addNewTank = () => {
    const newTankData = {
      tank: mySewageInitialValues.currentTank,
      newProperty: {
        id: 0,
        get_address: '',
        cesspool_name: '',
        has_order: false,
        has_debt: false,
        has_order_to_accept: false,
      },
    };

    if (clientProperty.length < 5) {
      dispatch(addNewTankAction(newTankData));
    }
    setCurrentTankNumber(clientProperty.length);
  };

  const showDeleteNewInfo = (action: ActionType) => {
    if (
      selectedTankId === 0 ||
      tankDataDifferent ||
      tankLocationDifferent ||
      formik.dirty
    ) {
      setWarningModalVisible(true);
      setActionToCall(action);
    } else {
      changeOrAddTank(action);
    }
  };

  const changeOrAddTank = (action: ActionType) => {
    switch (action) {
      case 'PREV':
        prevStep();
        break;
      case 'NEXT':
        nextStep();
        break;
      case 'ADD':
        addNewTank();
        break;
    }
  };

  const nextStep = () => {
    if (currentTankNumber < clientProperty.length - 1) {
      setCurrentTankNumber((prevState) => prevState + 1);
    } else {
      setCurrentTankNumber(0);
    }
  };

  const prevStep = () => {
    if (currentTankNumber >= 1) {
      setCurrentTankNumber((prevState) => prevState - 1);
    } else {
      setCurrentTankNumber(clientProperty.length - 1);
    }
  };

  const removeTank = () => {
    dispatch(deleteTankAction(`${selectedTankId}/`));
    setCurrentTankNumber(0);
  };

  const updateName = () => {
    const { postal_code, city, province, locality_sym, street_sym } =
      currentTank;

    const data = {
      cesspool_name: formik.values.currentTank.cesspool_name,
      postal_code: postal_code,
      city: city,
      locality_sym: locality_sym,
      province: province,
      street_sym: street_sym,
    };

    validator
      .isValid({
        cesspool_name: formik.values.currentTank.cesspool_name,
      })
      .then((valid) => {
        if (valid) {
          setIsNameValid(true);
          if (selectedTankId === 0 || clientProperty.length === 0) {
            dispatch(setNewTankName(formik.values.currentTank.cesspool_name));
          } else {
            dispatch(updateTankLocalization(data, `${selectedTankId}/`));
          }
        } else {
          setIsNameValid(false);
        }
      });
  };

  return (
    <Box>
      {deleteTankVisible && (
        <DeleteTankModal
          exit={() => setDeleteTankVisible(false)}
          deleteTank={removeTank}
          tankName={formik.values.currentTank.cesspool_name}
        />
      )}
      {warningModalVisible && (
        <WarningModal
          warningMessage={
            <>
              Masz niezapisane zmiany, czy na pewno chcesz
              {selectedTankId === 0
                ? ' anulować dodawanie zbiornika?'
                : ' je anulować?'}
            </>
          }
          backButtonText={
            <>Wróć do {selectedTankId === 0 ? 'dodawania' : 'zbiornika'}</>
          }
          cancelButtonText={
            selectedTankId === 0 ? 'Anuluj dodawanie' : 'Anuluj zmiany'
          }
          exit={() => setWarningModalVisible(false)}
          cancelAdd={() => {
            setWarningModalVisible(false);
            changeOrAddTank(actionToCall);
            selectedTankId === 0 && dispatch(getClientProperty());
          }}
        />
      )}
      {controlDetailsModalVisible && <ControlDetailsModal />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <TitleBar>Mój zbiornik</TitleBar>
      </Box>
      <PaperRowMobile>
        <TankNameWrapper>
          <InputWrapper>
            {clientProperty.length > 1 && (
              <RotatedArrow
                rotated={1}
                component={arrow}
                viewBox="0 0 384 512"
                sx={{ mr: '5px', color: 'gray', cursor: 'pointer' }}
                onClick={() => {
                  showDeleteNewInfo('PREV');
                }}
              />
            )}

            <Box
              sx={{
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CustomTextField
                id="currentTank.cesspool_name"
                label="NAZWA ZBIORNIKA"
                value={formik.values.currentTank.cesspool_name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'currentTank.cesspool_name',
                    e.target.value
                  );
                }}
                onBlur={() => updateName()}
                error={!isNameValid}
                helperText={!isNameValid && 'Nazwa musi mieć od 1 do 15 znaków'}
                inputProps={{
                  endAdornment: (
                    <>
                      <SvgIcon
                        viewBox="0 0 512 512"
                        component={edit}
                        sx={{ color: 'gray' }}
                      />
                    </>
                  ),
                }}
              />
              <MobileStepper
                sx={{ backgroundColor: 'transparent' }}
                variant="dots"
                steps={clientProperty.length}
                position="static"
                activeStep={currentTankNumber}
                backButton={null}
                nextButton={null}
              />
            </Box>
            {clientProperty.length > 1 && (
              <RotatedArrow
                rotated={0}
                component={arrow}
                viewBox="0 0 384 512"
                sx={{ ml: '5px', color: 'gray', cursor: 'pointer' }}
                onClick={() => {
                  showDeleteNewInfo('NEXT');
                }}
              />
            )}
          </InputWrapper>

          <GradientButton
            sx={{ m: 2 }}
            disabled={
              clientProperty.length >= 5 ||
              clientProperty.length === 0 ||
              selectedTankId === 0
            }
            onClick={() => {
              showDeleteNewInfo('ADD');
            }}
            data-cy="addTank"
          >
            Dodaj zbiornik +
          </GradientButton>
        </TankNameWrapper>
      </PaperRowMobile>
      <Wrapper>
        <Box sx={{ flex: 1 }}>
          <PaperBox>
            <SewageLocalizationForm tankHaveActiveOrder={lockDelete} />
          </PaperBox>
          {(currentTank?.virtual_control_permission ||
            !!currentTank?.virtual_control_auth_code) && (
            <PaperBox>
              <AssignVirtualControl />
            </PaperBox>
          )}
          {currentTank.virtual_control_protocol && (
            <PaperBox>
              <VirtualControlStepper />
            </PaperBox>
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <PaperBox>
            <TankDataForm tankHaveActiveOrder={lockDelete} />
          </PaperBox>
          <PaperBox>
            <ContractsComponent />
          </PaperBox>
          <PaperBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {clientProperty.length === 1 && (
              <Typography sx={{ textAlign: 'center', fontWeight: 700 }}>
                To jedyny dostępny zbiornik. Możesz go edytować, ale nie usunąć.
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ErrorGradientButton
                sx={{ m: 1 }}
                onClick={() => setDeleteTankVisible(true)}
                disabled={
                  clientProperty.length <= 1 ||
                  selectedTankId === 0 ||
                  lockDelete ||
                  !can_delete
                }
              >
                Usuń zbiornik
              </ErrorGradientButton>
            </Box>
          </PaperBox>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default MySewageLayout;
