import React from 'react';
import Image from 'next/image';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import sciekiPolskieLogo from '../../../../public/images/scieki_polskie_logo.svg';
import { useSelector } from 'react-redux';
import { AuthApiResult, RootState } from '@redux/interfaces';

const Wrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media (min-width: 660px) and (max-width: 905px) {
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const Address = styled(Box)`
  border-left: 2px solid #fff;
  color: #fff;
  padding: 10px;
  margin-left: 10%;
  display: inline-block;

  @media (max-width: 660px) {
    margin: 15px 0;
    padding: 0 0 0 10px;
  }
`;

const FileLink = styled(Typography)`
  text-decoration: underline;
  color: #fff;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 0.5rem;

  @media (max-width: 660px) {
    font-size: 1rem;
  }
`;

const LeftWrapper = styled(Box)`
  display: flex;
  padding: 10px;
  margin-top: 10px;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  width: 50%;

  @media (max-width: 905px) {
    width: 80%;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

const RighWrapper = styled(LeftWrapper)`
  justify-content: right;

  @media (max-width: 905px) {
    border-top: 1px solid white;
    justify-content: left;
    padding: 10px;
    flex-direction: row;
  }
  @media (max-width: 660px) {
    justify-content: space-between;
  }
`;

const FixedTypography = styled(Typography)`
  @media (max-width: 660px) {
    display: inline-block;
  }
`;
const FixedComma = styled('span')`
  display: none;
  @media (max-width: 660px) {
    display: inline-block;
  }
`;

const LowerFooter = () => {
  const { documents } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const getDocumentUri = (type: string) => {
    const item = documents.find((item) => item.type_code === type);

    return item?.uri ? item.uri : '/';
  };

  return (
    <Wrapper>
      <LeftWrapper>
        <Box>
          <Image
            src={sciekiPolskieLogo}
            alt={'Ścieki Polskie'}
            width={213}
            height={44}
          />
        </Box>
        <Address>
          <Typography variant={'body2'}>
            Ścieki Polskie sp. z o. o.,{' '}
          </Typography>
          <FixedTypography variant={'body2'}>
            ul. F. Nowowiejskiego 39{' '}
          </FixedTypography>
          <FixedTypography variant={'body2'}>
            {<FixedComma>, </FixedComma>} 61-733 Poznań
          </FixedTypography>
        </Address>
      </LeftWrapper>
      <RighWrapper>
        <a
          target="_blank"
          rel="noreferrer"
          href={getDocumentUri('DOC_PROCESSING_PERSONAL_DATA')}
        >
          <FileLink>Polityka Prywatności</FileLink>
        </a>

        <a
          target="_blank"
          rel="noreferrer"
          href={getDocumentUri('DOC_APP_TERMS_AND_CONDITIONS')}
        >
          <FileLink>Regulamin strony</FileLink>
        </a>
      </RighWrapper>
    </Wrapper>
  );
};

export default LowerFooter;
