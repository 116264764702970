import React, { useContext, useEffect, useState } from 'react';
import { Container, Box, Typography } from '@mui/material';
import { GradientButton } from '@styles/styled';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import { toast } from 'react-toastify';
import { DashboardContext } from '../../../context/DashboardContext';
import { levelStartFA } from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';
import { useNavigate } from 'react-router-dom';
import theme from '@styles/theme';
import { getOrderPropositionDetails } from '@redux/order/actions';
import { getFormatedDate } from '@utils/formatDate';

const FlexibleBox = styled(Box)`
  width: 50%;
  height: 100%;
  padding: 10px 0 0 30px;
  text-align: left;

  @media (max-width: 600px) {
    width: 70%;
    padding: 20px 10px;
  }

  @media (max-width: 450px) {
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    margin-bottom: -20px;
  }
`;

const FlexibleBoxRight = styled(Box)`
  width: 50%;
  height: 100%;
  margin-top: -10px;
  min-height: 160px;

  @media (max-width: 450px) {
    width: 100%;
    margin-top: -30px;
  }
`;

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('/images/szambiara.svg');
  background-position: right;
  background-size: 90%;
  background-repeat: no-repeat;
  background-color: #e2eaef;
  border-radius: 20px;
  flex-flow: row wrap;
  height: 100%;

  @media (max-width: 450px) {
    background-position: bottom right;
    background-size: 140%;
  }
`;

const CustomText = styled(Typography)`
  width: auto;
  font-weight: 700;
  color: #506266;

  @media (max-width: 660px) and (min-width: 451px) {
    width: 85%;
  }
`;

const OrderExport = () => {
  const [label, setLabel] = useState<string>('Nadeszła pora na wywóz?');
  const [buttonText, setButtonText] = useState<string>('Zamów');
  const [disableButton, setDisabled] = useState<boolean>(false);

  const { handleOrderLayoutVisible } = useContext(DashboardContext);

  const { data, currentTank } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );

  const { clientProperty } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    client_properties: clientProperties,
    active_orders,
    invoice_unpaid,
  } = data;

  const handleOpen = () => {
    levelStartFA(types.STEP_0);

    if (clientProperties.length === 0) {
      navigate('/my-sewage');
    } else {
      document.body.style.overflowY = 'hidden';
      if (currentTank?.ask_order && currentTank.ask_order.id) {
        handleOrderLayoutVisible(true);
        dispatch(getOrderPropositionDetails(`${currentTank.ask_order.id}/`));
      } else {
        if (currentTank.has_permission_to_find_company) {
          const hasWaitingAskOrder = clientProperties.filter(
            (item) => item?.ask_order !== null
          );

          if (
            hasWaitingAskOrder.length >= 1 ||
            invoice_unpaid.length !== 0 ||
            active_orders.length !== 0
          ) {
            document.body.style.removeProperty('overflow-y');
            toast.error(
              'Nie możesz złożyć kolejnego zamówienia wywozu dopóki poprzednie nie zostanie zrealizowane.'
            );
          } else {
            handleOrderLayoutVisible(true);
          }
        } else {
          const freeProperties = clientProperty.filter(
            (item) => !item.has_order && !item.has_debt
          );

          if (freeProperties.length === 0) {
            toast.error('Nie masz żadnego dostępnego zbiornika');
          } else {
            handleOrderLayoutVisible(true);
          }
        }
      }
    }
  };

  const setTextOnButtonAndLabel = () => {
    setDisabled(false);

    if (clientProperties.length === 0) {
      setLabel('Najpierw dodaj adres zbiornika');
      setButtonText('Dodaj zbiornik');
    } else {
      if (currentTank?.ask_order) {
        const newAskOrder = ['nowe', 'w toku'].includes(
          currentTank.ask_order.status
        );

        setDisabled(newAskOrder);
        setLabel(
          `${
            newAskOrder ? 'Szukamy dla Ciebie' : 'Mamy dla Ciebie propozycję'
          } wywozu na dzień ${getFormatedDate(
            currentTank.ask_order.planned_date
          )}`
        );
        setButtonText('Sprawdź szczegóły');
      } else {
        setLabel('Nadeszła pora na wywóz?');
        setButtonText('Zamów');
      }
    }
  };

  useEffect(() => {
    setTextOnButtonAndLabel();
  }, [clientProperty, currentTank, data]);

  return (
    <Container disableGutters maxWidth="md" sx={{ borderRadius: '20px' }}>
      <Wrapper>
        <FlexibleBox
          sx={{
            pt: 4,
          }}
        >
          <CustomText variant="h5">{label}</CustomText>
          {clientProperties.length === 0 && (
            <Typography
              fontWeight={500}
              fontSize={14}
              color={theme.palette.secondary.main}
            >
              I DANE TECHNICZNE
            </Typography>
          )}
          <GradientButton
            disabled={disableButton}
            onClick={() => handleOpen()}
            orange={Boolean(currentTank?.ask_order)}
          >
            {buttonText}
          </GradientButton>
        </FlexibleBox>
        <FlexibleBoxRight />
      </Wrapper>
    </Container>
  );
};
export default OrderExport;
