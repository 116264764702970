'use client';

import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'analytics/firebaseConfig';

const vapidKey = process.env.VAPID_KEY;

export const firebaseApp = initializeApp(firebaseConfig);

const firebaseCloudMessaging = {
  init: async function () {
    try {
      const messaging = getMessaging(firebaseApp);
      const status = await Notification.requestPermission();

      if (status && status === 'granted') {
        const fcm_token = await getToken(messaging, {
          vapidKey: vapidKey,
        });
        if (fcm_token) {
          return fcm_token;
        }
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};

export { firebaseCloudMessaging };
