import { ErrorGradientButton, GradientButton } from '@styles/styled';
import React from 'react';
import { Typography, Box, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HistoryDropItemInterface } from '@redux/interfaces';
import { SmallRow } from '@components/elements/DropsGrid/styled';
import download from '../../../../public/icons/download.svg';
import theme from '@styles/theme';
import { getInvoice } from '@redux/myExports/actions';
import { useDispatch } from 'react-redux';
import ModalHeader from '@components/elements/ModalHeader';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import UniversalModalLayout from '@components/layouts/UniversalModalLayout';

export const Row = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 2px solid #ededed;
  gap: 5px;
  padding: 5px 0;
`;

export const Bolder = styled(Typography)`
  font-weight: bold;
`;

const OrderDetails = ({
  item,
  close,
  deleteRecord,
  editRecord,
  reportComplaint,
}: {
  item: HistoryDropItemInterface;
  close: () => void;
  deleteRecord: (item: HistoryDropItemInterface) => void;
  editRecord: () => void;
  reportComplaint: (item: HistoryDropItemInterface) => void;
}) => {
  const dispatch = useDispatch();

  const getStatus = (status: 'IN_PROGRESS' | 'DONE' | 'CANCELED' | 'FILL') => {
    switch (status) {
      case 'IN_PROGRESS':
        return 'W trakcie';
      case 'DONE':
        return 'Ukończony';
      case 'CANCELED':
        return 'Anulowany';
      case 'FILL':
        return 'Do uzupełnienia';
      default:
        return '-';
    }
  };

  const formatDate = (date: string) => {
    const separator = date.indexOf('T');
    return date.slice(0, separator);
  };

  return (
    <UniversalModalLayout>
      <ModalHeader title="Szczegóły" handleExit={close} />
      <Row>
        <Bolder>Operator</Bolder>
        <Typography>{item.sanitation_company_name}</Typography>
      </Row>
      <Row>
        <Bolder>Ilość</Bolder>
        <Typography>{item.amount ? `${item.amount} m³` : '-'}</Typography>
      </Row>
      <Row>
        <Bolder>Data</Bolder>
        <Typography>{formatDate(item.date)}</Typography>
      </Row>
      <Row>
        <Bolder>Koszt</Bolder>
        <Typography>{item.price ? `${item.price} zł` : '-'}</Typography>
      </Row>
      <Row>
        <Bolder>Status</Bolder>
        <Typography>{getStatus(item.status)}</Typography>
      </Row>
      {item.has_invoice && (
        <Row
          onClick={() => {
            dispatch(getInvoice(`${item.id}/`));
          }}
        >
          <Bolder>Faktura</Bolder>

          <SmallRow>
            <SvgIcon
              component={download}
              viewBox="0 0 18 18"
              sx={{ mr: 0.5 }}
            />
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                '-webkit-user-select': 'none',
                userSelect: 'none',
              }}
              color={theme.palette.primary.dark}
            >
              Pobierz
            </Typography>
          </SmallRow>
        </Row>
      )}

      {!item.added_by_user && item.status === 'DONE' && (
        <Row onClick={() => reportComplaint(item)}>
          <Bolder>Zgłoś reklamację</Bolder>

          <SmallRow>
            <QuestionAnswerIcon sx={{ mr: 0.5, color: '#3498db' }} />
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 'bold',
                '-webkit-user-select': 'none',
                userSelect: 'none',
              }}
            >
              {item.has_reported_complaint ? 'Pokaż' : 'Napisz'}
            </Typography>
          </SmallRow>
        </Row>
      )}

      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <GradientButton
          sx={{ fontSize: '14px', m: 0 }}
          disabled={!item.added_by_user}
          onClick={editRecord}
        >
          Edytuj
        </GradientButton>
        <ErrorGradientButton
          sx={{ fontSize: '14px' }}
          onClick={() => {
            deleteRecord(item);
          }}
          disabled={!item.added_by_user}
        >
          Usuń
        </ErrorGradientButton>
      </Box>
    </UniversalModalLayout>
  );
};

export default OrderDetails;
