import React, { useState } from 'react';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import { BorderButton, GradientButton } from '@styles/styled';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';

import exit from '../../../../public/icons/exit.svg';
import {
  HistoryDropItemInterface,
  SingleAgreementInterface,
} from '@redux/interfaces';
import { useDispatch } from 'react-redux';
import { deleteExport } from '@redux/myExports/actions';
import { deleteAgreement } from '@redux/agreements/actions';
import UniversalModalLayout from '@components/layouts/UniversalModalLayout';

const ExitIcon = styled(IconButton)`
  flex: 1;
  justify-content: flex-end;
  &:hover {
    background-color: #fff;
  }
`;

const Row = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const ButtonWrapper = styled(Row)`
  justify-content: ${(p: { isDeleted: boolean }) =>
    p.isDeleted ? 'space-between' : 'center'};
  padding: 10px 20px 30px 20px;
`;

const Description = styled(Typography)`
  width: 80%;
  margin: 35px auto;
  font-weight: bold;
  text-align: center;
`;

const Title = styled(Typography)`
  //background-color: yellow;
  //flex: 2;
  width: 100%;
  text-align: center;
  font-size: 48px;
  line-height: 42px;

  @media (max-width: 660px) {
    font-size: 36px;
    line-height: 32px;
  }
`;

type Props = {
  closeModal: () => void;
  item: HistoryDropItemInterface | SingleAgreementInterface;
  loading: boolean;
  label: string;
};

const DeleteRecord = ({ closeModal, item, loading, label }: Props) => {
  const [isDeleted, setIsDeleted] = useState(true);

  const dispatch = useDispatch();

  const handleDelete = () => {
    if ('date' in item) {
      dispatch(deleteExport(`${item.id}/`));
    } else {
      dispatch(deleteAgreement(`${item.id}/`));
    }
    setIsDeleted(false);
  };

  const formatDate = (date: string) => {
    if ('date' in item) {
      const separator = date.indexOf('T');
      return date.slice(0, separator);
    } else {
      return date;
    }
  };

  const returnMessage = () => {
    if ('date' in item) {
      return isDeleted
        ? `Czy na pewno chcesz usunąć usługę z dnia ${formatDate(
            item.date
          )} z historii wywozów?`
        : 'Informacje o usłudze zostały usunięte z listy.';
    } else if ('conclusion_date' in item) {
      return isDeleted
        ? `Czy na pewno chcesz usunąć umowę z dnia ${formatDate(
            item.conclusion_date
          )}?`
        : 'Umowa została usunięta z listy.';
    }
  };

  return (
    <UniversalModalLayout containerSize="sm">
      <Row>
        {isDeleted && <Box sx={{ flex: 1, height: '10px' }} />}
        <Title color={theme.palette.error.main}>{label}</Title>
        {isDeleted && (
          <ExitIcon
            onClick={() => {
              closeModal();
            }}
          >
            <SvgIcon
              component={exit}
              viewBox="0 0 64 36.77"
              sx={{ fontSize: 55 }}
            />
          </ExitIcon>
        )}
      </Row>
      <Description>
        {loading ? 'Trwa usuwanie...' : returnMessage()}
      </Description>
      <ButtonWrapper isDeleted={isDeleted}>
        <BorderButton onClick={() => closeModal()} loading={loading}>
          {isDeleted ? 'Nie usuwaj' : 'Zamknij okno'}
        </BorderButton>
        {isDeleted && (
          <GradientButton
            onClick={() => handleDelete()}
            sx={{ m: 0 }}
            loading={loading}
          >
            Usuń
          </GradientButton>
        )}
      </ButtonWrapper>
    </UniversalModalLayout>
  );
};

export default DeleteRecord;
