'use client';

import * as yup from 'yup';

export default yup.object({
  statute: yup
    .boolean()
    .required()
    .oneOf([true], 'Zaakceptuj regulamin aby kontynuować'),
});
