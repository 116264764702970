import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { ErrorGradientButton, GradientButton } from '@styles/styled';
import CallIcon from '@mui/icons-material/Call';
import { PanoramaInterface } from '@redux/interfaces';
import {
  levelEndFA,
  levelStartFA,
  selectContentFA,
} from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';

const Wrapper = styled(Box)`
  width: 90%;
  margin: 20px auto;

  @media (max-width: 660px) {
    width: 90%;
    margin: 20px 0 0 0;
    padding-bottom: 20px;
  }
`;

const CompanyName = styled(Typography)`
  font-size: 24px;
  text-align: center;
  font-weight: 300;
  line-height: 26px;
`;

const PhoneNumber = styled('a')`
  font-size: 30px;
  font-weight: bold;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

type Props = {
  driver: PanoramaInterface;
  close: () => void;
  nextStep: () => void;
};

const SecondStepNonApp = ({ driver, close, nextStep }: Props) => {
  const [editedNumber, setEditedNuber] = useState<string>('');

  const { phone_nb, name, id } = driver;

  const editPhoneNum = () => {
    const phoneNumString = String(phone_nb);

    if (phoneNumString.length > 9) {
      setEditedNuber(
        `+${phoneNumString.slice(0, 2)} ${phoneNumString.slice(
          2,
          5
        )}-${phoneNumString.slice(5, 8)}-${phoneNumString.slice(8, 11)}`
      );
      return 0;
    }
    setEditedNuber(
      `${phoneNumString.slice(0, 3)}-${phoneNumString.slice(
        3,
        6
      )}-${phoneNumString.slice(6, 9)}`
    );
  };

  useEffect(() => {
    editPhoneNum();
  });

  const handleClose = () => {
    levelEndFA(types.LEVEL_NAME_NUM[1], false);
    close();
  };

  const handleNext = () => {
    levelEndFA(types.LEVEL_NAME_NUM[1], true);
    levelStartFA(types.LEVEL_NAME_NUM[2]);
    nextStep();
  };

  return (
    <Wrapper>
      <CompanyName>{name}</CompanyName>
      <PhoneNumber
        href={`tel:+${phone_nb}`}
        onClick={() => {
          selectContentFA('open_phone_number', id);
        }}
      >
        <CallIcon sx={{ fontSize: '30px', mr: 1 }} />
        {editedNumber}
      </PhoneNumber>
      <Box>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Udało się zamówić wywóz?
        </Typography>
        <Typography>
          Zapisz termin w aplikacji aby otrzymać powiadomienie kiedy nadejdzie
          data wywozu.
        </Typography>
        <ButtonWrapper sx={{ mt: 6 }}>
          <ErrorGradientButton sx={{ m: 0 }} onClick={handleClose}>
            Nie
          </ErrorGradientButton>
          <GradientButton sx={{ m: 0 }} onClick={handleNext}>
            Tak
          </GradientButton>
        </ButtonWrapper>
      </Box>
    </Wrapper>
  );
};

export default SecondStepNonApp;
