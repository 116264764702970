import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const BackToLogin = () => (
  <Link to="/login" replace={true} data-cy="backToLogin">
    <Box
      color="#5b616a"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        pb: 2,
      }}
    >
      <ArrowBackIcon />
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
        }}
      >
        WRÓĆ DO LOGOWANIA
      </Typography>
    </Box>
  </Link>
);

export default BackToLogin;
