import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

interface IcoWrapperProps {
  active: number;
}

const IcoWrapper = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: ${({ active }: IcoWrapperProps) => (active ? '#fff' : '#506266')};
  background: ${({ active }: IcoWrapperProps) =>
    active
      ? 'linear-gradient(to bottom right, rgba(173, 193, 61, 1) 0%, rgba(87, 140, 34, 1) 100%) no-repeat'
      : '#E3E6E6'};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default IcoWrapper;
