'use client';

import theme from '@styles/theme';
import {
  Box,
  Container,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface IRotatedArrow extends SvgIconProps {
  rotated: number;
  component: any;
}

export const RotatedArrow = styled(SvgIcon)<IRotatedArrow>`
  font-size: 40px;
  transition: 0.3s ease-in-out;
  transform: rotate(
    ${(p: { rotated: number }) => (p.rotated ? '90deg' : '270deg')}
  );
  z-index: 900;
`;

export const FlexContainer = styled(Container)`
  padding: 25px;
  margin: 0 auto;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid ${theme.palette.primary.main};
  background-color: #f6f9fb;

  @media (max-width: 730px) and (min-width: 550px) {
    padding: 25px 33px;
  }
  @media (max-width: 550px) {
    padding: 25px 10px;
  }
`;

export const CounterBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const LeftSideWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  width: 100%;
`;

export const LeftTypography = styled(Typography)`
  margin-bottom: 24px;
  padding: 0;
  font-weight: bold;
  text-align: center;
  width: 100%;

  @media (max-width: 730px) {
    font-size: 16px;
    margin: 0 auto 12px auto;
  }
`;

export const NearerOrderText = styled(Box)`
  display: flex;
  margin-top: -40px;
  height: 32px;

  @media (max-width: 660px) and (min-width: 600px) {
    position: relative;
    top: -20px;
  }

  @media (max-width: 600px) {
    margin-bottom: -10px;
    margin-top: -5px;
    height: auto;
  }
`;

export const GaugeWrapper = styled('div')`
  order: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    order: 2;
    margin-bottom: -60px;
  }
`;

export const TopText = styled(LeftTypography)`
  @media (max-width: 905px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media (min-width: 660px) and (max-width: 730px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media (max-width: 660px) {
    font-size: 22px;
    line-height: 28px;
    width: 100%;
  }
`;

export const TopIntoGauge = styled(TopText)`
  @media (max-width: 905px) {
    text-align: center;
    margin-bottom: 0;
  }

  @media (max-width: 660px) {
    font-size: 38px;
  }
`;

export const TextIntoGauge = styled(Typography)`
  margin-bottom: 24px;
  padding: 0;
  font-weight: bold;
  text-align: center;
  width: 100%;

  @media (min-width: 660px) and (max-width: 905px) {
    text-align: center;
    font-size: 16px;
  }
  @media (min-width: 660px) and (max-width: 730px) {
    font-size: 14px;
    margin-bottom: 14px;
    width: 70%;
  }
  @media (max-width: 660px) {
    font-size: 14px;
  }
`;

export const InsideText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 2.2em;
  font-weight: 100;
  color: #555;
  user-select: none;
`;

export const SetPreviousDrop = styled(Box)`
  width: 80%;

  @media (max-width: 905px) {
    width: 70%;
  }
  @media (min-width: 660px) and (max-width: 785px) {
    width: 60%;
  }
`;

export const InfoText = styled(Typography)`
  font-size: 18px;

  @media (max-width: 905px) {
    font-size: 14px;
  }
  @media (min-width: 660px) and (max-width: 785px) {
    font-size: 12px;
  }
`;

export const TankNameText = styled(Typography)`
  font-size: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 35px;
  height: 35px;
  width: 200px;
  text-align: center;

  @media (max-width: 660px) {
    font-size: 20px;
  }

  @media (max-width: 300px) {
    font-size: 16px;
    width: 100%;
  }
`;

export const SelectorWrapper = styled('div')`
  @media (max-width: 1200px) {
    display: none;
  }
`;
