import { Box, Tooltip, Typography } from '@mui/material';
import React, { forwardRef, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState, UserTanksInterface } from '@redux/interfaces';

const BoxRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LeftSideText = styled(Typography)`
  width: auto;

  @media (max-width: 660px) {
    width: 60%;
  }
`;

const RightSideText = styled(Typography)`
  width: auto;

  @media (max-width: 660px) {
    width: 30%;
    text-align: right;
  }
`;

export const PriceListSingleLine = ({
  sx,
  left,
  right,
}: {
  sx?: any;
  left: string;
  right: string;
}) => (
  <BoxRow sx={{ ...sx }}>
    <LeftSideText>{left}</LeftSideText>
    <RightSideText>{right}</RightSideText>
  </BoxRow>
);

export const PriceList = ({
  data,
  priceList,
}: {
  data: any;
  priceList: any;
}) => {
  const { currentTank } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );
  const RefWrapper = forwardRef(function RefWrapper(
    props: { children: ReactNode },
    ref: React.ForwardedRef<any>
  ) {
    return (
      <div {...props} ref={ref}>
        {props.children}
      </div>
    );
  });

  return (
    <Box sx={{ pb: 1 }}>
      {data.firstLineTitle && (
        <PriceListSingleLine
          left={data.firstLineTitle}
          right={data.firstLineValue}
        />
      )}
      {data.secondLineTitle && (
        <PriceListSingleLine
          sx={{ pt: 1 }}
          left={data.secondLineTitle}
          right={data.secondLineValue}
        />
      )}
      {priceList.provision > 0 && (
        <PriceListSingleLine
          sx={{ pt: 1 }}
          left="Opłata serwisowa"
          right={`${priceList.provision} %`}
        />
      )}
      {data.additionalInfo && <p>{data.additionalInfo}</p>}
      {data.minimalPrice && (
        <Tooltip title={data.minimalPrice} placement="bottom-start">
          <RefWrapper>
            <PriceListSingleLine
              sx={{ pt: 1 }}
              left="Cena minimalna zamówienia"
              right={`${priceList.prices.minimal_price}zł`}
            />
          </RefWrapper>
        </Tooltip>
      )}
      {currentTank.has_discount && (
        <PriceListSingleLine
          sx={{ pt: 1 }}
          left="Zniżka na pierwsze zamówienie"
          right="- 10 zł"
        />
      )}
    </Box>
  );
};
