'use client';

import { NextPage } from 'next';
import AuthLayout from '@components/layouts/AuthLayout';
import ResetPasswordPageTemplate from '@components/templates/ResePasswordPageTemplate/ResetPasswordPageTemplate';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';

const ForgottenPasswordPage: NextPage = () => (
  <AuthBackgroundLayout>
    <AuthLayout maxWidth={'sm'}>
      <ResetPasswordPageTemplate />
    </AuthLayout>
  </AuthBackgroundLayout>
);

export default ForgottenPasswordPage;
