'use client';

import * as yup from 'yup';

export default yup.object({
  first_name: yup.string().when('isCorrectData', {
    is: false,
    then: yup
      .string()
      .required('Imię jest wymagane')
      .nullable()
      .min(3, 'Dane powinny mieć minimum 3 znaki')
      .max(50, 'Dane powinny mieć maksimum 50 znaków')
      .matches(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/, 'Imię nie może zawierać cyfr'),
  }),
  last_name: yup.string().when('isCorrectData', {
    is: false,
    then: yup
      .string()
      .required('Imię jest wymagane')
      .nullable()
      .min(3, 'Dane powinny mieć minimum 3 znaki')
      .max(50, 'Dane powinny mieć maksimum 50 znaków')
      .matches(/[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/, 'Imię nie może zawierać cyfr'),
  }),
});
