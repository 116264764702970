import React from 'react';
import { styled } from '@mui/material/styles';

import { SvgIcon } from '@mui/material';
import arrow from '../../../../public/icons/arrowTop.svg';

const SmallRow = styled('div')`
  display: flex;
  flex-direction: row;
`;

type Props = {
  sortKey: string;
  setSort: (sortKey: string, sortDirection: 'asc' | 'desc') => void;
};

const SortArrows = ({ setSort, sortKey }: Props) => (
  <SmallRow>
    <SvgIcon
      component={arrow}
      viewBox="0 0 24 24"
      onClick={() => setSort(sortKey, 'asc')}
      sx={{ cursor: 'pointer' }}
    />
    <SvgIcon
      component={arrow}
      viewBox="0 0 24 24"
      onClick={() => setSort(sortKey, 'desc')}
      sx={{ cursor: 'pointer', transform: 'rotateX(180deg)' }}
    />
  </SmallRow>
);

export default SortArrows;
