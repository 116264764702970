import { initializeApp, getApp, getApps } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { types } from './types';
import { firebaseConfig } from './firebaseConfig';
import { AnalyticsInfoT } from '@components/modules/OrderStepper/types';

const envType = process.env.NODE_ENV;
const showAnalyticLog = process.env.SHOW_ANALYTIC_LOGS;

export const firebaseApp = !getApps().length
  ? initializeApp(firebaseConfig)
  : getApp();
export const analytics =
  firebaseApp.name && typeof window !== 'undefined'
    ? getAnalytics(firebaseApp)
    : null;

const showLogs = envType !== 'production' && showAnalyticLog;

const logEv = (
  analytics: any,
  type: string,
  data: any,
  logInfo?: () => void
) => {
  if (analytics && envType === 'production') {
    logEvent(analytics, type, data);
  }
  if (showLogs) {
    logInfo && logInfo();
  }
};

const levelStartFA = (level_name: string) => {
  logEv(analytics, types.LEVEL_START, { level_name: level_name }, () =>
    console.log(`[LOG-FA: LEVEL_START | NAME: ${level_name}]`)
  );
};

const levelEndFA = (level_name: string, success: boolean) => {
  logEv(
    analytics,
    types.LEVEL_END,
    {
      level_name: level_name,
      success: success,
    },
    () =>
      console.log(
        `[LOG-FA: LEVEL_END | NAME: ${level_name} | SUCCESS: ${success}]`
      )
  );
};

const setCheckoutOptionFA = (
  checkout_option: string,
  checkout_step: number
) => {
  logEv(
    analytics,
    types.SET_CHECKOUT_OPTIONS,
    {
      checkout_option: checkout_option,
      checkout_step: checkout_step,
    },
    () =>
      console.log(
        `[LOG-FA: SET_CHECKOUT_OPTIONS | OPTION: ${checkout_option} | STEP: ${checkout_step}]`
      )
  );
};

const selectedItemFA = (
  item_list_name: string,
  item_list_id: string,
  items?: any
) => {
  logEv(
    analytics,
    types.SELECT_ITEM,
    {
      item_list_id: item_list_id,
      item_list_name: item_list_name,
    },
    () =>
      console.log(
        `[LOG-FA: SELECT_ITEM | ITEM_NAME: ${item_list_name} | ITEM_ID: ${item_list_id}]`
      )
  );
};

const selectContentFA = (
  content: string,
  id: string | number,
  optional?: any
) => {
  logEv(
    analytics,
    types.SELECT_CONTENT,
    {
      content_type: content,
      ...(id && { item_id: id }),
      ...optional,
    },
    () =>
      console.log(
        `[LOG-FA: SELECT_CONTENT | CONTENT_TYPE: ${content} | ITEM_ID: ${id}]`,
        optional
      )
  );
};

const addToCartFA = (data: AnalyticsInfoT) => {
  logEv(analytics, types.ADD_TO_CART, data, () =>
    console.log(`[LOG-FA: ADD_TO_CART | DATA: `, data)
  );
};
const removeFromCartFA = (data: AnalyticsInfoT) => {
  logEv(analytics, types.REMOVE_FROM_CART, data, () =>
    console.log(`[LOG-FA: REMOVE_FROM_CART | DATA: `, data)
  );
};

const beginCheckoutFA = (data: AnalyticsInfoT) => {
  logEv(analytics, types.BEGIN_CHECKOUT, data, () =>
    console.log(`[LOG-FA: BEGIN_CHECKOUT | DATA: `, data)
  );
};

const pageViewFA = (data: { page_title: string }) => {
  logEv(analytics, types.PAGE_VIEW, data, () =>
    console.log(`[LOG-FA: PAGE_VIEW | DATA: `, data)
  );
};

export {
  levelEndFA,
  levelStartFA,
  setCheckoutOptionFA,
  selectedItemFA,
  addToCartFA,
  beginCheckoutFA,
  removeFromCartFA,
  selectContentFA,
  pageViewFA,
};
