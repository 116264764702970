import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Box } from '@mui/material';

import { emailValidator } from '@forms/ResetPassword/ResetPasswordFormFirstStep/validator';
import {
  getCaptchaRequest,
  getCaptchaRequestFailure,
  resetPasswordAction,
} from '@redux/auth/actions';
import { RegisterApiResult, RootState } from '@redux/interfaces';

import { GradientButton } from '@styles/styled';
import useReCaptchaVerify from '@hooks/useReCaptchaVerify';

const ResetPasswordFormFirstStep: React.FC = () => {
  const { loading } = useSelector<RootState, RegisterApiResult>(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  const initialValues: { email: string } = {
    email: '',
  };

  const getCaptcha = useReCaptchaVerify('RESET_PASSWORD_REQUEST');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: emailValidator,
    onSubmit: (values) => {
      dispatch(getCaptchaRequest());

      getCaptcha().then((token) => {
        if (token && token?.length < 200) {
          dispatch(getCaptchaRequestFailure());
          return;
        }
        const data = {
          email: values.email,
          recaptcha: token,
        };
        dispatch(resetPasswordAction(data));
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        id="email"
        name="email"
        label="Adres E-mail"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        inputProps={{ 'data-cy': 'email' }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GradientButton
          type="submit"
          variant="contained"
          sx={{ mt: 3, mb: 3 }}
          loading={loading}
          disabled={loading}
          data-cy="submit_register"
        >
          Wyślij link
        </GradientButton>
      </Box>
    </form>
  );
};

export default ResetPasswordFormFirstStep;
