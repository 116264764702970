'use client';

import { CurrentTankInterface } from '@redux/interfaces';

export const mySewageInitialValues: { currentTank: CurrentTankInterface } = {
  currentTank: {
    cesspool_name: '',
    postal_code: '',
    city: '',
    locality_sym: '',
    street: '',
    street_sym: 0,
    house_nb: '',
    flat_nb: '',
    geoposition: '',
    cesspoolfeatures: {
      sewage_group: '',
      sewage_type: '',
      cesspool_size: '',
      tank_type: '',
      resident_count: '',
      sewage_system: false,
      cesspool_tank_installation_date: '',
      registered_people_count: '',
      sewage_system_connection_date: '',
      water_source: false,
    },
    ossffeatures: null,
    agreement_set: [],
    province: { name: '', code: '0' },
    can_delete: true,
    has_discount: false,
    virtual_control_auth_code: '',
    virtual_control_commune_name: '',
    virtual_control_permission: false,
    virtual_control_protocol: null,
  },
};
