import React, { useRef } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import download from '../../../../public/icons/download.svg';
import unactiveIcon from '../../../../public/icons/redX.svg';
import successfull from '../../../../public/icons/successfull.svg';
import {
  SmallRow,
  GridContainer,
  GridItem,
  ShortertItem,
  DisabledOnMobile,
  UltraShort,
} from '@components/elements/ContractsComponent/styled';
import theme from '@styles/theme';
import { SingleAgreementInterface } from '@redux/interfaces';
import { downloadAgreement } from '@redux/agreements/actions';
import garbage from '../../../../public/icons/garbage.svg';
import { DisableOnMobile } from '@components/elements/DropsGrid/styled';

type Props = {
  item: SingleAgreementInterface;
  getDetails: (v: SingleAgreementInterface) => void;
  deleteAgreement: (v: SingleAgreementInterface) => void;
};

export const SingleLine = ({ item, getDetails, deleteAgreement }: Props) => {
  const dispatch = useDispatch();

  const deleteRef = useRef(null);
  const invoiceRef = useRef(null);

  const icon = (value: any, isActive: boolean = false) => (
    <SmallRow>
      <SvgIcon component={value} viewBox="0 0 18 18" sx={{ mr: 0.5 }} />
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'bold',
        }}
      >
        {isActive ? 'Aktywna' : 'Nieaktywna'}
      </Typography>
    </SmallRow>
  );

  const showStatus = () => {
    if (item?.expiration_date) {
      const dateOfExecution = Date.parse(item.expiration_date);

      let currentDate: any = new Date();
      currentDate =
        currentDate.getFullYear() +
        '/' +
        (currentDate.getMonth() + 1) +
        '/' +
        currentDate.getDate();
      currentDate = Date.parse(currentDate);

      if (Number(currentDate) > Number(dateOfExecution)) {
        return icon(unactiveIcon);
      } else if (Number(currentDate) <= Number(dateOfExecution)) {
        return icon(successfull, true);
      }
    }

    return icon(successfull, true);
  };

  const clickToEdit = (event: any) => {
    if (
      deleteRef.current &&
      // @ts-ignore
      !deleteRef.current?.contains(event.target) &&
      invoiceRef.current &&
      // @ts-ignore
      !invoiceRef.current?.contains(event.target)
    ) {
      getDetails(item);
    }
  };

  return (
    <GridContainer
      clickable={item.added_by_user}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => clickToEdit(e)}
    >
      <ShortertItem>
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          {item.conclusion_date}
        </Typography>
      </ShortertItem>
      <GridItem>
        <Typography variant="subtitle2">{item.company_name}</Typography>
      </GridItem>
      <DisabledOnMobile>{showStatus()}</DisabledOnMobile>
      <UltraShort ref={invoiceRef}>
        <SmallRow
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            dispatch(downloadAgreement(`${item.id}/download`));
          }}
        >
          <SvgIcon component={download} viewBox="0 0 18 18" sx={{ mr: 0.5 }} />
          <DisabledOnMobile>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: '700' }}
              color={theme.palette.primary.dark}
            >
              Pobierz
            </Typography>
          </DisabledOnMobile>
        </SmallRow>
      </UltraShort>
      <DisableOnMobile sx={{ cursor: 'pointer' }} ref={deleteRef}>
        {item.added_by_user ? (
          <Box onClick={() => deleteAgreement(item)}>
            <SvgIcon component={garbage} viewBox="0 0 24 24" />
          </Box>
        ) : (
          <Typography>Brak</Typography>
        )}
      </DisableOnMobile>
    </GridContainer>
  );
};
