'use client';

import { NextPage } from 'next';
import DashboardLayout from '@components/layouts/DashboardLayout';
import DropsGridLayout from '@components/layouts/DropsGridLayout';

const MyDrops: NextPage = () => (
  <DashboardLayout>
    <DropsGridLayout />
  </DashboardLayout>
);

export default MyDrops;
