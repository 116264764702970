'use client';

import Dashboard from '../../public/icons/dashboard.svg';
import Sewage from '../../public/icons/sewage.svg';
import Calendar from '../../public/icons/calendar.svg';
import Card from '../../public/icons/card.svg';
import Info from '../../public/icons/info.svg';
import Avatar from '../../public/icons/avatar.svg';
import Lock from '../../public/icons/lock.svg';

export default [
  {
    path: '/dashboard',
    svgIco: Dashboard,
    title: 'Pulpit',
  },
  {
    path: '/my-exports',
    svgIco: Calendar,
    title: 'Moje wywozy',
  },
  {
    path: '/my-sewage',
    svgIco: Sewage,
    title: 'Mój zbiornik',
  },
  {
    path: '/settings',
    svgIco: Avatar,
    title: 'Ustawienia konta',
  },
  {
    path: '/payments',
    svgIco: Card,
    title: 'Dane płatności ',
  },
  {
    path: '/help',
    svgIco: Info,
    title: 'Pomoc',
  },
  {
    path: '/login',
    svgIco: Lock,
    title: 'Wyloguj',
  },
];
