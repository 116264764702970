import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Container,
} from '@mui/material';
import {
  BorderButton,
  CustomPaper,
  GradientButton,
  RowWrap,
} from '@styles/styled';
import { ExitIcon } from '@components/elements/ExitIcon';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { OrderInterface, PaymentInterface, RootState } from '@redux/interfaces';
import {
  getCreditCard,
  getPosId,
  invoicePaidSuccessfully,
  payForInvoice,
  resetIsPaid,
} from '@redux/payment/actions';
import { useOnScreen } from '@hooks/useOnScreen';
import { toast } from 'react-toastify';
import { getDashboardInfo } from '@redux/dashboard/actions';
import { Skeleton } from '@mui/material';
import PaymentSummary from '@components/elements/PayForOrderModal/PaymentSummary';
import theme from '@styles/theme';
import { useNavigate } from 'react-router-dom';
import { globalNavigate } from '../../../navigation/globalHistory';
import { getPayu, getSecureForm, resetPayu } from '@utils/payuHelpers';

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: left;
  }
`;

const CustomFormControl = styled(FormControl)`
  width: 70%;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const PaymentWrapper = styled(Box)`
  position: absolute;
  overflow: auto;
  height: 100vh;
  max-width: 100vw;

  @media (max-width: 960px) {
    width: 70vw;
  }

  @media (max-width: 660px) {
    width: 100%;
    padding-bottom: 120px;
    top: 0;
  }
`;

const CustomPaperMobile = styled(CustomPaper)`
  padding: 0 16px;

  @media (max-width: 660px) {
    padding: 0;
  }
`;

const CardSelectorWrapper = styled(Box)`
  padding: 10px 0;

  @media (max-width: 660px) {
    border: none;
    padding: 0 0;
  }
`;

const optionsForms = {
  cardIcon: true,
  style: {
    basic: {
      fontSize: '24px',
    },
  },
  placeholder: {
    cvv: 'CVV',
  },
  lang: 'pl',
};

let cardCvv: any;

export const PayForOrderModal = () => {
  const [selectedCard, setSelectedCard] = useState('-1');
  const [cvvReq, setCvvReq] = useState<string | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [cvvRendered, setCvvRendered] = useState<any>(null);
  const [paymentSelection, setPaymentSelection] = useState<string>('T_PAYU');

  const closeModal = () => globalNavigate('dashboard');

  const cvvRef = useRef(null);
  const cvvOnScreen = useOnScreen(cvvRef);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { creditCard, loading, isPaid, cvvRedirectUri, posId } = useSelector<
    RootState,
    PaymentInterface
  >((state) => state.payment);
  const { invoiceToPay } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(getCreditCard());
    dispatch(getPosId());
  }, []);

  const handlePayu = () => {
    const _payu = getPayu(posId);

    _payu
      .sendCvv(cvvReq)
      .then(({ status }: { status: string }) => {
        if (status === 'SUCCESS') {
          toast.success('Udało się opłacić wywóz 🥳');
          dispatch(invoicePaidSuccessfully());
          dispatch(getDashboardInfo());
        }
      })
      .catch((e: any) => {
        console.error('Błąd przy autoryzacji płatności', e);
      });
  };

  const handlePaymentButton = () => {
    switch (paymentSelection) {
      case 'T_CARD':
        if (!isPaid) {
          if (invoiceToPay && !cvvReq && selectedCard !== '-1') {
            dispatch(
              payForInvoice(`${invoiceToPay.id}/`, {
                card_id: selectedCard,
              })
            );
          } else if (cvvReq) {
            handlePayu();
          } else {
            setShowError(true);
          }
        } else {
          dispatch(getDashboardInfo());
          dispatch(resetIsPaid());
          closeModal();
        }
        break;
      default:
        dispatch(
          payForInvoice(`${invoiceToPay.id}/`, {
            card_id: null,
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (cvvRedirectUri && posId && !cvvRendered) {
      const _payu = getPayu(posId);
      setCvvReq(_payu.extractRefReqId(cvvRedirectUri));

      if (cvvReq && cvvOnScreen) {
        const _secureForm = getSecureForm(posId);
        cardCvv = _secureForm.add('cvv', optionsForms);
        cardCvv.render('#payu-card-cvv');
        setCvvRendered(cardCvv);
      }
    }
  }, [cvvRedirectUri, posId, cvvReq, cvvOnScreen]);

  useEffect(() => {
    setSelectedCard('-1');
  }, [creditCard]);

  useEffect(() => {
    resetPayu();
    cardCvv = null;
    setCvvReq(null);
  }, []);

  return (
    <PaymentWrapper>
      <CustomPaperMobile>
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={0} md={2} />
          <Grid item xs={8} sx={{ textAlign: 'center' }} color="black">
            <Title variant={isPaid ? 'h5' : 'h4'}>
              {isPaid ? 'Pomyślnie opłacono wywóz' : 'Opłać wywóz'}
            </Title>
          </Grid>
          <Exit
            item
            xs={4}
            md={2}
            sx={{ textAlign: 'right' }}
            onClick={closeModal}
          >
            <ExitIcon />
          </Exit>
        </Grid>
        <PaymentSummary />
        <FormControl
          margin="normal"
          id="paymentType"
          sx={{
            padding: '0px 2rem',
            '@media (max-width: 660px)': {
              padding: '0 0',
            },
          }}
        >
          <Container maxWidth={false}>
            <RadioGroup>
              <Box>
                {!isPaid && (
                  <Box>
                    <FormControlLabel
                      value="T_PAYU"
                      control={<Radio />}
                      checked={paymentSelection === 'T_PAYU'}
                      onChange={() => {
                        setPaymentSelection('T_PAYU');
                      }}
                      label={
                        <Typography fontWeight={'bold'}>
                          Wybierz szybkie płatności PayU
                        </Typography>
                      }
                    />
                    <FormHelperText>BLIK, GOOGLE PAY, PRZELEW</FormHelperText>
                  </Box>
                )}
                <Box>
                  {!isPaid && (
                    <FormControlLabel
                      value="T_CARD"
                      control={<Radio />}
                      checked={paymentSelection === 'T_CARD'}
                      onChange={() => {
                        setPaymentSelection('T_CARD');
                      }}
                      label={
                        <Typography
                          fontWeight={'bold'}
                          color={creditCard.length ? 'black' : 'secondary'}
                        >
                          Wybierz kartę płatniczą
                        </Typography>
                      }
                      disabled={creditCard.length === 0}
                    />
                  )}
                  <CardSelectorWrapper>
                    {creditCard.length >= 1 && !cvvReq && !isPaid && (
                      <CustomFormControl fullWidth>
                        <InputLabel id="paymentMethodLabel">
                          Karta płatnicza
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="paymentMethodLabel"
                          id="selectPaymentMethod"
                          label="Karta płatnicza"
                          variant="outlined"
                          name="selectPaymentMethod"
                          value={selectedCard}
                          MenuProps={{
                            disableScrollLock: true,
                          }}
                          onChange={(e) => {
                            setSelectedCard(e.target.value);
                            setShowError(false);
                            setPaymentSelection('T_CARD');
                          }}
                        >
                          <MenuItem key="empty" value="-1">
                            Wybierz kartę
                          </MenuItem>
                          {creditCard.map((card) => (
                            <MenuItem key={card.card_id} value={card.card_id}>
                              {card.number_masked}
                            </MenuItem>
                          ))}
                        </Select>
                        {showError && (
                          <Typography
                            color={theme.palette.error.main}
                            variant="subtitle2"
                            sx={{ fontWeight: '600' }}
                          >
                            Najpierw wybierz kartę
                          </Typography>
                        )}
                      </CustomFormControl>
                    )}
                    {loading && creditCard.length === 0 && !isPaid && (
                      <Box>
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: '1rem', width: '30%' }}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={210}
                          height={40}
                        />
                      </Box>
                    )}

                    <Box
                      sx={{
                        display: `${cvvReq && !isPaid ? 'unset' : 'none'}`,
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', mb: 2 }}>
                        Kod CVV
                      </Typography>
                      <div
                        id="payu-card-cvv"
                        ref={cvvRef}
                        className="payu-card-form"
                      ></div>
                    </Box>

                    <RowWrap
                      sx={{ mt: 3 }}
                      data={creditCard.length === 0}
                      propsWidth="1400px"
                    >
                      {creditCard.length === 0 && !loading && !isPaid && (
                        <BorderButton
                          sx={{ m: '5px' }}
                          onClick={() => {
                            closeModal();
                            navigate('/payments');
                          }}
                        >
                          Dodaj kartę płatniczą
                        </BorderButton>
                      )}
                      <GradientButton
                        sx={{ m: '5px' }}
                        loading={loading}
                        onClick={handlePaymentButton}
                      >
                        {isPaid ? 'Powrót' : 'Zapłać'}
                      </GradientButton>
                    </RowWrap>
                  </CardSelectorWrapper>
                </Box>
              </Box>
            </RadioGroup>
          </Container>
        </FormControl>
      </CustomPaperMobile>
    </PaymentWrapper>
  );
};
