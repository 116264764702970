'use client';

import { types } from './types';
import { DashboardPropertiesInterface } from '@redux/interfaces';

export const getDashboardInfo = () => {
  return {
    method: 'GET',
    type: types.GET_DASHBOARD_INFO_REQUEST,
  };
};

export const setFirebaseToken = (data: { registration_token: string }) => {
  return {
    method: 'POST',
    type: types.SET_FIREBASE_TOKEN_REQUEST,
    data,
  };
};

export const deleteFirebaseToken = (data: { registration_token: string }) => {
  return {
    method: 'DELETE',
    type: types.DELETE_FIREBASE_TOKEN_REQUEST,
    data,
  };
};

export const getUnreadNotifications = (data?: { page: number }) => {
  return {
    method: 'GET',
    type: types.GET_UNREAD_NOTIFICATIONS_REQUEST,
    data,
  };
};

export const setReadNotifications = (data: { id_list: Array<string> }) => {
  return {
    method: 'PATCH',
    type: types.SET_READ_NOTIFICATIONS_REQUEST,
    data,
  };
};

export const setCurrentTankAction = (data: DashboardPropertiesInterface) => {
  return {
    type: types.SET_CURRENT_TANK,
    data,
  };
};
