import { styled } from '@mui/material/styles';
import { Box, Skeleton } from '@mui/material';

const Wrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  height: 35px;
`;

const SingleLineSkeleton = () => {
  return (
    <Wrapper>
      <Box sx={{ width: '12%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '85%' }} />
      </Box>
      <Box sx={{ width: '27%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
      </Box>
      <Box sx={{ width: '10%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '25%' }} />
      </Box>
      <Box sx={{ width: '12%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '65%' }} />
      </Box>
      <Box sx={{ width: '17%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%' }} />
      </Box>
      <Box sx={{ width: '13%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
      </Box>
      <Box sx={{ width: '9%' }}>
        <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
      </Box>
    </Wrapper>
  );
};

const DropsSkeleton = () => (
  <>
    <SingleLineSkeleton />
    <SingleLineSkeleton />
    <SingleLineSkeleton />
    <SingleLineSkeleton />
    <SingleLineSkeleton />
  </>
);

export default DropsSkeleton;
