'use client';

import { NextPage } from 'next';

import UserAccountLayout from '@components/layouts/UserAccountLayout';
import DashboardLayout from '@components/layouts/DashboardLayout';
import { WarningModalProvider } from '../../context/MySewageContext';

const Settings: NextPage = () => {
  return (
    <WarningModalProvider>
      <DashboardLayout>
        <UserAccountLayout />
      </DashboardLayout>
    </WarningModalProvider>
  );
};

export default Settings;
