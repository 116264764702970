import { Skeleton } from '@mui/material';
import { Item, Wrapper } from '@components/elements/YourCreditCard/SingleCard';

const SingleLineSkeleton = () => (
  <Wrapper>
    <Item small>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
    </Item>
    <Item>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
    </Item>
    <Item>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
    </Item>
    <Item small>
      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
    </Item>
  </Wrapper>
);

export const CardSkeleton = () => (
  <>
    <SingleLineSkeleton />
    <SingleLineSkeleton />
    <SingleLineSkeleton />
  </>
);
