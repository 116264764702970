import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  ContainerProps,
  Switch,
  FormControl,
  Link,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { BorderButton, GradientButton } from '@styles/styled';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';
import {
  MyExportsInterface,
  OrderInterface,
  RootState,
} from '@redux/interfaces';
import { addNewExport } from '@redux/myExports/actions';
import {
  acceptOrderProposition,
  addNewOrder,
  getContractWithDriver,
} from '@redux/order/actions';
import { toast } from 'react-toastify';
import SingleLine from '@components/elements/PayForOrderModal/SingleLine';
import {
  beginCheckoutFA,
  levelEndFA,
  removeFromCartFA,
  selectContentFA,
} from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';

const { primary } = theme.palette;

const BoxRow = styled(Box)`
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
  align-content: center;
  flex-direction: row;

  @media (max-width: 660px) {
    justify-content: space-around;
    flex-direction: column;
    gap: 10px;
  }
`;

const HelperText = styled(FormHelperText)`
  width: 70%;
  padding-bottom: 10px;
  color: ${primary.contrastText};

  @media (max-width: 660px) {
    width: 100%;
  }
`;

interface ContainerI extends ContainerProps {
  component: any;
}

const StyledContainer = styled(Container)<ContainerI>`
  width: 90%;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

type SummaryType = {
  nextStep?: () => void;
  prevStep?: () => void;
  nonApp?: boolean;
  formik?: any;
};

const Summary = ({ nextStep, prevStep, nonApp, formik }: SummaryType) => {
  const [contractAccepted, setContractAccepted] = useState(false);
  const [orderDate, setOrderDate] = useState('');

  const dispatch = useDispatch();

  const { isAdded } = useSelector<RootState, MyExportsInterface>(
    (state) => state.myExports
  );

  const { newOrderAdded, loading, hasAgreement, askOrder } = useSelector<
    RootState,
    OrderInterface
  >((state) => state.order);

  const handleAdd = () => {
    levelEndFA(
      nonApp ? types.LEVEL_NAME_NUM[4] : types.LEVEL_NAME_APP[3],
      true
    );

    if (nextStep) {
      const { cost, fullDate, driver, selectedTank } = formik.values;

      const date =
        fullDate.getFullYear() +
        '-' +
        (fullDate.getMonth() + 1) +
        '-' +
        fullDate.getDate();

      let correctDate = date + 'T00:00:00+01:00';

      correctDate = correctDate.replaceAll('.', '-');

      if (nonApp) {
        const formData = new FormData();
        formData.append('client_property', selectedTank);
        formData.append('date', correctDate);
        formData.append('price', cost ? cost : '');
        formData.append('sanitation_company_name', driver.name);
        formData.append('sanitation_company', driver.id);
        formData.append('amount', '0');

        dispatch(addNewExport(formData));
      } else {
        if (contractAccepted || hasAgreement) {
          beginCheckoutFA(formik.values.analyticsInfo);
          if (askOrder && askOrder?.client_property === selectedTank) {
            dispatch(acceptOrderProposition(`${askOrder?.id}/`));
          } else {
            dispatch(
              addNewOrder(`${selectedTank}/`, {
                date: date,
                company: driver.id,
              })
            );
          }
        } else {
          toast.error(
            'Jeśli chcesz dokonać zamówienia zaakceptuj najpierw umowę z operatorem.'
          );
        }
      }
    }
  };

  const getContract = () => {
    const { driver, selectedTank } = formik.values;
    selectContentFA('clicked_agreement', driver.id);
    dispatch(getContractWithDriver(`${selectedTank}/${driver.id}/`));
  };

  useEffect(() => {
    if (formik?.values) {
      const { fullDate } = formik.values;

      if (fullDate) {
        const date = new Date(fullDate);
        setOrderDate(date.toLocaleDateString('pl-PL'));
      }
    }
  }, []);

  useEffect(() => {
    if ((isAdded || newOrderAdded) && nextStep) nextStep();
  }, [isAdded, newOrderAdded]);

  const handlePrevStep = () => {
    levelEndFA(
      nonApp ? types.LEVEL_NAME_NUM[4] : types.LEVEL_NAME_APP[3],
      false
    );
    if (!nonApp) removeFromCartFA(formik.values.analyticsInfo);
    if (prevStep) prevStep();
  };

  return (
    <StyledContainer
      maxWidth={false}
      component="form"
      onSubmit={formik && formik.handleSubmit}
    >
      <Box>
        <Typography variant="h6" sx={{ p: 2, pl: 0, fontWeight: 'bold' }}>
          Twoje zamówienie:
        </Typography>
        <Box>
          <Box>
            <SingleLine label="Data wywozu: " value={orderDate} />
            {!nonApp && (
              <HelperText>
                (Wybrany przewoźnik zastrzega sobie prawo do wykonania wywozu
                dzień wcześniej.)
              </HelperText>
            )}
          </Box>
          <SingleLine
            label="Wybrany operator:"
            value={formik.values.driver.name}
          />
          {!nonApp && (
            <SingleLine
              label="Szacowany koszt: "
              value={`${formik.values.cost} ${
                formik.values.costType && `/${formik.values.costType}`
              } `}
            />
          )}
        </Box>
      </Box>

      {!nonApp && !hasAgreement && (
        <Box
          sx={{
            borderBottom: '2px solid #B3C6C0',
            borderTop: '2px solid #B3C6C0',
            pb: 2,
          }}
        >
          <FormControl>
            <Box>
              <Switch
                id="contract"
                name="contract"
                color="primary"
                checked={contractAccepted}
                onChange={() => setContractAccepted((prevState) => !prevState)}
                data-cy="contract"
              />
              <FormLabel disabled>
                <Typography color="red" sx={{ display: 'inline-block' }}>
                  *
                </Typography>{' '}
                Wyrażam zgodę na zawarcie umowy z wybranym operatorem na wywóz
                nieczystości płynnych.{' '}
                <Link
                  target="_blank"
                  data-cy="statute-url"
                  sx={{ color: theme.palette.primary.dark, fontWeight: 'bold' }}
                  onClick={() => getContract()}
                >
                  Zobacz umowę.
                </Link>
              </FormLabel>
            </Box>
          </FormControl>
        </Box>
      )}

      {prevStep && nextStep && (
        <BoxRow sx={{ width: '100%' }}>
          <BorderButton
            onClick={() => handlePrevStep()}
            loading={loading}
            disabled={loading}
          >
            Wstecz
          </BorderButton>
          <GradientButton
            type="submit"
            data-cy="submit_save"
            sx={{ m: 0 }}
            loading={loading}
            disabled={loading}
            onClick={() => {
              handleAdd();
            }}
          >
            {nonApp ? 'Zakończ' : 'Zamów z obowiązkiem zapłaty'}
          </GradientButton>
        </BoxRow>
      )}
    </StyledContainer>
  );
};

export default Summary;
