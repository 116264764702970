'use client';

import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Dashboard from '../screens/dashboard';
import LoginPage from '../screens/login';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthApiResult, RootState } from '@redux/interfaces';

import MyExports from '@screens/my-exports';
import MySewage from '@screens/my-sewage';
import Payments from '@screens/payments';
import Help from '@screens/help';
import Settings from '@screens/settings';
import ForgottenPassword from '@screens/forgotten-password';
import ForgottenPasswordDonePage from '@screens/forgotten-password/done';
import { loginFromBoss } from '@redux/auth/actions';
import FirstVerify from '@screens/first-verify';
import AddCardPopUp from '@components/modules/AddCardPopUp';
import { PayForPickupModal } from '@components/elements/LastOrder/Modals';
import { globalLocation } from './globalHistory';
import { pageViewFA } from '../analytics/analyticFunctions';

const RootNavigation = () => {
  const { logged } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (globalLocation?.pathname) {
      const pageData = {
        page_title: globalLocation.pathname,
      };

      pageViewFA(pageData);
    }
  }, [globalLocation]);

  useEffect(() => {
    if (logged) {
      navigate('/dashboard', { replace: true });
    }
  }, [logged]);

  useEffect(() => {
    if (!logged && window && window.sessionStorage) {
      const token = sessionStorage.getItem('TOKEN');
      if (token) {
        dispatch(loginFromBoss());
      }
    }
  });

  useEffect(() => {
    if (
      !logged &&
      !(
        pathname === '/login' ||
        pathname === '/register/*' ||
        pathname === '/email-confirm/*' ||
        pathname === '/forgotten-password*'
      )
    ) {
      navigate('/login', { replace: true });
    }
  }, [location]);

  return (
    <Routes>
      {logged ? (
        <>
          <Route path="dashboard" element={<Dashboard />} />
          <Route
            path="dashboard/pay-for-order"
            element={<PayForPickupModal />}
          />
          <Route path="my-exports" element={<MyExports />} />
          <Route path="my-sewage" element={<MySewage />} />
          <Route path="payments" element={<Payments />} />
          <Route path="payments/add-new-card" element={<AddCardPopUp />} />
          <Route path="help" element={<Help />} />
          <Route path="settings" element={<Settings />} />
        </>
      ) : (
        <>
          <Route path="forgotten-password" element={<ForgottenPassword />} />
          <Route
            path="forgotten-password/done"
            element={<ForgottenPasswordDonePage />}
          />
          <Route path="login" element={<LoginPage />} />
          <Route path="first-verify" element={<FirstVerify />} />
        </>
      )}
    </Routes>
  );
};

export default RootNavigation;
