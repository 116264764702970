import React from 'react';

import { Box, Paper } from '@mui/material';

import ContactForm from '@forms/Settings/ContactForm';
import ChangePassword from '@forms/Settings/ChangePassword';
import TitleBar from '@components/elements/TitleBar';
import { LayoutWrapper } from '@styles/styled';
import BillingData from '@components/elements/BillingDataComponent';
import { styled } from '@mui/material/styles';

const Wrapper = styled(Box)`
  flex: 1;
  margin-left: 24px;

  @media (max-width: 905px) {
    margin: 0;
  }
`;

const UserAccountLayout: React.FC = () => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <TitleBar>Ustawienia konta</TitleBar>
      </Box>
      <LayoutWrapper>
        <Box sx={{ flex: 1 }}>
          <Paper
            sx={{
              mt: 4,
              p: 3,
              borderRadius: '25px',
              boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
            }}
          >
            <ContactForm />
          </Paper>

          <Paper
            sx={{
              mt: 4,
              p: 3,
              borderRadius: '25px',
              boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
            }}
          >
            <BillingData />
          </Paper>
        </Box>
        <Wrapper sx={{ flex: 1, ml: 3 }}>
          <Paper
            sx={{
              mt: 4,
              p: 3,
              borderRadius: '25px',
              boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
            }}
          >
            <ChangePassword />
          </Paper>
        </Wrapper>
      </LayoutWrapper>
    </Box>
  );
};

export default UserAccountLayout;
