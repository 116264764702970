'use client';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const TableWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 660px) {
    width: 140%;
  }
`;

export const GridContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  flex: 1;
  width: 90%;
  margin: 0 auto;
  cursor: pointer;

  @media (max-width: 550px) {
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const GridItem = styled('div')`
  width: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;

  @media (max-width: 660px) {
    width: 33%;
  }

  @media (max-width: 550px) {
    border: none;
  }
`;

type WidthProps = {
  width?: string;
  mobileWidth?: string;
};

export const ShortertItem = styled(GridItem)`
  width: ${(p: WidthProps) => (p.width ? p.width : '60%')};
  display: flex;
  justify-content: center;

  @media (max-width: 660px) {
    width: ${(p: WidthProps) => (p.mobileWidth ? p.mobileWidth : '40%')};
  }
`;

export const SmallRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DisableOnMobile = styled(ShortertItem)`
  @media (max-width: 660px) {
    display: none;
  }
`;
