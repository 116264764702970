'use client';

import { types } from './types';

export const addNewExport = (data: FormData) => {
  return {
    type: types.ADD_NEW_EXPORT_REQUEST,
    formData: data,
  };
};

export const confirmIsAdded = () => {
  return {
    type: types.CONFIRM_IS_ADDED,
  };
};

export const findCompany = (id: string) => {
  return {
    type: types.FIND_COMPANY_REQUEST,
    id,
  };
};

export const resetCompanies = () => {
  return {
    type: types.FIND_COMPANY_RESET,
  };
};

export const getHistoryDrop = (id: string) => {
  return {
    type: types.GET_HISTORY_DROP_REQUEST,
    id,
  };
};

export const deleteExport = (id: string) => {
  return {
    type: types.DELETE_EXPORT_REQUEST,
    id,
  };
};

export const updateExport = (id: string, data: FormData) => {
  return {
    type: types.UPDATE_EXPORT_REQUEST,
    id,
    formData: data,
  };
};

export const getInvoice = (id: string) => {
  return {
    type: types.GET_INVOICE_REQUEST,
    id,
  };
};

export const getComplaintDetails = (id: string) => {
  return {
    type: types.GET_COMPLAINT_REQUEST,
    id,
  };
};
export const reportComplaint = (
  id: string,
  complaint: { complaint: string }
) => {
  return {
    type: types.ADD_COMPLAINT_REQUEST,
    id,
    data: complaint,
  };
};
