import { Box, Container, CssBaseline } from '@mui/material';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

type Props = {
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm';
};

const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY as string;

const AuthLayout = ({ children, maxWidth = 'xs' }: Props) => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
    <Container component="main" maxWidth={maxWidth}>
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Container>
  </GoogleReCaptchaProvider>
);
export default AuthLayout;
