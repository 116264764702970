import React, { useEffect } from 'react';
import { CardTitle } from '@styles/styled';
import DownloadReadyProtocol from '@components/modules/VirtualControl/DownloadReadyProtocol';
import ProtocolWasSent from '@components/modules/VirtualControl/ProtocolWasSent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, UserTanksInterface } from '@redux/interfaces';
import {
  getProtocol,
  getProtocolFile,
  resetProtocolId,
} from '@redux/userCesspools/actions';
import ContractLoaderContainer from '@components/elements/ContractLoaderContainer';
import ProtocolDetails from '@components/modules/VirtualControl/ProtocolDetails';
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { lawNote } from '@components/modules/VirtualControl/text';
import SignProtocol from '@components/modules/VirtualControl/SignProtocol';

const HeaderContainer = styled(CardTitle)`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const VirtualControlStepper = () => {
  const dispatch = useDispatch();

  const {
    currentTank: { virtual_control_protocol },
    selectedTankId,
    loadingGetProtocol,
    protocolTaskId,
  } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const handleGetProtocol = () => {
    dispatch(getProtocol(`${selectedTankId}/documents/`));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (protocolTaskId) {
        dispatch(getProtocolFile(`${selectedTankId}/?id=${protocolTaskId}`));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [protocolTaskId]);

  const returnStep = () => {
    if (virtual_control_protocol) {
      switch (virtual_control_protocol.status) {
        case 'NEW':
          return <ProtocolDetails />;
        case 'SUBMITTED':
          return (
            <ProtocolWasSent protocolSendDate={virtual_control_protocol.date} />
          );
        case 'DONE':
          return (
            <DownloadReadyProtocol
              downloadProtocol={handleGetProtocol}
              address={virtual_control_protocol.address}
              protocolSendDate={virtual_control_protocol.date}
            />
          );
        case 'SIGNED_BY_COMMUNE':
          return <SignProtocol downloadProtocol={handleGetProtocol} />;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <>
      {loadingGetProtocol && (
        <ContractLoaderContainer
          message="Przygotowujemy deklarację do pobrania..."
          optionalAction={() => dispatch(resetProtocolId())}
        />
      )}
      <HeaderContainer>
        Cyfrowa kontrola mieszkańca
        <Tooltip title={lawNote} enterTouchDelay={100}>
          <InfoIcon fontSize="small" />
        </Tooltip>
      </HeaderContainer>
      {returnStep()}
    </>
  );
};

export default VirtualControlStepper;
