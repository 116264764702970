import {
  CityWrapper,
  NumbersWrapper,
  Row,
  SecondRow,
} from '@forms/TerytAddressForm/styled';
import {
  getCities,
  getProvinces,
  getStreets,
  resetLocality,
  resetStreet,
} from '@redux/teryt/actions';
import React, { useEffect, useMemo, useState } from 'react';
import CustomAutocomplete from '@components/elements/CustomAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  TerytInterface,
  UserTanksInterface,
} from '@redux/interfaces';
import { correctPostalCode } from '@forms/TerytAddressForm/postalCode';
import CustomTextField from '@components/elements/CustomTextField';
import FormSkeleton from '@forms/TerytAddressForm/FormSkeleton';
import { useLocation } from 'react-router-dom';

type PropsT = {
  formik: any;
  isLoading?: boolean;
};

const TerytAddressForm = ({ formik, isLoading = false }: PropsT) => {
  const [isRod, setIsRod] = useState(false);
  const [rodStreetName, setRodStreetName] = useState('');
  const [disableInput, setDisableInput] = useState<boolean>(false);

  const { provinces, localities, streets } = useSelector<
    RootState,
    TerytInterface
  >((state) => state.teryt);

  const { currentTank } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const cachedStreets = useMemo(() => {
    if (isRod) {
      return [{ key: 0, id: 0, locality_sym: '', name: rodStreetName }];
    }

    return streets
      ? streets.map((street) => ({
          key: street.id,
          id: street.id,
          locality_sym: street.locality_sym,
          name: street.full_street_name,
        }))
      : [];
  }, [streets, rodStreetName]);

  const clearOnProvinceChange = () => {
    formik.setFieldValue('city', {
      name: '',
      locality_sym: '',
      commune_description: '',
    });
    formik.setFieldValue('street', {
      id: 0,
      locality_sym: '',
      name: '',
    });
  };

  useEffect(() => {
    setDisableInput(
      Boolean(currentTank?.virtual_control_auth_code) &&
        pathname === '/my-sewage'
    );
  }, [currentTank]);

  if (isLoading) return <FormSkeleton />;
  return (
    <>
      <Row>
        <CustomAutocomplete
          id="province"
          label="WOJEWÓDZTWO"
          disabled={disableInput}
          options={provinces}
          loadOnOpen={() => dispatch(getProvinces())}
          value={formik.values.province}
          forcePopUp={Boolean(!formik.values.province?.name?.length)}
          onClear={() => clearOnProvinceChange()}
          inputOnChange={(e: any) => {
            if (e.target.value.length >= 3) {
              const { value } = e.target;

              dispatch(getProvinces({ term: value }));
            }
          }}
          callOnChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            formik.setFieldValue('province', e);
            clearOnProvinceChange();
          }}
          error={Boolean(formik.touched.province && formik.errors.province)}
          helperText={formik.touched.province && formik.errors.province?.name}
        />
        <CityWrapper>
          <CustomTextField
            id="postalCode"
            label="KOD POCZTOWY"
            disabled={disableInput}
            autoComplete="postalCode"
            value={formik.values.postalCode}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              correctPostalCode(e.target.value, formik);
            }}
            error={Boolean(
              formik.touched.postalCode && formik.errors.postalCode
            )}
            helperText={formik.touched.postalCode && formik.errors.postalCode}
            sx={{ flex: 1.5 }}
          />
          <CustomAutocomplete
            id="city"
            options={localities}
            label="MIEJSCOWOŚĆ"
            disabled={Boolean(
              !formik.values?.province ||
                formik.values?.province?.name === '' ||
                disableInput
            )}
            forcePopUp={Boolean(!formik.values.city?.name?.length)}
            value={formik.values.city}
            onClear={() => {
              dispatch(resetStreet());
              dispatch(resetLocality());
              formik.setFieldValue('street', {
                id: 0,
                locality_sym: '',
                name: '',
              });
            }}
            inputOnChange={(e: any) => {
              if (e.target.value.length >= 3) {
                dispatch(
                  getCities({
                    term: e.target.value,
                    province_code: formik.values.province?.code,
                  })
                );
              }
            }}
            callOnChange={(e: any) => {
              dispatch(resetStreet());
              formik.setFieldValue('city', e);
              formik.setFieldValue('street', {
                id: 0,
                locality_sym: '',
                name: '',
              });
            }}
            error={Boolean(formik.touched.city && formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city?.name}
          />
        </CityWrapper>
      </Row>
      <SecondRow>
        <CustomAutocomplete
          id="street"
          options={cachedStreets}
          label="ULICA"
          disabled={Boolean(
            !formik.values?.city ||
              formik.values?.city?.name === '' ||
              disableInput
          )}
          value={formik.values.street}
          forcePopUp={Boolean(!formik.values.street?.name?.length)}
          inputOnChange={(e: any) => {
            if (e.target.value.length >= 3) {
              const { value } = e.target;

              const firstThree = value.slice(0, 3);

              if (firstThree.toUpperCase() === 'ROD') {
                setIsRod(true);
                setRodStreetName(value);
              } else {
                setIsRod(false);

                dispatch(
                  getStreets({
                    term: value,
                    locality_sym: formik.values.city?.locality_sym,
                  })
                );
              }
            }
          }}
          onClear={() => {
            dispatch(resetStreet());
            formik.setFieldValue('street', {
              id: 0,
              locality_sym: '',
              name: '',
            });
          }}
          callOnChange={(e: any) => {
            formik.setFieldValue('street', e);
          }}
          error={Boolean(formik.touched.street && formik.errors.street)}
          sx={{ flex: 4 }}
        />
        <NumbersWrapper>
          <CustomTextField
            id="houseNumber"
            label="NR BUDYNKU"
            value={formik.values.houseNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.handleChange(e);
            }}
            error={Boolean(
              formik.touched.houseNumber && formik.errors.houseNumber
            )}
            helperText={formik.touched.houseNumber && formik.errors.houseNumber}
            disabled={disableInput}
          />
          <CustomTextField
            id="flatNumber"
            label="NR LOKALU"
            value={formik.values.flatNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.handleChange(e);
            }}
            error={Boolean(
              formik.touched.flatNumber && formik.errors.flatNumber
            )}
            helperText={formik.touched.flatNumber && formik.errors.flatNumber}
            disabled={disableInput}
          />
        </NumbersWrapper>
      </SecondRow>
    </>
  );
};

export default TerytAddressForm;
