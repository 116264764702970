'use client';

import { NextPage } from 'next';
import DashboardLayout from '@components/layouts/DashboardLayout';
import MySewageLayout from '@components/layouts/MySewageLayout';
import { WarningModalProvider } from '../../context/MySewageContext';

const MySewage: NextPage = () => (
  <WarningModalProvider>
    <DashboardLayout>
      <MySewageLayout />
    </DashboardLayout>
  </WarningModalProvider>
);

export default MySewage;
