import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

const FallowUse = styled(Typography)`
  font-weight: 600;
  color: black;

  @media (max-width: 660px) {
    font-size: 1.125rem;
  }
`;

const FollowWrapper = styled(Box)`
  width: 100%;
`;

const FallowOnFB = styled(Typography)`
  display: flex;
  align-items: center;
  font-weight: 800;
  color: #1877f2;

  @media (max-width: 660px) {
    font-size: 1.125rem;
  }
`;

const Wrapper = styled('div')`
  background-color: #fff;
  border-radius: 10px;
  margin: 0 0 20px 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 660px) {
    align-items: center;
    margin: 20px auto;
    width: 92%;
    padding: 10px 20px;
    box-shadow: 0 2px 7px 2px #ddd;
  }
`;

const FallowUs = () => (
  <Wrapper>
    <FollowWrapper>
      <FallowUse variant={'subtitle1'} sx={{ marginBottom: '10px' }}>
        Obserwuj nasz profil i bądź na bieżąco!
      </FallowUse>
    </FollowWrapper>
    <a
      href={'https://www.facebook.com/szambo.online/'}
      target={'_blank'}
      rel="noreferrer"
    >
      <FallowOnFB variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
        Znajdź nas na{' '}
        <FacebookRoundedIcon sx={{ fontSize: 24, marginLeft: '5px' }} />
      </FallowOnFB>
    </a>
  </Wrapper>
);

export default FallowUs;
