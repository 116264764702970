import { Box, Skeleton } from '@mui/material';

export const NotificationSkeleton = () => (
  <Box
    sx={{
      pb: 1,
      width: '95%',
      margin: '0 auto',
    }}
  >
    <Box sx={{ width: '30%' }}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
    <Skeleton variant="rounded" width="100%" height={70} />
    <Box sx={{ width: '30%', float: 'right' }}>
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
  </Box>
);
