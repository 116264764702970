export const cL = (val: string | number) => {
  if (String(val).length == 1) return `0${val}`;
  return val;
};

export const getFormatedDate = (date?: string | null, dots?: boolean) => {
  const separator = dots ? '.' : '-';

  if (date) {
    const d = new Date(date);
    return `${cL(d.getDate())}${separator}${cL(
      d.getMonth() + 1
    )}${separator}${d.getFullYear()}`;
  }
  return null;
};

export const getFormatedTime = (date: string) => {
  const d = new Date(date);
  return `${d.getHours()}:${cL(d.getMinutes())}`;
};
