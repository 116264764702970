import Image from 'next/image';
import logo from '../../../../public/images/logo_white.svg';
// @ts-ignore
import googlePlay from '../../../../public/images/googlePlay.webp';
// @ts-ignore
import appGallery from '../../../../public/images/appGallery.webp';
// @ts-ignore
import appStore from '../../../../public/images/appStore.webp';

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

const Wrapper = styled('div')`
  background-color: #474544;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`;

const RightWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const LeftWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 999;

  @media (max-width: 660px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Img = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  background-position: left;
  background-size: cover;
`;

const Overlay = styled(Img)`
  width: 300px;
  z-index: 998;
  background-image: url('/images/overlay.webp');

  @media (max-width: 660px) {
    width: 45%;
  }
`;

const Text = styled('p')`
  font-weight: bold;
  margin: 15px 15px 15px 35px;
  font-size: 18px;
  color: #fff;

  @media (max-width: 660px) {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
`;

const Logo = styled(Image)`
  height: 35px;
  width: 120px;
`;

const ANDROID = 'ANDROID';
const APPLE = 'APPLE';
const HUAWEI = 'HUAWEI';
const UNDEFINED = 'UNDEFINED';

export const InstallAppFromStore = () => {
  const [deviceType, setDeviceType] = useState('UNDEFINED');

  const isInStandaloneMode = () =>
    window.matchMedia('(display-mode: standalone)').matches ||
    // @ts-ignore
    window.navigator?.standalone ||
    document.referrer.includes('android-app://');

  const checkDevice = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    const huaweiRegex = new RegExp(
      /ALP-|AMN-|ANA-|ANE-|ANG-|AQM-|ARS-|ART-|ATU-|BAC-|BLA-|BRQ-|CAG-|CAM-|CAN-|CAZ-|CDL-|CDY-|CLT-|CRO-|CUN-|DIG-|DRA-|DUA-|DUB-|DVC-|ELE-|ELS-|EML-|EVA-|EVR-|FIG-|FLA-|FRL-|GLK-|HMA-|HW-|HWI-|INE-|JAT-|JEF-|JER-|JKM-|JNY-|JSC-|LDN-|LIO-|LON-|LUA-|LYA-|LYO-|MAR-|MED-|MHA-|MLA-|MRD-|MYA-|NCE-|NEO-|NOH-|NOP-|OCE-|PAR-|PIC-|POT-|PPA-|PRA-|RNE-|SEA-|SLA-|SNE-|SPN-|STK-|TAH-|TAS-|TET-|TRT-|VCE-|VIE-|VKY-|VNS-|VOG-|VTR-|WAS-|WKG-|WLZ-|YAL/g
    );

    if (/huawei/i.test(userAgent) || userAgent.match(huaweiRegex)) {
      setDeviceType(HUAWEI);
    } else if (/android/i.test(userAgent)) {
      setDeviceType(ANDROID);
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType(APPLE);
    } else {
      setDeviceType(UNDEFINED);
    }
  };

  useEffect(() => {
    checkDevice();
  }, []);

  const buttonDetails = (): {
    img: string | StaticImport;
    url: string;
    height: number;
    width: number;
    alt: string;
  } => {
    switch (deviceType) {
      case ANDROID:
        return {
          img: googlePlay,
          url: 'https://play.google.com/store/apps/details?id=com.szambo.online',
          height: 40,
          width: 120,
          alt: 'google play button',
        };
      case HUAWEI:
        return {
          img: appGallery,
          url: 'https://appgallery.huawei.com/app/C104299619',
          height: 55,
          width: 140,
          alt: 'app gallery button',
        };
      case APPLE:
        return {
          img: appStore,
          url: 'https://apps.apple.com/pl/app/szambo-online/id1562902764',
          height: 40,
          width: 120,
          alt: 'app store button',
        };
      default:
        return {
          img: '',
          url: '#',
          height: 0,
          width: 0,
          alt: '',
        };
    }
  };

  return (
    <>
      {deviceType !== 'UNDEFINED' && !isInStandaloneMode() && (
        <Wrapper onClick={() => (window.location.href = buttonDetails().url)}>
          <Overlay />
          <LeftWrapper>
            <Logo src={logo} alt={'logo'} height={35} width={120} />
            <Text>Pobierz bezpłatną aplikację</Text>
          </LeftWrapper>
          <RightWrapper>
            <Image
              src={buttonDetails().img}
              alt={buttonDetails().alt}
              height={buttonDetails().height}
              width={buttonDetails().width}
            />
          </RightWrapper>
        </Wrapper>
      )}
    </>
  );
};
