'use client';

import { types } from './types';

export const getProvinces = (data?: { term: string }) => {
  return {
    method: 'GET',
    type: types.GET_PROVINCES_REQUEST,
    data,
  };
};

export const getCities = (data?: {
  term: string;
  province_code: string | undefined;
}) => {
  return {
    method: 'GET',
    type: types.GET_CITY_REQUEST,
    data,
  };
};

export const getStreets = (data?: {
  term: string;
  locality_sym: string | undefined;
}) => {
  return {
    method: 'GET',
    type: types.GET_STREET_REQUEST,
    data,
  };
};

export const localizeMarker = (data?: { lat: number; lng: number }) => {
  return {
    type: types.LOCALIZE_MARKER_SUCCESS,
    data,
  };
};

export const resetStreet = () => {
  return {
    type: types.RESET_TERYT_STREET,
  };
};

export const resetLocality = () => {
  return {
    type: types.RESET_TERYT_LOCALITY,
  };
};
