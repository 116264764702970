import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';
import theme from '@styles/theme';

import ProgressBar from 'react-customizable-progressbar';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useSelector } from 'react-redux';
import { ClientData, RootState } from '@redux/interfaces';
import { useEffect, useState } from 'react';

const Row = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 75%;

  @media (max-width: 660px) {
    width: 100%;
    justify-content: center;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
`;

const InsideText = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: -5px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 100;
  color: #555;
  user-select: none;
`;

const StepTitle = styled('p')`
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 660px) {
    font-size: 18px;
  }
  @media (max-width: 310px) {
    font-size: 17px;
  }
`;

type Props = {
  orderType: 'NUM' | 'APP' | null;
  currentStep: number;
};

const MobileStepper = ({ orderType, currentStep }: Props) => {
  const [appProgressValue, setAppProgresValue] = useState(25);
  const [appLabels, setAppLabels] = useState([
    'Wybierz dostawcę usługi',
    'Wybierz datę wywozu',
    'Sprawdź cenę usługi',
    'Potwierdź zamówienie',
    'Zakończ',
  ]);

  const { width } = useWindowDimensions();

  const green = theme.palette.primary.main;

  const { billingData } = useSelector<RootState, ClientData>(
    (state) => state.contact
  );

  const numLabels = [
    'Wybierz dostawcę usługi',
    'Zadzwoń do operatora',
    'Wybierz ustaloną datę',
    'Wprowadź cenę usługi',
    'Potwierdź dane',
    'Zakończ',
  ];

  useEffect(() => {
    if (!billingData?.full_name && appLabels[3] === 'Potwierdź zamówienie') {
      setAppLabels((prevState) => {
        if (prevState.includes('Wprowadź dane billingowe')) {
          return prevState;
        }
        return [
          ...prevState.slice(0, 3),
          'Wprowadź dane billingowe',
          ...prevState.slice(3, appLabels.length),
        ];
      });
      setAppProgresValue(20);
    }
  }, [billingData, appLabels]);

  const getTitles = () => {
    switch (orderType) {
      case 'NUM':
        return {
          currentLabel: numLabels[currentStep],
          nextLabel: numLabels[currentStep + 1],
        };

      case 'APP':
        return {
          currentLabel: appLabels[currentStep],
          nextLabel: appLabels[currentStep + 1],
        };
      default:
        return {
          currentLabel: appLabels[currentStep],
          nextLabel: '',
        };
    }
  };

  return (
    <Wrapper>
      <Row>
        <Box sx={{ marginRight: '10px' }}>
          <StepTitle>{getTitles().currentLabel}</StepTitle>
          {currentStep >= 1 && width >= 310 && (
            <Typography sx={{ fontSize: '14px' }} color="gray">
              Dalej: {getTitles().nextLabel}
            </Typography>
          )}
        </Box>
        <Box sx={{ height: '80px' }}>
          <ProgressBar
            radius={38}
            progress={
              orderType === 'NUM'
                ? (currentStep + 1) * 20
                : (currentStep + 1) * appProgressValue
            }
            cut={120}
            rotate={-210}
            strokeWidth={5}
            strokeColor={green}
            trackStrokeWidth={5}
            pointerRadius={2}
            pointerFillColor={green}
            pointerStrokeColor={green}
            pointerStrokeWidth={5}
            className="sewageCounter"
          >
            <InsideText>
              <Typography sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                Krok{' '}
              </Typography>
              <Typography
                sx={{ fontWeight: '700', marginTop: '-2px' }}
                color={green}
              >
                {currentStep + 1} z{' '}
                {orderType === 'NUM'
                  ? numLabels.length - 1
                  : appLabels.length - 1}
              </Typography>
            </InsideText>
          </ProgressBar>
        </Box>
      </Row>
      {currentStep >= 1 && width <= 310 && (
        <Typography sx={{ fontSize: '14px' }} color="gray">
          Dalej: {getTitles().nextLabel}
        </Typography>
      )}
    </Wrapper>
  );
};

export default MobileStepper;
