import { ReactNode } from 'react';
import { Paper, Container } from '@mui/material';

type Props = {
  children: ReactNode;
};

const AuthLayoutWithoutCaptcha = ({ children }: Props) => {
  return (
    <Container component="main" maxWidth="sm">
      <Paper
        sx={{
          marginTop: 8,
          p: 5,
          borderRadius: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {children}
      </Paper>
    </Container>
  );
};
export default AuthLayoutWithoutCaptcha;
