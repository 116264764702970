import { Box } from '@mui/material';
import IsCompanyRadioSelect from '@components/elements/IsCompanyRadioSelect';
import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { BillingDataFormI, ClientData, RootState } from '@redux/interfaces';
import {
  updateClient,
  updateClientBillingData,
} from '@redux/userSettings/myAccount/actions';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { BorderButton, GradientButton } from '@styles/styled';
import TerytAddressForm from '@forms/TerytAddressForm';
import validator from '@forms/BillingDataForm/validator';
import terytValidator from '@forms/SewageLocalizationForm/validator';
import { WarningModalContext } from '../../context/MySewageContext';
import NonTerytDataWarning from '@components/elements/NonTerytDataWarning';
import AdditionalContactData from '@forms/BillingDataForm/AdditionalContactData';
import ClientOrCompanyForm from '@components/elements/ClientOrCompanyForm';

const initialValues: BillingDataFormI = {
  clientType: 'T_PER',
  nip: '',
  postalCode: '',
  houseNumber: '',
  flatNumber: '',
  companyName: '',
  province: { name: '', code: '' },
  city: {
    name: '',
    locality_sym: '',
    commune_description: '',
  },
  street: {
    id: 0,
    locality_sym: '',
    name: '',
  },
  phoneNumber: '',
  name: '',
  surname: '',
};

const CustomGradientButton = styled(GradientButton)`
  margin: 20px 0 15px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: space-around;
  @media (max-width: 905px) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
`;

type FormPropsT = {
  isInOrder?: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
};

const BillingDataForm = ({
  isInOrder = false,
  prevStep,
  nextStep,
}: FormPropsT) => {
  const [isTeryted, setIsTeryted] = useState(true);

  const dispatch = useDispatch();

  const { billingData, loading, client } = useSelector<RootState, ClientData>(
    (state) => state.contact
  );

  const { handleBillingDataDifferent } = useContext(WarningModalContext);

  const mergedValidators = validator.concat(terytValidator);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: mergedValidators,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => onSubmitForm(values),
  });

  useEffect(() => {
    if (billingData) {
      const {
        province,
        city,
        locality_sym,
        street_sym,
        street,
        billing_type,
        full_name,
        postal_code,
        nip,
        house_nb,
        flat_nb,
      } = billingData;

      const newState = {
        isInOrder: isInOrder,
        ...(province
          ? { province: province }
          : {
              province: { name: '', code: '' },
            }),
        city: {
          name: city,
          locality_sym: locality_sym,
        },
        street: {
          id: street_sym,
          locality_sym: locality_sym,
          name: street,
        },
        clientType: billing_type,
        nip: nip ? nip : '0000000000',
        postalCode: postal_code,
        houseNumber: house_nb,
        flatNumber: flat_nb ? flat_nb : '',
        companyName: billing_type === 'T_COM' && full_name ? full_name : '',
        name:
          billing_type === 'T_PER' && full_name ? full_name.split(' ')[0] : '',
        surname:
          billing_type === 'T_PER' && full_name ? full_name.split(' ')[1] : '',
      };

      if (full_name)
        setIsTeryted(
          Boolean(newState.city.locality_sym && newState.province.code)
        );
      if (isInOrder && client) {
        const { phone_nb, last_name, first_name } = client;

        formik.resetForm({
          values: {
            ...newState,
            phoneNumber: phone_nb ? phone_nb : '',
            name: first_name ? first_name : '',
            surname: last_name ? last_name : '',
          },
        });
      } else {
        formik.resetForm({ values: newState });
      }
    }
  }, [billingData, client]);

  useEffect(() => {
    const { dirty } = formik;
    handleBillingDataDifferent(dirty);
  }, [formik]);

  const onSubmitForm = (values: BillingDataFormI) => {
    const { dirty } = formik;

    if (dirty) {
      const {
        nip,
        clientType,
        city,
        flatNumber,
        houseNumber,
        postalCode,
        street,
        province,
        companyName,
        name,
        surname,
      } = values;

      const streetObject = street
        ? {
            street: street.name,
            street_sym: street.id === 0 ? null : street.id,
          }
        : {
            street: '',
            street_sym: null,
          };

      const nameObject =
        clientType === 'T_COM'
          ? {
              full_name: companyName,
            }
          : { full_name: name + ' ' + surname };

      const data = {
        postal_code: postalCode,
        city: city.name,
        locality_sym: city.locality_sym,
        house_nb: houseNumber,
        flat_nb: flatNumber,
        province: province,
        billing_type: clientType,
        nip: clientType === 'T_COM' ? nip : null,
        ...nameObject,
        ...streetObject,
      };

      if (isInOrder) {
        const contactData = {
          username: client.email,
          first_name: name,
          last_name: surname,
          phone_nb: values?.phoneNumber,
          promotions: false,
          email: client.email,
          ...(clientType === 'T_COM' && {
            client_company_name: companyName,
          }),
        };

        dispatch(updateClient(contactData));
      }
      dispatch(updateClientBillingData(data));
    }
    nextStep && nextStep();
  };

  const handlePrevStep = () => {
    prevStep && prevStep();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        {!isTeryted && <NonTerytDataWarning />}

        <IsCompanyRadioSelect formik={formik} />

        <ClientOrCompanyForm formik={formik} />

        <TerytAddressForm formik={formik} />

        {isInOrder && <AdditionalContactData formik={formik} />}
      </Box>
      <ButtonWrapper sx={{ marginTop: '20px' }}>
        {isInOrder ? (
          <>
            <BorderButton onClick={() => handlePrevStep()}>Wstecz</BorderButton>
            <GradientButton sx={{ margin: 0 }} type="submit">
              Zapisz i przejdź dalej
            </GradientButton>
          </>
        ) : (
          <CustomGradientButton
            type="submit"
            data-cy="submit_save_billing_data"
            loading={loading}
            disabled={loading}
          >
            Zapisz zmiany
          </CustomGradientButton>
        )}
      </ButtonWrapper>
    </form>
  );
};

export default BillingDataForm;
