import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardInterface,
  OrderInterface,
  RootState,
  SingleCLientPropertyInterface,
  UserTanksInterface,
} from '@redux/interfaces';
import { Box, MenuItem } from '@mui/material';
import { getClientCompanies } from '@redux/order/actions';
import React, { useEffect, useState } from 'react';
import {
  SelectedTankTitle,
  TankSelectorWrapper,
  CustomSelect,
} from '@components/elements/CesspoolTankSelector/styled';
import { setSelectedTankIdAction } from '@redux/userCesspools/actions';

type PropsT = {
  formik: any;
  selectedTank: number | null;
  currentStep: number;
  setSelectedTank: (v: number) => void;
};

export const CesspoolTankSelector = ({
  formik,
  setSelectedTank,
  selectedTank,
  currentStep,
}: PropsT) => {
  const [propertiesToSelect, setPropertiesToSelect] = useState<
    Array<SingleCLientPropertyInterface> | []
  >([]);

  const { loading, clientProperty, selectedTankId } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  const { askOrderLoading } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  const {
    data: { client_properties: dashboardProperties },
  } = useSelector<RootState, DashboardInterface>((state) => state.dashboard);

  const dispatch = useDispatch();

  useEffect(() => {
    if (clientProperty && clientProperty.length > 1) {
      let idsToExclude: number[] | [] = [];

      const anyTankHasAskOrder = dashboardProperties.findIndex(
        (item) => item.ask_order
      );

      if (anyTankHasAskOrder !== -1) {
        idsToExclude = dashboardProperties
          .filter(
            (item) =>
              item.has_permission_to_find_company && item.id !== selectedTankId
          )
          .map((item) => item?.id);
      }

      const properties = clientProperty.filter((item) => {
        if (!item.has_order && !item.has_debt) {
          return (
            !(item.has_order_to_accept && item.id !== selectedTankId) &&
            ![...idsToExclude].includes(item.id)
          );
        }
        return false;
      });

      setPropertiesToSelect(properties);
    }
  }, [clientProperty, dashboardProperties]);

  return (
    <>
      {propertiesToSelect?.length ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <SelectedTankTitle>dla zbiornika: </SelectedTankTitle>
          <TankSelectorWrapper>
            <CustomSelect
              labelId="clientTypeSelect-label"
              id="clientTypeSelect"
              value={selectedTank}
              label="zbiornik"
              variant="standard"
              disabled={currentStep > 0 || loading || askOrderLoading}
              onChange={(e) => {
                setSelectedTank(Number(e.target.value));
                dispatch(setSelectedTankIdAction(Number(e.target.value)));
                formik.setFieldValue('selectedTank', Number(e.target.value));
                dispatch(getClientCompanies(`${e.target.value}/`));
              }}
            >
              {propertiesToSelect.map((item) => (
                <MenuItem key={item.id} value={`${item.id}`}>
                  <SelectedTankTitle>
                    {item.cesspool_name.length >= 8
                      ? item.cesspool_name
                      : item.cesspool_name}
                  </SelectedTankTitle>
                </MenuItem>
              ))}
            </CustomSelect>
          </TankSelectorWrapper>
        </Box>
      ) : null}
    </>
  );
};
