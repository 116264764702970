'use client';

import * as yup from 'yup';

export default yup.object({
  name: yup
    .string()
    .required('Imię nie może być puste.')
    .min(3, 'Imię nie może być krótsze niż 3 znaki')
    .max(25, 'Imię nie może być dłuższe niż 25 znaków'),
  surname: yup
    .string()
    .required('Nazwisko nie może być puste.')
    .min(2, 'Nazwisko nie może być krótsze niż 3 znaki')
    .max(30, 'Nazwisko nie może być dłuższe niż 30 znaków'),
  phone: yup
    .string()
    .nullable()
    .required('Numer telefonu jest wymagany')
    .matches(/^[0-9]+$/, 'Numer telefonu musi składać się z cyfr')
    .min(9, 'Numer telefonu jest za krótki')
    .max(11, 'Numer telefonu jest za długi'),
  clientCompanyName: yup.mixed().when('clientType', {
    is: 'T_COM',
    then: yup
      .string()
      .nullable()
      .required('Wprowadź nazwę firmy')
      .min(1, 'Nazwa firmy powinna mieć co najmniej jeden znak')
      .max(255, 'Maksymalna długość to 255 znaków'),
    otherwise: yup.string().nullable(),
  }),
});
