import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { Badge, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useFormik } from 'formik';

import { SewageLocalizationFormI } from '@forms/SewageLocalizationForm/interface';
import { BorderButton, CardTitle, GradientButton } from '@styles/styled';

import { localizeMarker } from '@redux/teryt/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, UserTanksInterface } from '@redux/interfaces';
import Geocode from '@redux/teryt/Geocode';
import {
  addNewTank,
  updateTankLocalization,
} from '@redux/userCesspools/actions';
import validator from './validator';
import { WarningModalContext } from '../../context/MySewageContext';
import TerytAddressForm from '@forms/TerytAddressForm';
import theme from '@styles/theme';

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 10px;
  flex-direction: column;
`;

const WrongTeryt = styled('div')`
  background-color: ${theme.palette.warning.light};
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const initialValues: SewageLocalizationFormI = {
  province: { name: '', code: '' },
  city: {
    name: '',
    locality_sym: '',
    commune_description: '',
  },
  street: {
    id: 0,
    locality_sym: '',
    name: '',
  },
  postalCode: '',
  flatNumber: '',
  houseNumber: '',
  geolocation: { lat: 52.41279684089903, lng: 16.92293039325305 },
};

const DynamicMap = dynamic(() => import('./Map'), {
  ssr: false,
});

type PropsT = {
  tankHaveActiveOrder: boolean;
};

const SewageLocalizationForm = ({ tankHaveActiveOrder }: PropsT) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [updatedPosition, setUpdatedPosition] = useState(null);
  const [isTerytIncorrect, setIsTerytIncorrect] = useState(false);
  const [isMarkupPositionChanged, setIsMarkupPositionChanged] = useState(false);
  const [markupPosition, setMarkupPosition] = useState({
    lat: 52.41279684089903,
    lng: 16.92293039325305,
  });

  const {
    currentTank,
    selectedTankId,
    newName,
    clientProperty,
    tankDetailsLoading,
    addingNewTank,
  } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { cesspool_name } = currentTank;

  const dispatch = useDispatch();
  const { handleTankLocationDifferent } = useContext(WarningModalContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: (values) => {
      const { city, flatNumber, houseNumber, postalCode, street, province } =
        values;

      const streetObject = street
        ? {
            street: street.name,
            street_sym: street.id === 0 ? null : street.id,
          }
        : {
            street: '',
            street_sym: null,
          };

      const data = {
        cesspool_name:
          selectedTankId === 0 || clientProperty.length === 0
            ? newName
            : currentTank.cesspool_name,
        postal_code: postalCode,
        city: city.name,
        locality_sym: city.locality_sym,
        house_nb: houseNumber,
        flat_nb: flatNumber?.length ? flatNumber : null,
        geoposition: `POINT (${markupPosition.lng} ${markupPosition.lat})`,
        province: province,
        ...streetObject,
      };

      if (selectedTankId === 0 || clientProperty.length === 0) {
        dispatch(addNewTank(data));
      } else {
        dispatch(updateTankLocalization(data, `${selectedTankId}/`));
      }
      setIsMarkupPositionChanged(false);
    },
  });

  const returnLocation = (
    geoposition: string | { lat: number; lng: number }
  ) => {
    if (geoposition && typeof geoposition === 'string') {
      const editedGeoposition = geoposition
        ? geoposition.trim().split(/\s+/)
        : '';
      const lng = Number(
        editedGeoposition[1].slice(1, editedGeoposition[1].length)
      );
      const lat = Number(
        editedGeoposition[2].slice(0, editedGeoposition[2].length - 1)
      );
      setMarkupPosition({ lat: lat, lng: lng });
    }
  };

  useEffect(() => {
    const {
      city,
      locality_sym,
      street,
      street_sym,
      flat_nb,
      house_nb,
      geoposition,
      postal_code,
      province,
    } = currentTank;

    returnLocation(geoposition);

    const newState = {
      city: {
        name: city,
        locality_sym: locality_sym,
      },
      street: {
        id: street_sym,
        locality_sym: locality_sym,
        name: street,
      },
      postalCode: postal_code,
      flatNumber: flat_nb === null ? '' : flat_nb,
      houseNumber: house_nb,
      ...(province
        ? { province: province }
        : {
            province: { name: '', code: '' },
          }),
      geolocation: markupPosition ? markupPosition : initialValues.geolocation,
    };

    setIsMarkupPositionChanged(false);
    // @ts-ignore
    formik.resetForm({ values: newState });
    setIsTerytIncorrect(
      (!locality_sym || !province || !house_nb) &&
        selectedTankId !== 0 &&
        !tankDetailsLoading
    );
  }, [currentTank]);

  useEffect(() => {
    if (Boolean(window)) {
      setMapLoaded(true);
    }
  }, []);

  useEffect(() => {
    formik.setTouched({}, false);
    formik.setErrors({});
    setIsTerytIncorrect(false);
  }, [selectedTankId]);

  useEffect(() => {
    if (updatedPosition) {
      setMarkupPosition(updatedPosition);
      setIsMarkupPositionChanged(true);
    }
  }, [updatedPosition]);

  useEffect(() => {
    const { dirty } = formik;
    handleTankLocationDifferent(dirty);
  }, [formik]);

  const findMe = () => {
    const { city, street, postalCode, flatNumber, houseNumber } = formik.values;

    const address = `${street?.name} ${houseNumber} ${
      flatNumber && (flatNumber.length > 0 ? `/${flatNumber}` : '')
    }, ${postalCode} ${city?.name}`;

    const addressToLocalize = Geocode.getGeolocation(address);

    addressToLocalize.then((a) => {
      setMarkupPosition(a);
      dispatch(localizeMarker(a));
    });
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      data-cy="yourCesspoolAddress"
    >
      <CardTitle sx={{ mb: 3 }}>Adres twojego zbiornika</CardTitle>

      {isTerytIncorrect && (
        <WrongTeryt>
          Wykryliśmy błąd w danych zbiornika. Uzupełnij ponownie puste pola
          adresu i zapisz.
        </WrongTeryt>
      )}

      <TerytAddressForm
        formik={formik}
        isLoading={
          tankDetailsLoading && !addingNewTank && cesspool_name.length === 0
        }
      />

      <ButtonWrapper>
        <BorderButton
          disabled={!formik.values?.city || formik.values?.city?.name === ''}
          onClick={() => findMe()}
          data-cy="locate_button"
        >
          Lokalizuj
        </BorderButton>
      </ButtonWrapper>
      <Box
        sx={{
          height: '340px',
          borderRadius: '10px',
          border: '1px solid gray',
          overflow: 'hidden',
        }}
      >
        {mapLoaded && (
          <DynamicMap
            position={markupPosition}
            updatePosition={(val: any) => setUpdatedPosition(val)}
          />
        )}
      </Box>
      <ButtonWrapper>
        <Badge
          badgeContent="!"
          color="error"
          overlap="circular"
          sx={{ left: 85, top: 10 }}
          invisible={!isMarkupPositionChanged}
        />
        <GradientButton
          type="submit"
          sx={{ m: 0 }}
          disabled={tankHaveActiveOrder}
          data-cy="save_localization_data"
        >
          Zapisz zmiany
        </GradientButton>
      </ButtonWrapper>
    </Box>
  );
};

export default SewageLocalizationForm;
