import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Typography, Paper, Box, Grid, CircularProgress } from '@mui/material';
import { ExitIcon } from '@components/elements/ExitIcon';
import { styled } from '@mui/material/styles';
import { BorderButton, GradientButton } from '@styles/styled';

import { useDispatch, useSelector } from 'react-redux';
import { PaymentInterface, RootState } from '@redux/interfaces';
import {
  actionDone,
  addCreditCard,
  getCreditCard,
  setAddCardRedirectedOff,
} from '@redux/payment/actions';
import { useOnScreen } from '@hooks/useOnScreen';
import { getPosId } from '@redux/payment/actions';
// @ts-ignore
import { isSafari } from '@firebase/util';
import { globalNavigate } from '../../../navigation/globalHistory';
import { getPayu, getSecureForm } from '@utils/payuHelpers';
import theme from '@styles/theme';
import { toast } from 'react-toastify';

const Row = styled(Box)<{ loading: boolean }>`
  display: ${(p) => (p.loading ? 'flex' : 'none')};
  justify-content: center;
  gap: 20px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const ErrorText = styled('p')<{ visible: boolean }>`
  opacity: ${(p) => (p.visible ? 1 : 0)};
  color: ${theme.palette.error.main};
  margin-top: 5px;
`;

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const Div = styled('div')<{ ref: MutableRefObject<HTMLDivElement | null> }>``;

const ExtensiblePaper = styled(Paper)`
  padding: 24px;
  border-radius: 25px;
  width: 50vw;
  box-shadow: 0px 16px 24px rgba(10, 60, 70, 0.16);

  @media (max-width: 660px) {
    width: 90vw;
  }
`;

const Title = styled(Typography)`
  font-size: 30px;
  font-weight: 600;
  text-align: left;

  @media (max-width: 660px) {
    font-size: 18px;
  }
`;

const InfoLabel = styled(Typography)`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 16px;
  padding-top: 32px;
  line-height: 2.275rem;

  @media (max-width: 660px) {
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 16px;
  }
`;

const optionsForms = {
  cardIcon: true,
  style: {
    basic: {
      fontSize: '24px',
    },
  },
  placeholder: {
    number: '',
    date: 'MM/YY',
    cvv: '',
  },
  lang: 'pl',
};

const AddNewCard = () => {
  const [addInProgress, setAddInProgress] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({
    cvv: null,
    date: null,
    number: null,
  });
  const [redirected, setRedirected] = useState(false);

  const exit = () => {
    globalNavigate('payments');
  };

  const {
    redirectedFromDashboard,
    posId,
    secureBody,
    loading,
    addedCard,
    creditCard,
    actionNeeded,
    payuStatus,
  } = useSelector<RootState, PaymentInterface>((state) => state.payment);

  const { redirect_uri } = payuStatus;

  const dispatch = useDispatch();
  const cardRef = useRef(null);
  const cvvRef = useRef(null);
  const dateRef = useRef(null);

  const cardOnScreen = useOnScreen(cardRef);
  const cvvOnScreen = useOnScreen(cvvRef);
  const dateOnScreen = useOnScreen(dateRef);

  let cardNumber: any;
  let cardCvv: any;
  let cardDate: any;

  useEffect(() => {
    dispatch(getPosId());
  }, []);

  const handleClose = () => {
    if (isSafari() && redirect_uri.length > 0 && !redirected) {
      window.open(redirect_uri, '_blank', 'noreferrer');
      setRedirected(true);
    } else {
      if (redirectedFromDashboard && creditCard.length >= 1) {
        globalNavigate('/dashboard');
        dispatch(setAddCardRedirectedOff());
      }
      if (!redirectedFromDashboard) {
        dispatch(getCreditCard());
        exit();
      }
    }
  };

  useEffect(() => {
    if (cardOnScreen && cvvOnScreen && dateOnScreen && posId) {
      const _secureForm = getSecureForm(posId);

      cardNumber = _secureForm.add('number', optionsForms);
      cardCvv = _secureForm.add('cvv', optionsForms);
      cardDate = _secureForm.add('date', optionsForms);

      cardNumber.render('#payu-card-number');
      cardCvv.render('#payu-card-cvv');
      cardDate.render('#payu-card-date');
    }
  }, [cardOnScreen, cvvOnScreen, dateOnScreen, posId]);

  useEffect(() => {
    window.onbeforeunload = function () {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const addCard = () => {
    const _payu = getPayu(posId);

    try {
      _payu
        .tokenize('MULTI')
        .then(function (result: {
          status: string;
          body: { token: string };
          error: { messages: any };
        }) {
          if (result.status === 'SUCCESS') {
            const token = { card_token: result.body.token };
            setAddInProgress(true);
            dispatch(addCreditCard(token));
          } else {
            const errors = result.error.messages;
            setErrorMessage({
              cvv: null,
              date: null,
              number: null,
            });
            if (Array.isArray(errors)) {
              errors.forEach((error: any) => {
                if (!error.source) {
                  toast.error(
                    'Wystąpił niezidentyfikowany błąd. Upewnij się czy dane Twojej karty są poprawne i spróbuj ponownie.'
                  );
                } else {
                  setErrorMessage((prevState: any) => ({
                    ...prevState,
                    [error.source]: error.message,
                  }));
                }
              });
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (actionNeeded) {
      setAddInProgress(false);
    }
  }, [actionNeeded]);

  return (
    <ExtensiblePaper>
      <Grid container>
        <Grid item xs={10}>
          <Title>Dodawanie Karty Płatniczej</Title>
        </Grid>
        <Exit
          item
          xs={2}
          sx={{ textAlign: 'right' }}
          onClick={() => {
            exit();
            dispatch(setAddCardRedirectedOff());
            dispatch(getCreditCard());
            if (actionNeeded) dispatch(actionDone());
          }}
        >
          <ExitIcon />
        </Exit>
      </Grid>
      {!addInProgress && (
        <InfoLabel>
          {actionNeeded ? 'Wymagana dodatkowa akcja' : 'Uzupełnij dane z karty'}
        </InfoLabel>
      )}
      {!secureBody && !addedCard && !actionNeeded && (
        <Row sx={{ pb: 2 }} loading={!loading}>
          <div className="card-container">
            <div className="input">
              <aside>Numer Karty</aside>
              <Div
                ref={cardRef}
                className="payu-card-form"
                id="payu-card-number"
              />
              <ErrorText visible={!!errorMessage.number}>
                {errorMessage.number}
              </ErrorText>
            </div>

            <div className="input">
              <aside>Ważna do</aside>
              <Div
                ref={dateRef}
                className="payu-card-form"
                id="payu-card-date"
              />
              <ErrorText visible={!!errorMessage.date}>
                {errorMessage.date}
              </ErrorText>
            </div>

            <div className="input">
              <aside>CVV</aside>
              <Div ref={cvvRef} className="payu-card-form" id="payu-card-cvv" />
              <ErrorText visible={!!errorMessage.cvv}>
                {errorMessage.cvv}
              </ErrorText>
            </div>
          </div>
        </Row>
      )}
      {addInProgress && !actionNeeded && (
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
          <CircularProgress />
        </Box>
      )}
      {actionNeeded && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ margin: '0 auto' }}>
            <Typography sx={{ textAlign: 'center' }}>
              Wymagana jest potwierdzenie na stronie PayU.
            </Typography>
            <Typography sx={{ textAlign: 'center' }}>
              Jeśli już autoryzowałeś kartę możesz wrócić do poprzedniej strony.
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <BorderButton onClick={handleClose}>
              {isSafari() && redirect_uri.length > 0 && !redirected
                ? 'Kontynuuj autoryzowanie płatności'
                : 'Powrót'}
            </BorderButton>
          </Box>
        </Box>
      )}
      {!addInProgress && !actionNeeded && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <GradientButton
            sx={{ margin: '10px 0' }}
            data-cy="submit_save_credit_card"
            onClick={() => addCard()}
          >
            Zapisz kartę
          </GradientButton>
        </Box>
      )}
    </ExtensiblePaper>
  );
};

export default AddNewCard;
