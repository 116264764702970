'use client';

import * as yup from 'yup';

export default yup.object({
  old_password: yup.string().required('Hasło jest wymagane.'),
  new_password1: yup
    .string()
    .required('Hasło jest wymagane.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
      'Hasło musi mieć co najmniej 8 znaków, dużą i małą literę oraz cyfrę'
    ),
  new_password2: yup
    .string()
    .required('Potwierdź swoje hasło.')
    .oneOf([yup.ref('new_password2'), null], 'Hasła nie pasują do siebie.'),
});
