import { styled } from '@mui/material/styles';
import { Box, FormControl } from '@mui/material';

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: 1100px) and (max-width: 1350px) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const ResidentsForm = styled(FormControl)`
  width: calc(50% - 10px);

  @media (min-width: 1100px) and (max-width: 1350px) {
    width: 100%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
`;

export const CalendarWrapper = styled('div')`
  display: flex;
  width: calc(50% - 10px);

  @media (max-width: 1350px) {
    width: 100%;
  }
`;
