import React from 'react';
import { Container } from '@mui/material';
import Image from 'next/image';
import logo from '../../../../public/images/szambo.online.png';
import { CustomPaper } from '@styles/styled';
import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
};

const Wrapper = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyMailLayout = ({ children }: Props) => {
  return (
    <Wrapper maxWidth={false}>
      <Container maxWidth="sm">
        <CustomPaper
          sx={{
            p: 5,
            borderRadius: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Image src={logo} alt={'logo'} />

          {children}
        </CustomPaper>
      </Container>
    </Wrapper>
  );
};

export default VerifyMailLayout;
