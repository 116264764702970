import React from 'react';
import { styled } from '@mui/material/styles';
import { SvgIcon, Typography } from '@mui/material';

import IcoWrapper from '@components/elements/MenuItem/IcoWrapper';
import { MenuType } from '@customTypes/menu/MenuType';

import { useLocation } from 'react-router-dom';

const Wrapper = styled('div')`
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: #e7eaed;
    cursor: pointer;

    ${IcoWrapper} {
      color: #fff;
      background: #506266;
    }
  }

  @media (max-width: 905px) and (min-width: 660px) {
    flex-direction: column;
    margin-bottom: 5px;
    text-align: center;
    width: 150px;
  }

  @media (max-width: 660px) {
    justify-content: left;
    align-items: center;
  }

  @media (max-height: 480px) {
    margin: 0;
    padding: 5px;
    width: 80%;
  }
`;

type Props = {
  item: MenuType;
  handleMenuPress: ({ title, path }: { title: string; path: string }) => void;
};

const MenuItem: React.FC<Props> = ({ item, handleMenuPress }) => {
  const { path, svgIco, title } = item;

  const location = useLocation();

  return (
    <Wrapper
      data-cy={path}
      onClick={() => {
        handleMenuPress({ title, path });
      }}
    >
      <IcoWrapper active={location.pathname === path ? 1 : 0}>
        <SvgIcon
          component={svgIco}
          viewBox={
            title === 'Mój zbiornik'
              ? '0 0 12 16'
              : title === 'Wyloguj'
              ? '0 0 14.4 16'
              : '0 0 16 16'
          }
          fontSize={'small'}
        />
      </IcoWrapper>
      <Typography
        variant={'body1'}
        fontWeight={'600'}
        color={'#506266'}
        sx={{ p: 1 }}
      >
        {title}
      </Typography>
    </Wrapper>
  );
};

export default MenuItem;
