import { NextPage } from 'next';
import { Typography, Box } from '@mui/material';
import Image from 'next/image';
import AuthLayoutWithoutCaptcha from '@components/layouts/AuthLayoutWithoutCaptcha';

import logo from '../../../public/images/szambo.online.png';

import React from 'react';
import { LoadingButton } from '@mui/lab';
import theme from '@styles/theme';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import { useNavigate } from 'react-router-dom';

const ForgottenPasswordDonePage: NextPage = () => {
  const navigate = useNavigate();

  return (
    <AuthBackgroundLayout>
      <AuthLayoutWithoutCaptcha>
        <Image src={logo} alt={'logo'} onClick={() => navigate('/')} />
        <Box sx={{ mt: 5, pr: 5, pl: 5 }}>
          <Typography
            component="h2"
            variant="h4"
            sx={{ textAlign: 'center', p: 3 }}
          >
            Resetowanie hasła
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            sx={{ mb: 3, textAlign: 'center' }}
          >
            Wysłaliśmy do Ciebie e-mail z linkiem do zmiany hasła{' '}
          </Typography>
          <Typography
            component="h6"
            variant="subtitle1"
            sx={{ mb: 5, textAlign: 'center' }}
          >
            Skontaktuj się z nami, jeśli nie otrzymasz go w ciągu kilku minut.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
              onClick={() => navigate('/login', { replace: true })}
              data-cy="backToLogin"
              sx={{
                borderRadius: 10,
                border: `3px solid ${theme.palette.primary.main}`,
                color: 'black',
                fontWeight: 'bold',
                pr: 3,
                pl: 3,
                pt: 1,
                pb: 1,
                textTransform: 'unset',
                fontSize: 16,
              }}
            >
              Wróć do logowania
            </LoadingButton>
          </Box>
        </Box>
      </AuthLayoutWithoutCaptcha>
    </AuthBackgroundLayout>
  );
};

export default ForgottenPasswordDonePage;
