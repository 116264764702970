import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';
import { Box, Container, Paper, SvgIcon, Typography } from '@mui/material';
import { SingleAgreementInterface } from '@redux/interfaces';
import ModalHeader from '@components/elements/ModalHeader';
import { Bolder, Row } from '@components/elements/OrderDetails';
import download from '../../../../public/icons/download.svg';
import theme from '@styles/theme';
import { SmallRow } from '@components/elements/DropsGrid/styled';
import React from 'react';
import { ErrorGradientButton, GradientButton } from '@styles/styled';
import { useDispatch } from 'react-redux';
import { downloadAgreement } from '@redux/agreements/actions';

type PropsT = {
  close: () => void;
  edit: () => void;
  deleteAgreement: () => void;
  selectedAgreement: SingleAgreementInterface | null;
};
const AgreementDetails = ({
  close,
  selectedAgreement,
  edit,
  deleteAgreement,
}: PropsT) => {
  const dispatch = useDispatch();

  const getStatus = () => {
    if (selectedAgreement?.expiration_date) {
      const dateOfExecution = Date.parse(selectedAgreement.expiration_date);

      let currentDate: any = new Date();
      currentDate =
        currentDate.getFullYear() +
        '/' +
        (currentDate.getMonth() + 1) +
        '/' +
        currentDate.getDate();
      currentDate = Date.parse(currentDate);

      if (Number(currentDate) > Number(dateOfExecution)) {
        return 'Nieaktywna';
      } else if (Number(currentDate) <= Number(dateOfExecution)) {
        return 'Aktywna';
      }
    }
    return 'Aktywna';
  };

  return (
    <SemiTransparentBackground
      additionalStyle={{
        backgroundImage:
          'linear-gradient(120deg,rgba(87, 140, 34, 0.95),rgba(168, 195, 0, 0.95))',
      }}
    >
      <Container maxWidth="md">
        <Paper
          sx={{
            p: 3,
            borderRadius: '25px',
            boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          }}
        >
          <ModalHeader title="Szczegóły umowy" handleExit={close} />
          <Row>
            <Bolder>Operator</Bolder>
            <Typography>{selectedAgreement?.company_name}</Typography>
          </Row>
          <Row>
            <Bolder>Data</Bolder>
            <Typography>{selectedAgreement?.conclusion_date}</Typography>
          </Row>
          <Row>
            <Bolder>Status</Bolder>
            <Typography>{getStatus()}</Typography>
          </Row>
          <Row
            onClick={() => {
              dispatch(downloadAgreement(`${selectedAgreement?.id}/download`));
            }}
          >
            <Bolder>Umowa</Bolder>
            <SmallRow>
              <SvgIcon
                component={download}
                viewBox="0 0 18 18"
                sx={{ mr: 0.5 }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 'bold',
                  '-webkit-user-select': 'none',
                  userSelect: 'none',
                }}
                color={theme.palette.primary.dark}
              >
                Pobierz
              </Typography>
            </SmallRow>
          </Row>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <GradientButton
              sx={{ fontSize: '14px', m: 0 }}
              disabled={!selectedAgreement?.added_by_user}
              onClick={edit}
            >
              Edytuj
            </GradientButton>
            <ErrorGradientButton
              sx={{ fontSize: '14px' }}
              onClick={() => {
                deleteAgreement();
              }}
              disabled={!selectedAgreement?.added_by_user}
            >
              Usuń
            </ErrorGradientButton>
          </Box>
        </Paper>
      </Container>
    </SemiTransparentBackground>
  );
};

export default AgreementDetails;
