import { FormikProps } from 'formik';
import CustomTextField from '@components/elements/CustomTextField';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type InptProps = {
  noMargin?: boolean;
  flex?: string;
};

type RowProps = {
  nowrap?: number;
};

const InputWrapper = styled('div')`
  margin-left: ${(p: InptProps) => (p.noMargin ? 0 : '16px')};
  flex: ${(p: InptProps) => (p.flex ? p.flex : 1)};

  @media (max-width: 660px) {
    margin-left: 0;
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 660px) {
    flex-direction: ${(p: RowProps) => (p.nowrap ? 'row' : 'column')};
    justify-content: center;
    gap: 15px;
  }
`;

const ClientOrCompanyForm = ({ formik }: { formik: FormikProps<any> }) => (
  <Wrapper>
    {formik.values.clientType === 'T_COM' && (
      <Row>
        <InputWrapper noMargin={true} flex="2">
          <CustomTextField
            id="companyName"
            label="NAZWA FIRMY"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.companyName)}
            helperText={
              typeof formik.errors.companyName === 'string' &&
              formik.errors.companyName
            }
          />
        </InputWrapper>
        <InputWrapper flex="1.5">
          <CustomTextField
            id="nip"
            label="NIP"
            autoComplete="nip"
            value={formik.values.nip}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.nip)}
            helperText={
              typeof formik.errors.nip === 'string' && formik.errors.nip
            }
          />
        </InputWrapper>
      </Row>
    )}

    {(formik.values.clientType !== 'T_COM' || formik.values.isInOrder) && (
      <Row>
        <InputWrapper noMargin={true}>
          <CustomTextField
            id="name"
            label="IMIĘ"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.name)}
            helperText={
              typeof formik.errors.name === 'string' && formik.errors.name
            }
          />
        </InputWrapper>
        <InputWrapper>
          <CustomTextField
            id="surname"
            label="NAZWISKO"
            value={formik.values.surname}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.surname)}
            helperText={
              typeof formik.errors.surname === 'string' && formik.errors.surname
            }
          />
        </InputWrapper>
      </Row>
    )}
  </Wrapper>
);

export default ClientOrCompanyForm;
