import {
  CityWrapper,
  NumbersWrapper,
  Row,
  SecondRow,
} from '@forms/TerytAddressForm/styled';
import { Skeleton } from '@mui/material';
import React from 'react';

const FormSkeleton = () => (
  <>
    <Row>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={56}
        sx={{ borderRadius: 2 }}
      />
      <CityWrapper>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={56}
          sx={{ marginTop: '15px', borderRadius: 2 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={56}
          sx={{ marginTop: '15px', borderRadius: 2 }}
        />
      </CityWrapper>
    </Row>
    <SecondRow>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={56}
        sx={{ marginTop: '15px', borderRadius: 2 }}
      />
      <NumbersWrapper sx={{ flex: 2 }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={56}
          sx={{ marginTop: '15px', borderRadius: 2 }}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={56}
          sx={{ marginTop: '15px', borderRadius: 2 }}
        />
      </NumbersWrapper>
    </SecondRow>
  </>
);

export default FormSkeleton;
