import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments } from '@redux/auth/actions';
import { AuthApiResult, RootState } from '@redux/interfaces';
import IsBackendOffline from '@components/elements/IsBackendOffline';

type Props = {
  children: React.ReactNode;
  additionalStyle?: object | null;
};

const CustomContainer = styled(Container)`
  align-items: center;

  @media (max-width: 660px) {
    align-items: flex-start;
  }
`;

const AuthBackgroundLayout = ({ children, additionalStyle }: Props) => {
  const { documents, isOffline } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (documents.length === 0) dispatch(getDocuments());
  });

  return (
    <>
      {isOffline && <IsBackendOffline />}
      <CustomContainer
        sx={[
          {
            minHeight: '100vh',
            pb: 2,
            display: 'flex',
            justifyContent: 'center',
            pt: 2,
            backgroundImage: 'url("/images/Background.webp")',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          },
          additionalStyle !== undefined && { ...additionalStyle },
        ]}
        maxWidth={false}
      >
        {children}
      </CustomContainer>
    </>
  );
};

export default AuthBackgroundLayout;
