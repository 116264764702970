import UniversalModalLayout from '@components/layouts/UniversalModalLayout';
import ModalHeader from '@components/elements/ModalHeader';
import { DropRecordModalsT } from '@customTypes/types';
import React from 'react';
import { Description } from '@components/modules/DropZoneModals/styled';
import AddNewDropRecordForm from '@forms/AddNewDropRecordForm';
import { Box } from '@mui/material';
import ReportComplaintForm from '@forms/ReportComplaintForm';

const ReportComplaintModal = ({
  itemToEdit,
  closeModal,
}: DropRecordModalsT) => {
  return (
    <UniversalModalLayout modalPosition="flex-start">
      <ModalHeader title="Złóż reklamację wywozu" handleExit={closeModal} />
      <Description>
        Poniższe dane dot. wywozu wraz z adresem zbiornika zostaną dołączone do
        treści reklamacji
      </Description>
      <Box sx={{ backgroundColor: 'rgba(154,154,154, 0.4)', p: 2 }}>
        <AddNewDropRecordForm
          closeModal={closeModal}
          itemToEdit={itemToEdit}
          isReadOnly
        />
      </Box>
      <ReportComplaintForm orderRecord={itemToEdit} closeModal={closeModal} />
    </UniversalModalLayout>
  );
};

export default ReportComplaintModal;
