import React from 'react';
import { BorderButton, ErrorGradientButton } from '@styles/styled';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { ExitIcon } from '@components/elements/ExitIcon';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCardDone, deleteCreditCard } from '@redux/payment/actions';
import {
  CreditCardInterface,
  PaymentInterface,
  RootState,
} from '@redux/interfaces';

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const FlexCenterBox = styled(Box)`
  display: flex;
  justify-content: center;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: left;
  }
`;

type Props = {
  exit: () => void;
  cardToDelete: CreditCardInterface | null;
};

export const DeleteCard = ({ exit, cardToDelete }: Props) => {
  const { deleteCard, loading } = useSelector<RootState, PaymentInterface>(
    (state) => state.payment
  );

  const dispatch = useDispatch();

  return (
    <Box>
      <Paper
        sx={{
          p: 3,
          borderRadius: '25px',
          boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
        }}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={0} md={2} />
          <Grid
            item
            xs={!deleteCard ? 8 : 12}
            sx={{ textAlign: 'center' }}
            color="black"
          >
            <Title variant="h3">Usuwanie karty</Title>
          </Grid>
          <Exit
            item
            xs={4}
            md={2}
            sx={{ textAlign: 'right' }}
            onClick={() => {
              exit();
              if (deleteCard) dispatch(deleteCardDone());
            }}
          >
            {!deleteCard && <ExitIcon />}
          </Exit>
        </Grid>
        <Typography sx={{ textAlign: 'center', mt: 1, fontWeight: 'bold' }}>
          {!deleteCard
            ? `Czy na pewno chcesz usunąć dane karty o numerze ${
                cardToDelete && cardToDelete.number_masked
              } ?`
            : 'Dane Twojej karty zostały usunięte.'}
        </Typography>

        <Row>
          <FlexCenterBox>
            <BorderButton
              sx={{ margin: '10px 0' }}
              onClick={() => {
                exit();
                if (deleteCard) dispatch(deleteCardDone());
              }}
              data-cy="exit_delete_credit_card"
              loading={loading}
            >
              {!deleteCard ? 'Nie usuwaj' : 'Zamknij okno'}
            </BorderButton>
          </FlexCenterBox>
          {!deleteCard && (
            <FlexCenterBox>
              <ErrorGradientButton
                sx={{ margin: '10px 0' }}
                data-cy="delete_credit_card"
                onClick={() => {
                  if (cardToDelete)
                    dispatch(deleteCreditCard(cardToDelete.card_id));
                }}
                loading={loading}
              >
                Usuń
              </ErrorGradientButton>
            </FlexCenterBox>
          )}
        </Row>
      </Paper>
    </Box>
  );
};
