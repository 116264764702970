import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BorderButton, GradientButton, OrderRow } from '@styles/styled';
import CustomTextField from '@components/elements/CustomTextField';
import { PanoramaInterface } from '@redux/interfaces';
import {
  levelEndFA,
  levelStartFA,
  selectContentFA,
} from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';

type Props = {
  driver: PanoramaInterface;
  prevStep: () => void;
  nextStep: () => void;
  formik: any;
};

const Wrapper = styled(Box)`
  width: 90%;
  margin: 20px auto;

  @media (max-width: 660px) {
    width: 90%;
    margin: 20px 0 0 0;
    padding-bottom: 20px;
  }
`;

const HelperText = styled('p')`
  font-size: 14px;
  color: gray;
  margin: 0;
`;

const CostInputWrapper = styled(Box)`
  width: 60%;
  margin: 20px auto;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const CompanyName = styled(Typography)`
  font-size: 24px;
  text-align: center;
  font-weight: 300;
  line-height: 26px;
`;

const ThirdStepNonApp = ({ prevStep, nextStep, driver, formik }: Props) => {
  const { name } = driver;

  const handleNext = () => {
    selectContentFA('set_cost', '', { price: formik.values.cost });
    levelEndFA(types.LEVEL_NAME_NUM[3], true);
    levelStartFA(types.LEVEL_NAME_NUM[4]);
    nextStep();
  };

  const handlePrev = () => {
    levelEndFA(types.LEVEL_NAME_NUM[3], false);
    prevStep();
  };

  return (
    <Wrapper>
      <CompanyName>{name}</CompanyName>

      <CostInputWrapper>
        <CustomTextField
          id="cost"
          label="Całkowity koszt usługi"
          value={formik.values.cost}
          onChange={formik.handleChange}
          error={formik.touched.cost && Boolean(formik.errors.cost)}
          helperText={formik.touched.cost && formik.errors.cost}
          inputProps={{
            endAdornment: (
              <>
                <p>zł</p>
              </>
            ),
          }}
        />
        <HelperText>
          To pole jest nieobowiązkowe, możesz uzupełnić je póżniej w zakładce{' '}
          <strong>Moje wywozy</strong>.
        </HelperText>
      </CostInputWrapper>

      <OrderRow>
        <BorderButton onClick={handlePrev}>Wstecz</BorderButton>
        <GradientButton sx={{ margin: 0 }} onClick={handleNext}>
          Dalej
        </GradientButton>
      </OrderRow>
    </Wrapper>
  );
};

export default ThirdStepNonApp;
