import React, { useEffect, useState } from 'react';
import {
  RotatedArrow,
  TankNameText,
} from '@components/elements/ProgressBarComponent/styled';
import arrow from '../../../../public/icons/angle-down-solid.svg';
import { DashboardInterface, RootState } from '@redux/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import theme from '@styles/theme';
import { styled } from '@mui/material/styles';
import { setSelectedTankIdAction } from '@redux/userCesspools/actions';
import { setCurrentTankAction } from '@redux/dashboard/actions';

const { primary } = theme.palette;

const Wrapper = styled('div')`
  border: 2px solid #a8cc1f;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 1200px) {
    border: none;
    width: 350px;
    border-radius: unset;
  }
`;

const ArrowsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 1200px) {
    width: 70%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const MobileInfo = styled(Typography)`
  @media (min-width: 1200px) {
    display: none;
  }

  @media (max-width: 300px) {
    font-size: 16px;
  }
`;

export const TankSelector = () => {
  const [locallySelectedTankId, setLocallySelectedTankId] = useState<number>(0);

  const { data, currentTank, loading } = useSelector<
    RootState,
    DashboardInterface
  >((state) => state.dashboard);

  const dispatch = useDispatch();

  const clientProperty = data.client_properties;

  useEffect(() => {
    if (loading) {
      setLocallySelectedTankId(0);
    }

    if (
      clientProperty.length >= 1 &&
      locallySelectedTankId <= clientProperty.length
    ) {
      dispatch(
        setSelectedTankIdAction(clientProperty[locallySelectedTankId].id)
      );
      dispatch(setCurrentTankAction(clientProperty[locallySelectedTankId]));
    }
  }, [locallySelectedTankId]);

  return (
    <Wrapper>
      {clientProperty.length > 1 && (
        <ArrowsWrapper>
          <RotatedArrow
            rotated={1}
            component={arrow}
            viewBox="0 0 384 512"
            sx={{ mr: '5px', color: primary.contrastText, cursor: 'pointer' }}
            onClick={() => {
              if (locallySelectedTankId >= 1) {
                setLocallySelectedTankId((prevState) => prevState - 1);
              } else {
                setLocallySelectedTankId(clientProperty.length - 1);
              }
            }}
          />
          <Box>
            <MobileInfo
              sx={{
                fontWeight: 'bold',
                mt: 1,
                textAlign: 'center',
              }}
              variant="h6"
            >
              Wybór zbiornika
            </MobileInfo>
            <TankNameText
              sx={{
                fontWeight: 700,
                textTransform: 'uppercase',
              }}
            >
              {currentTank.cesspool_name}
            </TankNameText>
          </Box>

          <RotatedArrow
            rotated={0}
            component={arrow}
            viewBox="0 0 384 512"
            sx={{ mr: '5px', color: primary.contrastText, cursor: 'pointer' }}
            onClick={() => {
              if (locallySelectedTankId >= clientProperty.length - 1) {
                setLocallySelectedTankId(0);
              } else {
                setLocallySelectedTankId((prevState) => prevState + 1);
              }
            }}
          />
        </ArrowsWrapper>
      )}
    </Wrapper>
  );
};
