import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { abortController } from '@services/RestService';
import React from 'react';
import { styled } from '@mui/material/styles';

const Container = styled(Box)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1001;
  border-radius: 25px;
`;

type PropsT = {
  message?: string;
  optionalAction?: () => void;
};

const ContractLoaderContainer = ({
  message = 'Przygotowujemy umowę do pobrania...',
  optionalAction = undefined,
}: PropsT) => (
  <Container data-cy="contract-loader-container">
    <Typography fontSize="26" fontWeight="bold">
      {message}
    </Typography>
    <CircularProgress sx={{ marginTop: 3, marginBottom: 3 }} />
    <Typography>Wczytywanie trwa zbyt długo?</Typography>
    <Link
      data-cy="abort-contract"
      sx={{ color: 'black', fontWeight: 'bold' }}
      onClick={() => {
        abortController.abort();
        if (optionalAction) {
          optionalAction();
        }
      }}
    >
      Anuluj pobieranie
    </Link>
  </Container>
);

export default ContractLoaderContainer;
