import React, { useState } from 'react';
import RootNavigation from '../navigation/RootNavigation';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalHistory } from '../navigation/globalHistory';
import { useSearchParams } from 'next/navigation';
import { toast } from 'react-toastify';

export default function App({ token }: { token: string | null }) {
  const searchParams = useSearchParams();
  const [logged, setLogged] = useState(false);

  if (token && window && window.sessionStorage) {
    if (!logged) {
      sessionStorage.setItem('TOKEN', token);
      caches.delete('pwabuilder-offline');
      setLogged(true);
    }
  }

  if (searchParams.get('pid')) {
    const paymentInProgress = localStorage.getItem('paymentInProgress');

    if (paymentInProgress === searchParams.get('pid')) {
      if (searchParams.get('error')) {
        toast.error(
          'Proces płatności PayU został przerwany lub wystąpił błąd podczas realizacji zlecenia. Odczekaj chwilę i spróbuj ponownie.'
        );
      } else {
        toast.success('Udało się opłacić wywóz 🥳');
      }
      localStorage.removeItem('paymentInProgress');
    }
  }
  return (
    <Router>
      <GlobalHistory>
        <RootNavigation />
      </GlobalHistory>
    </Router>
  );
}
