import { styled } from '@mui/material/styles';
import theme from '@styles/theme';
import { NotificationInterface } from '@redux/interfaces';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DashboardContext } from '../../../context/DashboardContext';

const ClearText = styled('p')`
  padding: 0;
  margin: 0;
`;

const Title = styled(ClearText)`
  font-weight: 600;
  padding-top: 10px;
`;
const Description = styled(ClearText)`
  color: #a1a1a1;
`;
const DateText = styled(ClearText)`
  text-align: right;
  color: #a1a1a1;
  font-size: 12px;
  padding: 5px 0;
`;

const NotiWrapper = styled('div')`
  padding: 5px;
  width: 100%;
  cursor: ${(p: { clickable: boolean }) =>
    p.clickable ? 'pointer' : 'default'};

  &:hover {
    background-color: #e7eaed;
  }
`;

const BorderBox = styled('div')`
  border-bottom: 2px solid #e1e1e1;
  padding: 0 10px;

  &:hover {
    background-color: #e7eaed;
  }
`;

const UnreadDot = styled('span')`
  height: 10px;
  width: 10px;
  background-color: ${theme.palette.primary.main};
  border-radius: 20px;
  display: inline-block;
  margin-left: 5px;
`;

const CustomLink = styled(Link)`
  font-weight: 600;
`;

export const SingleNotification = ({
  notification,
  arrayContains,
}: {
  notification: NotificationInterface;
  arrayContains: (item: string, is_unread: boolean) => void;
}) => {
  const [isUnread, setIsUnread] = useState(false);

  const { handleOrderLayoutVisible } = useContext(DashboardContext);
  const navigate = useNavigate();

  const { name } = notification;

  const formatDate = (date: string) => {
    const separator = date.indexOf('T');
    return date.slice(0, separator);
  };

  const additionalInfo = notification?.additional_kwargs;
  let cesspoolSize = additionalInfo?.cesspool_size
    ? additionalInfo?.cesspool_size?.split('.')
    : '0';
  cesspoolSize = additionalInfo?.cesspool_size ? cesspoolSize[0] : '0';

  const returnDescription = (type: string | null) => {
    switch (type) {
      case 'valid_card': // Karta dodana poprawnie
        return (
          <Description>
            Udało się! Do Twojego konta została dodana karta płatnicza, dzięki
            której wygodnie zapłacisz za kolejne odbiory nieczystości.
          </Description>
        );
      case 'incoming_disposal': // Masz dziś zaplanowany odbiór szamba
        return (
          <Description>
            Na dzisiaj zaplanowano wywóz szamba z Twojej posesji. Pamiętaj, by
            zapewnić kierowcy dostęp do zbiornika.
          </Description>
        );
      case 'disposal_done': // 'Odbiór szamba został potwierdzony. Całkowity koszt to: {} zł'.format(cost)
        return (
          <Description>
            Kierowca poinformował nas o odebraniu szamba z Twojej posesji.
            Szczegółowe informacje na temat kosztu usługi znajdziesz w zakładce
            <CustomLink to="/my-exports"> Moje wywozy</CustomLink>
          </Description>
        );
      case 'disposal_confirmed': // 'Zrealizowaliśmy Twoje zamówienie na wywóz szamba. Zabraliśmy {}m3 nieczystości.'.format(amount)
        return (
          <Description>
            Firma asenizacyjna {additionalInfo?.sanitation_company_name}{' '}
            odebrała z Twojego zbiornika {cesspoolSize}m3 nieczystości.
            Wszystkie szczegóły wywozu znajdziesz w zakładce{' '}
            <CustomLink to="/my-exports">Moje wywozy</CustomLink>.
          </Description>
        );
      case 'invalid_card': // Autoryzacja karty zakończona niepowodzeniem.
        return (
          <Description>
            Niestety, nie udało nam się zautoryzować i dodać karty płatniczej na
            twoim koncie. Spróbuj ponownie później.
          </Description>
        );
      case 'changed_date': // Zmieniono datę Twojego zamówienia.
        return (
          <Description>
            Ważna informacja! Firma asenizacyjna{' '}
            {additionalInfo?.sanitation_company_name} dokonała zmiany terminu
            zamówienia na dzień {additionalInfo?.changed_date}.
          </Description>
        );
      case 'debt_due': // Masz nieopłaconą fakturę.
        return (
          <Description>
            Na Twoim koncie jest przynajmniej jedna nieopłacona faktura. Opłać
            zaległość, by złożyć kolejne zamówienia odbioru.
          </Description>
        );
      case 'invalid_transaction': // Nie udało się pobrać środków.
        return (
          <Description>
            Twoja transakcja została odrzucona przez bank. Spróbuj ponownie
            później.
          </Description>
        );
      case 'report_complaint_success': // Potwierdzenie wysłania reklamacji
        return (
          <Description>
            Twoja reklamacja zostanie przekazana do firmy asenizacyjnej{' '}
            {additionalInfo?.sanitation_company_name}. Szczegóły znajdziesz w
            zakładce <CustomLink to="/my-exports">Moje wywozy</CustomLink>.
          </Description>
        );
      case 'ask_order_created_successfully': // Otrzymaliśmy Twoje zapytanie o wywóz.
        return (
          <Description>
            Potrzebujemy chwili na zweryfikowanie przesłanego zapytania i
            kontakt z dostępnymi firmami asenizacyjnymi, by umówić dla Ciebie
            usługę wywozu szamba.
          </Description>
        );
      case 'ask_order_canceled_by_service': // Anulowano propozycję wywozu
        return (
          <Description>
            Niestety, zaproponowana opcja wywozu szamba wyznaczona na dzień{' '}
            {additionalInfo?.planned_date} została anulowana. Możemy znaleźć dla
            Ciebie kolejny wywóz. Wystarczy, że zgłosisz ponownie
          </Description>
        );
      case 'ask_order_canceled': // Brak dostępnych firm asenizacyjnych na dzień {ask_order.planned_date.strftime("%d.%m.%Y")}
        return (
          <Description>
            Z przykrością powiadamiamy, że żadna z firm asenizacyjnych nie
            zdecydowała się na usługę wywozu szamba we wskazanym terminie.
            Spróbuj ponownie wybierając inną datę realizacji.
          </Description>
        );
      case 'ask_order_to_confirm': // Sprawdź propozycję wywozu na dzień {ask_order.planned_date.strftime("%d.%m.%Y")}
        return (
          <Description>
            Firma asenizacyjna {additionalInfo?.sanitation_company_name}{' '}
            wyraziła chęć zrealizowania w dniu {additionalInfo?.planned_date}{' '}
            usługi wywozu nieczystości ciekłych ze zbiornika{' '}
            {additionalInfo?.cesspool_name}. Zaakceptuj propozycję do dnia{' '}
            {additionalInfo?.planned_date_1}, w przeciwnym razie wygaśnie i nie
            będzie możliwości jej przywrócenia.
          </Description>
        );
      case 'inspection_request': // Urząd miasta/gminy prosi Cię o wypełnienie deklaracji zbiornika.
        return (
          <Description>
            {additionalInfo?.client_property_commune} rozpoczął kontrolę
            mieszkańca. Sprawdź i uzupełnij dane techniczne zbiornika{' '}
            {additionalInfo?.client_property_name}, dodaj umowy a w zakładce
            <CustomLink to="/my-exports">
              &quot;Moje wywozy&quot;
            </CustomLink>{' '}
            wgraj potwierdzenia wykonania odbioru z ostatnich 12 miesięcy. Dane
            prześlij następnie do urzędu. Sprawdź szczegóły w Mój zbiornik{' '}
            sekcji Cyfrowa kontrola mieszkańca.
          </Description>
        );
      case 'inspection_done': // Zakończono cyfrową kontrolę mieszkańca
        return (
          <Description>
            Urząd gminy {additionalInfo?.client_property_commune} otrzymał Twój
            dokument i zakończył proces Cyfrowej kontroli mieszkańca dla
            zbiornika {additionalInfo?.client_property_name}
          </Description>
        );
      case 'inspection_in_progress': // Masz nowy dokument do podpisania
        return (
          <Description>
            Urząd gminy {additionalInfo?.client_property_commune} wysłał
            protokół cyfrowej kontroli mieszkańca dla zbiornik{' '}
            {additionalInfo?.client_property_name}. Pobierz dokument, podpisz go
            cyfrowo i odeślij przez szambo.online
          </Description>
        );

      default:
        return '';
    }
  };

  useEffect(() => {
    setIsUnread(notification.is_unread);
  }, [notification]);

  const cesspoolWarning = [
    'cesspool_warning_100',
    'cesspool_warning_95',
    'cesspool_warning_90',
    'cesspool_warning_85',
    'cesspool_warning_80',
  ];
  const myExports = ['disposal_done', 'disposal_confirmed'];

  return (
    <NotiWrapper
      key={notification.id}
      clickable={[...cesspoolWarning, ...myExports].includes(name)}
      onMouseEnter={() => {
        arrayContains(notification.id, notification.is_unread);
        setIsUnread(false);
      }}
      onClick={() => {
        if (cesspoolWarning.includes(name)) {
          handleOrderLayoutVisible(true);
        } else if (myExports.includes(name)) {
          navigate('/my-exports');
        } else if (name === 'new_protocol_generated') {
          navigate('/my-sewage', {
            state: { id: notification.additional_kwargs?.client_property_id },
          });
        }
      }}
    >
      <BorderBox>
        <Title>
          {notification.message}
          {isUnread && <UnreadDot />}
        </Title>
        {returnDescription(notification.name)}
        <DateText>{formatDate(notification.created_at)}</DateText>
      </BorderBox>
    </NotiWrapper>
  );
};
