'use client';

import { styled } from '@mui/material/styles';
import theme from '@styles/theme';
import { Box, Typography } from '@mui/material';

export const DropZoneContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(p: { error?: string }) =>
    p.error ? theme.palette.error.main : theme.palette.primary.main};
  border-style: dashed;
  background-color: #fafafa;
  color: ${theme.palette.primary.contrastText};
  outline: none;
  transition: border 0.24s ease-in-out;

  &:hover {
    border-color: ${(p: { error?: string }) =>
      p.error ? theme.palette.error.dark : theme.palette.primary.dark};
  }
`;

export const Description = styled(Typography)`
  width: 80%;
  margin: 35px auto;
  font-weight: bold;
  text-align: center;

  @media (max-width: 450px) {
    width: 100%;
    margin: 15px auto;
    font-size: 18px;
  }
`;

export const BillPhoto = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

export const Row = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 10px 0;
  gap: 30px;
  align-items: flex-start;

  @media (max-width: 660px) {
    padding: 0;
    justify-content: space-between;
    flex-direction: column;
    gap: 0;
  }
`;

export const RedStar = styled('span')`
  color: red;

  &::before {
    content: '*';
  }
`;

export const DateLabel = styled('p')`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
