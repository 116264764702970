import { CardDescription } from '@styles/styled';
import { Typography } from '@mui/material';
import React from 'react';
import { getFormatedDate } from '@utils/formatDate';

type ProtocolWasSentPropsT = {
  protocolSendDate: string;
};

const ProtocolWasSent = ({ protocolSendDate }: ProtocolWasSentPropsT) => (
  <>
    <CardDescription sx={{ fontWeight: 'bold' }}>
      TWOJA DEKLARACJA ZOSTAŁA WYSŁANA DO URZĘDU
    </CardDescription>
    <Typography fontSize="14px">
      Dziękujemy za przesłanie deklaracji. Zgłoszenie zostanie zweryfikowane
      przez urzędnika, od którego otrzymasz podpisany protokół cyfrowej
      kontroli. Dokument będzie dostępny w aplikacji w niniejszej sekcji.
      Powiadomimy Cię jak tylko będzie gotowy do pobrania.
    </Typography>
    <Typography fontSize="14px" mt={2}>
      Data przekazania deklaracji {getFormatedDate(protocolSendDate)}
    </Typography>
  </>
);

export default ProtocolWasSent;
