'use client';

import * as yup from 'yup';

export default yup.object({
  postalCode: yup
    .string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}[- ]{0,1}\d{3}$/, 'Kod pocztowy nie jest poprawny')
    .min(6, 'Kod pocztowy powinien mieć 5 znaków')
    .max(6, 'Kod pocztowy powinien mieć 5 znaków'),
  houseNumber: yup
    .string()
    .nullable(true)
    .required('Numer budynku jest wymagany'),
  city: yup
    .object()
    .shape({
      name: yup.string().required('Miasto jest wymagane'),
    })
    .nullable()
    .required('Miasto jest wymagane'),
  province: yup.object().shape({
    name: yup.string().required('Województwo jest wymagane'),
  }),
});
