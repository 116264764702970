import {
  FlexContainer,
  CounterBox,
  LeftTypography,
  LeftSideWrapper,
  GaugeWrapper,
  NearerOrderText,
  InsideText,
  InfoText,
  TopText,
  TextIntoGauge,
  TopIntoGauge,
  SelectorWrapper,
  SetPreviousDrop,
} from './styled';
import theme from '@styles/theme';

import ReactCustomizableProgressbar from '@components/elements/ReactCustomizableProgressbar/ReactCustomizableProgressbar';

import React, { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useSelector } from 'react-redux';
import { DashboardInterface, RootState } from '@redux/interfaces';

import { TankSelector } from '@components/elements/TankSelector';

const SewageCounter = () => {
  const [currentColor, setCurrentColor] = useState<string>(
    theme.palette.primary.main
  );
  const [gaugeSize, setGaugeSize] = useState<number>(170);
  const [pointerSize, setPointerSize] = useState<number>(6);
  const [strokeWidth, setStrokeWidth] = useState<number>(20);
  const [amount, setAmount] = useState<number | null>(0);
  const [bottomTextColor, setBottomTextColor] = useState<string | null>(null);
  const [bottomText, setBottomText] = useState<string>('');

  const { data, currentTank } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );

  const clientProperty = data.client_properties;

  const wrapper = useRef(null);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (clientProperty.length >= 1) {
      setAmount(currentTank.estimated_sewage);
    }
    if (clientProperty.length === 0) {
      setAmount(null);
    }
    setWarningMessage();
  }, [data, currentTank]);

  useEffect(() => {
    if (amount && amount >= 80 && amount < 90) {
      setCurrentColor(theme.palette.warning.main);
    } else if (amount && amount >= 90) {
      setCurrentColor(theme.palette.error.main);
    } else {
      setCurrentColor(theme.palette.primary.main);
    }
  }, [amount]);

  useEffect(() => {
    if (width >= 906) {
      setGaugeSize(170);
    }
    if (width <= 905) {
      setGaugeSize(150);
    }
    if (width <= 865) {
      setGaugeSize(120);
    }
    if (width <= 785) {
      setGaugeSize(100);
    }
    if (width <= 660) {
      setGaugeSize(120);
    }
    if (width <= 480) {
      setGaugeSize(110);
    }
    if (width <= 310) {
      setGaugeSize(85);
      setStrokeWidth(10);
      setPointerSize(4);
    } else {
      setStrokeWidth(20);
      setPointerSize(6);
    }
  }, [height, width]);

  const setWarningMessage = () => {
    const { active_orders } = data;

    const isActive = active_orders.filter(
      ({ client_property_id }) => client_property_id === currentTank.id
    );

    if (isActive.length === 1) {
      const { date } = isActive[0];

      let today: Date | number = new Date();
      today.setHours(0, 0, 0, 0);
      today = today.getTime();

      let orderDate: Date | number = new Date(date);
      orderDate.setHours(0, 0, 0, 0);
      orderDate = orderDate.getTime();

      if (orderDate > today) {
        setBottomTextColor('#621B16');
        setBottomText('Zamówienie wywozu potwierdzone');
      } else if (orderDate === today) {
        setBottomTextColor('#621B16');
        setBottomText('Spodziewaj się odbioru już dziś!');
      } else if (orderDate < today) {
        setBottomTextColor('#D32F2F');
        setBottomText('Czekamy na potwierdzenie odbioru');
      }
    } else {
      setBottomTextColor(null);
      setBottomText('Pora zamówić wywóz!');
    }
  };

  return (
    <FlexContainer maxWidth="md" disableGutters ref={wrapper}>
      <LeftSideWrapper>
        <TopText color={theme.palette.primary.contrastText} variant="h5">
          Miernik zapełnienia zbiornika
        </TopText>
        <SelectorWrapper>
          <TankSelector />
        </SelectorWrapper>
      </LeftSideWrapper>

      <CounterBox id="counterBox">
        <GaugeWrapper>
          <ReactCustomizableProgressbar
            radius={gaugeSize}
            progress={amount ? amount : 0}
            cut={120}
            rotate={-210}
            strokeWidth={strokeWidth}
            strokeColor={currentColor}
            trackStrokeWidth={strokeWidth}
            pointerRadius={pointerSize}
            pointerFillColor={currentColor}
            pointerStrokeColor={currentColor}
            pointerStrokeWidth={pointerSize * 4}
            className="sewageCounter"
          >
            <InsideText>
              {amount === null ? (
                <SetPreviousDrop>
                  <InfoText
                    variant="subtitle2"
                    color={theme.palette.primary.contrastText}
                    sx={{ textAlign: 'center', width: '90%', margin: '0 auto' }}
                  >
                    Aby aktywować miernik uzupełnij dane zbiornika oraz
                    informacje o 3 ostatnich wywozach.
                  </InfoText>
                </SetPreviousDrop>
              ) : (
                <>
                  <TopIntoGauge
                    color={currentColor}
                    sx={{ fontWeight: '700', fontSize: '48px' }}
                  >
                    {amount}%
                  </TopIntoGauge>
                  <TextIntoGauge
                    variant="h6"
                    color={theme.palette.primary.contrastText}
                    sx={{ fontWeight: '700', mt: 1 }}
                  >
                    Aktualny poziom
                  </TextIntoGauge>
                </>
              )}
            </InsideText>
          </ReactCustomizableProgressbar>
        </GaugeWrapper>
        <NearerOrderText>
          {amount && amount >= 80 ? (
            <LeftTypography
              color={
                bottomTextColor
                  ? bottomTextColor
                  : amount >= 90
                  ? theme.palette.error.main
                  : theme.palette.warning.main
              }
              variant="h6"
            >
              {bottomText}
            </LeftTypography>
          ) : null}
        </NearerOrderText>
      </CounterBox>
    </FlexContainer>
  );
};

export default SewageCounter;
