import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  Checkbox,
  Box,
  FormControl,
  FormLabel,
  Button,
  Typography,
} from '@mui/material/';

import { TLoginForm } from '@customTypes/forms/LoginForm';
import validator from '@forms/LoginForm/validator';
import {
  getCaptchaRequest,
  getCaptchaRequestFailure,
  loginAction,
  loginFromBoss,
  savePinAction,
} from '@redux/auth/actions';
import { GradientButton } from '@styles/styled';
import { JustRow } from '@styles/styled';
import CustomTextField from '@components/elements/CustomTextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import useReCaptchaVerify from '@hooks/useReCaptchaVerify';

const CryptoJS = require('crypto-js');

type PLoginForm = {
  loading?: boolean;
  error?: boolean;
};

const initialValues: TLoginForm = {
  email: '',
  password: '',
  rememberMe: false,
  recaptcha: '',
  securePin: '',
  isTokenSaved: false,
};

const LoginForm: React.FC<PLoginForm> = ({
  error = false,
  loading = false,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [securePinVisible, setSecurePinVisible] = useState(false);

  const dispatch = useDispatch();

  const getCaptcha = useReCaptchaVerify('LOGIN_REQUEST');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: (values: TLoginForm) => {
      if (values.isTokenSaved) {
        encryptData();
      } else {
        dispatch(getCaptchaRequest());

        getCaptcha()
          .then((token) => {
            if (token && token?.length < 200) {
              dispatch(getCaptchaRequestFailure());
              return;
            }
            const data = {
              recaptcha: token,
              email: values.email,
              password: values.password,
            };

            dispatch(loginAction(data));
          })
          .catch(() => {
            dispatch(getCaptchaRequestFailure());
          });

        if (values.securePin) dispatch(savePinAction(values.securePin));
      }
    },
  });

  useEffect(() => {
    if (window && window.localStorage) {
      const encryptedToken = localStorage.getItem('encryptedToken');

      if (encryptedToken) {
        formik.setFieldValue('rememberMe', true);
        formik.setFieldValue('isTokenSaved', true);
      }
    }
  }, []);

  const encryptData = () => {
    if (window && window.localStorage) {
      const encryptedToken = localStorage.getItem('encryptedToken');
      const pin = formik.values.securePin;

      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, pin).toString(
          CryptoJS.enc.Utf8
        );
        const decryptedToken = JSON.parse(bytes);

        if (decryptedToken) {
          sessionStorage.setItem('TOKEN', decryptedToken.key);
          dispatch(loginFromBoss());
        }
      } catch (error) {
        formik.setFieldError(
          'securePin',
          'Nie rozpoznano pinu szybkiego logowania.'
        );
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {!formik.values.isTokenSaved && (
        <>
          <CustomTextField
            focused
            id="email"
            label="E-MAIL"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              (formik.touched.email && Boolean(formik.errors.email)) || error
            }
            helperText={formik.touched.email && formik.errors.email}
          />
          <CustomTextField
            focused
            id="password"
            label="HASŁO"
            type={passwordVisible ? 'text' : 'password'}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={
              (formik.touched.password && Boolean(formik.errors.password)) ||
              error
            }
            helperText={formik.touched.password && formik.errors.password}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setPasswordVisible((prevState) => !prevState);
                    }}
                    edge="end"
                  >
                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <JustRow>
            <FormControl>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  id="rememberMe"
                  name="rememberMe"
                  color="primary"
                  value={formik.values.rememberMe}
                  onChange={formik.handleChange}
                  data-cy="rememberMe"
                />
                <FormLabel sx={{ fontSize: 14, color: 'black' }}>
                  Pamiętaj mnie
                </FormLabel>
              </Box>
            </FormControl>
            <Link
              to="/forgotten-password"
              data-cy="remindPassword"
              replace={true}
            >
              <Typography
                sx={{ alignSelf: 'center', fontWeight: 'bold', fontSize: 14 }}
              >
                Przypomnij hasło
              </Typography>
            </Link>
          </JustRow>
        </>
      )}
      {formik.values.rememberMe && (
        <CustomTextField
          id="securePin"
          label="Pin szybkiego logowania"
          type={securePinVisible ? 'text' : 'password'}
          value={formik.values.securePin}
          onChange={formik.handleChange}
          error={
            (formik.touched.securePin && Boolean(formik.errors.securePin)) ||
            error
          }
          helperText={formik.touched.securePin && formik.errors.securePin}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSecurePinVisible((prevState) => !prevState);
                  }}
                  edge="end"
                >
                  {securePinVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            inputMode: 'numeric',
          }}
        />
      )}
      {formik.values.isTokenSaved && (
        <Box>
          <Typography variant="subtitle1" sx={{ display: 'inline' }}>
            Chcesz się zalogować na inne konto?
          </Typography>
          <Button
            variant="text"
            sx={{ display: 'inline', m: 0, p: 0 }}
            onClick={() => {
              formik.setFieldValue('rememberMe', false);
              formik.setFieldValue('isTokenSaved', false);
              localStorage.removeItem('encryptedToken');
            }}
          >
            Kliknij tutaj
          </Button>
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GradientButton
          type="submit"
          variant="contained"
          data-cy="submit_login"
          loading={loading}
          disabled={loading}
          sx={{ color: '#fff', m: 3 }}
        >
          Zaloguj się
        </GradientButton>
      </Box>
    </form>
  );
};

export default LoginForm;
