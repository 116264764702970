import React, { createContext, ReactNode, useState } from 'react';
import { MySewageContextI } from './interface';

const defaultValues = {
  tankNameDifferent: false,
  billingFormDifferent: false,
  tankDataDifferent: false,
  tankLocationDifferent: false,
  controlDetailsModalVisible: false,
  controlAdditionalInfo: '',
  setTankNameDifferent: (val: boolean) => {},
  setControlDetailsModalVisible: (val: boolean) => {},
  setControlAdditionalInfo: (val: string) => {},
  handleBillingDataDifferent: (val: boolean) => {},
  handleTankDataDifferent: (val: boolean) => {},
  handleTankLocationDifferent: (val: boolean) => {},
};

const WarningModalContext = createContext<MySewageContextI>(defaultValues);

const WarningModalProvider = ({ children }: { children: ReactNode }) => {
  const [billingFormDifferent, setBillingFormDifferent] =
    useState<boolean>(false);
  const [tankNameDifferent, setTankNameDifferent] = useState<boolean>(false);
  const [tankDataDifferent, setTankDataDifferent] = useState<boolean>(false);
  const [tankLocationDifferent, setTankLocationDifferent] =
    useState<boolean>(false);
  const [controlDetailsModalVisible, setControlDetailsModalVisible] =
    useState<boolean>(false);
  const [controlAdditionalInfo, setControlAdditionalInfo] =
    useState<string>('');

  const handleBillingDataDifferent = (val: boolean) =>
    setBillingFormDifferent(val);
  const handleTankDataDifferent = (val: boolean) => setTankDataDifferent(val);
  const handleTankLocationDifferent = (val: boolean) =>
    setTankLocationDifferent(val);

  const store = {
    setTankNameDifferent,
    handleBillingDataDifferent,
    handleTankDataDifferent,
    handleTankLocationDifferent,
    setControlDetailsModalVisible,
    setControlAdditionalInfo,
    tankNameDifferent,
    tankDataDifferent,
    tankLocationDifferent,
    billingFormDifferent,
    controlDetailsModalVisible,
    controlAdditionalInfo,
  };

  return (
    <WarningModalContext.Provider value={store}>
      {children}
    </WarningModalContext.Provider>
  );
};

export { WarningModalProvider, WarningModalContext };
