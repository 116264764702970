'use client';

import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export default function useReCaptchaVerify(action?: string) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  return useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    return await executeRecaptcha(action);
  }, [executeRecaptcha]);
}
