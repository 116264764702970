import React from 'react';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import { CancelPickup } from '@components/elements/CancelPickup';
import { PayForOrderModal } from '@components/elements/PayForOrderModal';
import Script from 'next/script';
import process from 'process';

export const CancelPickupModal = ({
  cancelOrder,
}: {
  cancelOrder: () => void;
}) => (
  <AuthBackgroundLayout
    additionalStyle={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
  >
    <CancelPickup exit={cancelOrder} />
  </AuthBackgroundLayout>
);

const ENV = process.env.ENV_TYPE;
const payuLink =
  ENV === 'PROD'
    ? 'https://secure.payu.com/javascript/sdk'
    : 'https://secure.snd.payu.com/javascript/sdk';

export const PayForPickupModal = () => (
  <AuthBackgroundLayout
    additionalStyle={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
  >
    <Script type="text/javascript" src={payuLink} />
    <PayForOrderModal />
  </AuthBackgroundLayout>
);
