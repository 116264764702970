import { styled } from '@mui/material/styles';
import { BorderButton } from '@styles/styled';
import { SvgIcon, Box, Typography } from '@mui/material';

import exit from '../../../../public/icons/exit.svg';
import mailbox from '../../../../public/icons/mailbox.svg';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardInterface, RootState } from '@redux/interfaces';
import { getUnreadNotifications } from '@redux/dashboard/actions';
import React, { useEffect, useRef, useState } from 'react';
import { NotificationSkeleton } from '@components/elements/NotificationModal/Skeleton';
import { SingleNotification } from '@components/elements/NotificationModal/SingleNotification';
import RegisterPush from '@components/elements/NotificationModal/RegisterPush';
// @ts-ignore
import { isSafari } from '@firebase/util';

const Wrapper = styled('div')`
  background-color: #ffffff;
  position: absolute;
  right: 17px;
  top: 60px;
  min-width: 400px;
  z-index: 999;
  box-shadow: 0px 16px 24px rgba(10, 60, 70, 0.16);
  border-radius: 20px;

  @media (max-width: 660px) {
    position: relative;
    left: auto;
    right: auto;
    top: 0;
    width: 90vw;
    margin-top: -20vh;
  }
`;

const NotificationsContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;

  @media (max-width: 660px) {
    max-height: 60vh;
  }
`;

const ExitWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10px 10px 5px 0;
`;

type Icon = {
  component: any;
};

const Exit = styled(SvgIcon)<Icon>`
  &:hover {
    cursor: pointer;
  }
`;

export const NotificationModal = ({
  close,
  ringRef,
  arrayContains,
  secondRingRef,
}: {
  close: () => void;
  ringRef: any;
  secondRingRef: any;
  arrayContains: (item: string, is_unread: boolean) => void;
}) => {
  const [currentPage, setCurrentPage] = useState(2);

  const { notifications, notificationsInfo, loading } = useSelector<
    RootState,
    DashboardInterface
  >((state) => state.dashboard);

  const dispatch = useDispatch();
  const wrapperRef = useRef(null);

  const readed = notifications.filter((item) => !item.is_unread);
  const unReaded = notifications.filter((item) => item.is_unread);

  const sortedNotiList = [...unReaded, ...readed];

  const loadMore = () => {
    if (notificationsInfo.next !== null) {
      dispatch(getUnreadNotifications({ page: currentPage }));
      setCurrentPage((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        wrapperRef.current &&
        // @ts-ignore
        !wrapperRef.current?.contains(event.target) &&
        ringRef.current &&
        !ringRef.current?.contains(event.target) &&
        secondRingRef.current &&
        !secondRingRef.current?.contains(event.target)
      ) {
        close();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });

  return (
    <Wrapper ref={wrapperRef}>
      <ExitWrapper>
        <Exit
          component={exit}
          viewBox="0 0 64 36.77"
          onClick={() => {
            close();
          }}
        />
      </ExitWrapper>
      {'Notification' in window &&
        Notification.permission === 'default' &&
        isSafari() && <RegisterPush />}

      <>
        <NotificationsContainer>
          {sortedNotiList &&
            sortedNotiList.map((item) => (
              <SingleNotification
                key={item.id}
                notification={item}
                arrayContains={arrayContains}
              />
            ))}
          {loading && (
            <>
              <NotificationSkeleton />
            </>
          )}
          {!sortedNotiList.length && !loading && (
            <>
              <Typography
                sx={{ fontWeight: 700, pl: 2, pr: 2, textAlign: 'center' }}
              >
                Nic tu jeszcze nie ma...
              </Typography>
              <SvgIcon
                component={mailbox}
                viewBox="0 0 576.49928 493.5"
                sx={{ fontSize: '120px', mt: 1 }}
              />
            </>
          )}
        </NotificationsContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            minHeight: '25px',
          }}
        >
          {notificationsInfo.next && (
            <BorderButton sx={{ mt: 3, mb: 2 }} onClick={() => loadMore()}>
              Zobacz więcej
            </BorderButton>
          )}
        </Box>
      </>
    </Wrapper>
  );
};
