import { FormHelperText, InputLabel, Select } from '@mui/material';
import theme from '@styles/theme';
import React, { ReactElement } from 'react';

type Props = {
  label: ReactElement | string;
  value: string | boolean | null;
  error?: any;
  onChange: any;
  disabled: boolean;
  children: any;
  id: string;
};

const CustomSelect = ({
  id,
  disabled,
  label,
  children,
  onChange,
  value,
  error,
}: Props) => {
  return (
    <>
      <InputLabel
        id={id + 'Id'}
        sx={{
          color: 'gray',
          fontWeight: '700',
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={id + 'Id'}
        id={id}
        name={id}
        disabled={disabled}
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        inputProps={{ 'data-cy': `${id}` }}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>
          {error}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomSelect;
