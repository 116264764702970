import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import plLocale from 'date-fns/locale/pl';
import { DatePicker, LocalizationProvider, plPL } from '@mui/x-date-pickers';
import { DateLabel, RedStar } from '@components/modules/DropZoneModals/styled';
import { TextField } from '@mui/material';
import React from 'react';

type PropsT = {
  formik: any;
  label: string;
  fieldName: string;
  required?: boolean;
  disabled?: boolean;
};

const DateSelector = ({
  formik,
  label,
  fieldName,
  required = true,
  disabled = false,
}: PropsT) => (
  <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={plLocale}
    localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
  >
    <DatePicker
      openTo="day"
      views={['year', 'month', 'day']}
      disabled={disabled}
      label={
        <DateLabel>
          {required && <RedStar />}
          {label}
        </DateLabel>
      }
      value={formik.values[fieldName]}
      onChange={(newValue) => {
        formik.setFieldValue(fieldName, newValue);
      }}
      inputFormat="dd.MM.yyyy"
      renderInput={(params) => (
        <TextField
          data-cy={fieldName}
          fullWidth
          {...params}
          disabled={disabled}
          error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
          helperText={formik.touched[fieldName] && formik.errors[fieldName]}
          // @ts-ignore
          FormHelperTextProps={{ 'data-cy': `${fieldName}Error` }}
          InputLabelProps={{
            style: { color: 'gray', fontWeight: '700' },
          }}
          sx={{ flex: 1 }}
          margin="normal"
        />
      )}
    />
  </LocalizationProvider>
);

export default DateSelector;
