import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { getMessaging, onMessage } from 'firebase/messaging';
import { firebaseCloudMessaging } from '@utils/webPush';

import SideBar from '@components/modules/SideBar';
import Footer from '@components/elements/Footer';
import theme from '@styles/theme';
import { styled } from '@mui/material/styles';
import Logo from '@components/elements/Logo';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardInfo, setFirebaseToken } from '@redux/dashboard/actions';
import {
  AuthApiResult,
  DashboardInterface,
  RootState,
} from '@redux/interfaces';
import { store } from '@redux/store';
import { firebaseApp } from '@utils/webPush';
// @ts-ignore
import { isSafari } from '@firebase/util';
import {
  getClientBillingData,
  getClientContactData,
} from '@redux/userSettings/myAccount/actions';
import IsBackendOffline from '@components/elements/IsBackendOffline';
import { useLocation } from 'react-router-dom';

const FixedLogo = styled(Logo)`
  transform: scale(0.8);

  @media (max-width: 905px) {
    transform: scale(0.6);
  }

  @media (max-width: 660px) {
    display: none;
  }
`;

const LogoWrapper = styled(Box)`
  @media (max-width: 660px) {
    display: none;
  }
`;

const DisabledOnMobile = styled('div')`
  @media (max-width: 660px) {
    display: none;
  }
`;

type Props = {
  children: React.ReactNode;
};

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const [reloadDashboard, setReloadDashboard] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { fcmToken } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );

  const { isOffline } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const notifyMe = () => {
    if (!('Notification' in window)) {
      toast.error('Twoja przeglądarka nie obsługuje powiadomień');
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(function (permission) {
        if (fcmToken.length === 0 && permission === 'granted') getFcmToken();
      });
    }
  };

  async function getFcmToken() {
    try {
      const token = await firebaseCloudMessaging.init();
      if (token) {
        dispatch(setFirebaseToken({ registration_token: token }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getMessage = () => {
    const messaging = getMessaging(firebaseApp);

    onMessage(messaging, (message) => {
      const { userId } = store.getState().auth;

      if (message?.data && String(userId) === message?.data.user) {
        dispatch(getDashboardInfo());
        toast.info('Nowa wiadomość');
      }
    });
  };

  useEffect(() => {
    if ('PushManager' in window) {
      if (!isSafari()) {
        notifyMe();
      }
      getMessage();
    }
    dispatch(getClientBillingData());
    dispatch(getClientContactData());
  }, []);

  useEffect(() => {
    if (reloadDashboard && pathname === '/dashboard') {
      dispatch(getDashboardInfo());
      setReloadDashboard(false);
    }
  }, [reloadDashboard]);

  document.addEventListener('visibilitychange', function () {
    if (document.visibilityState === 'visible') {
      setReloadDashboard(true);
    }
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        backgroundColor: `${theme.palette.background.default}`,
      }}
    >
      {isOffline && <IsBackendOffline />}
      <Grid
        container
        sx={{ maxWidth: '1640px', margin: '1rem auto 0 auto', width: '100%' }}
      >
        <Grid item flex={1}>
          <Grid container item sx={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <LogoWrapper sx={{ marginLeft: '20px' }}>
                <FixedLogo />
              </LogoWrapper>
              <SideBar />
            </Grid>
            <Grid item sx={{ width: '100%' }}>
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DisabledOnMobile>
        <Footer />
      </DisabledOnMobile>
    </Box>
  );
};

export default DashboardLayout;
