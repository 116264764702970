import React, { useEffect } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { Paper, Box, Typography } from '@mui/material/';

import { RootState, AuthApiResult } from '@redux/interfaces';
import ResetPasswordForm from '@forms/ResetPassword/ResetPasswordFormFirstStep';
import BackToLogin from '@components/elements/BackToLogin';

import logo from '../../../../public/images/szambo.online.png';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPageTemplate: React.FC = () => {
  const { logged } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (logged) {
      navigate('/dashboard');
    }
  }, [logged]);

  return (
    <Paper
      sx={{
        marginTop: 8,
        p: 5,
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BackToLogin />
      <Image src={logo} alt={'logo'} onClick={() => navigate('/dashboard')} />
      <Box sx={{ mt: 5, pr: 5, pl: 5 }}>
        <Typography
          component="h2"
          variant="h4"
          sx={{ textAlign: 'center', p: 3 }}
        >
          Resetowanie hasła
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          sx={{ textAlign: 'center' }}
        >
          Nie pamiętasz swojego hasła?
        </Typography>
        <Typography
          component="h2"
          variant="subtitle1"
          sx={{ mb: 3, textAlign: 'center' }}
        >
          Wpisz poniżej e-mail, którego użyłeś/aś podczas rejestracji, a my
          wyślemy Ci link do zmiany hasła.
        </Typography>

        <ResetPasswordForm />
      </Box>
    </Paper>
  );
};

export default ResetPasswordPageTemplate;
