import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Typography, SvgIcon } from '@mui/material';
import { CustomPaper, GradientButton } from '@styles/styled';
import { styled } from '@mui/material/styles';

import scrollArrow from '../../../../public/icons/scrollArrow.svg';

import DeleteRecord from '@components/elements/DropsGrid/DeleteRecord';
import AddNewDropRecord from '@components/modules/DropZoneModals/AddNewDropRecord/AddNewDropRecord';
import OrderDetails from '@components/elements/OrderDetails';
import SingleRecord from './SingleRecord';
import Title from '@components/elements/DropsGrid/Title';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useDispatch, useSelector } from 'react-redux';
import { getClientProperty } from '@redux/userCesspools/actions';
import {
  HistoryDropItemInterface,
  MyExportsInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import {
  getComplaintDetails,
  getHistoryDrop,
  resetCompanies,
} from '@redux/myExports/actions';
import DropsSkeleton from '@components/elements/DropsGrid/DropsSkeleton';
import ReportComplaintModal from '@components/elements/ReportComplaintModal';
import ComplaintDetailModal from '@components/elements/ReportComplaintModal/ComplaintDetailModal';
import { types } from '@redux/myExports/types';

const TopBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const FooterWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  padding: 30px 0;
`;

const NextPageButton = styled(Button)`
  background-color: transparent;
  color: black;
  text-decoration: underline;
  text-transform: unset;
`;

const RecordsWrapper = styled(Box)`
  width: auto;

  @media (max-width: 1155px) {
    width: 70vw;
    overflow: auto;
  }
  @media (max-width: 660px) {
    width: 90vw;
  }
`;

const Records = styled(Box)`
  @media (max-width: 1155px) {
    width: 150%;
  }

  @media (max-width: 910px) {
    width: 200%;
  }

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const PrevPageBox = styled(Box)`
  flex: 1;
  text-align: left;
`;

const ScrollMe = styled(Box)`
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (min-width: 1155px) {
    display: none;
  }

  @media (max-width: 660px) {
    display: none;
  }
`;

const CustomContainer = styled(Container)`
  margin: 0 0 16px 0;

  @media (max-width: 660px) {
    margin-bottom: 85px;
  }
`;

const DropsGrid = () => {
  const [addRecordModal, setAddRecordModal] = useState<boolean>(false);
  const [clickedOrder, setClickedOrder] =
    useState<HistoryDropItemInterface | null>(null);
  const [clickedOrderDetails, setClickedOrderDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [reportComplaintModalVisible, setReportComplaintModalVisible] =
    useState(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [complaintDetailsModalVisible, setComplaintDetailsModalVisible] =
    useState<boolean>(false);
  const [modalToDelete, setModalToDelete] = useState<HistoryDropItemInterface>({
    id: 0,
    status: 'CANCELED',
    date: '',
    added_by_user: false,
    sanitation_company_name: '',
    sanitation_company_id: '',
    amount: 0,
    price: 0,
    has_invoice: false,
    client_property_id: 0,
    client_property_name: '',
    has_reported_complaint: false,
  });

  const { clientProperty } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { historyDrop, loading, needRefresh } = useSelector<
    RootState,
    MyExportsInterface
  >((state) => state.myExports);

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const handleVisibleDetails = (item: HistoryDropItemInterface) => {
    if (item) {
      setClickedOrder(item);
      setClickedOrderDetail(true);
    }
  };

  const handleVisible = (item: HistoryDropItemInterface) => {
    setIsModalVisible(true);
    if (item) {
      setModalToDelete(item);
      setClickedOrder(null);
    }
  };

  useEffect(() => {
    dispatch(getClientProperty());

    if (currentPage > 1) {
      dispatch(getHistoryDrop(`?limit=10&offset=${(currentPage - 1) * 10}`));
    } else {
      dispatch(getHistoryDrop('?limit=10'));
    }
  }, []);

  useEffect(() => {
    dispatch(getHistoryDrop(`?limit=10&offset=${(currentPage - 1) * 10}`));
  }, [needRefresh]);

  useEffect(() => {
    setTotalPages(Math.ceil(historyDrop.count / 10));
  }, [historyDrop]);

  const handleChangeNextPage = () => {
    if (historyDrop.next) {
      setCurrentPage((prevState) => prevState + 1);
      dispatch(getHistoryDrop(`?limit=10&offset=${currentPage * 10}`));
    }
  };
  const handleChangePrevPage = () => {
    if (historyDrop.previous) {
      dispatch(getHistoryDrop(`?limit=10&offset=${(currentPage - 2) * 10}`));
      setCurrentPage((prevState) => prevState - 1);
    }
  };

  const handleClickOnRecord = (item: HistoryDropItemInterface) => {
    if (width <= 660) {
      handleVisibleDetails(item);
    } else {
      if (item.added_by_user) {
        setClickedOrder(item);
        setAddRecordModal(true);
      }
    }
  };

  const handleReportComplaint = (item: HistoryDropItemInterface) => {
    if (item.has_reported_complaint) {
      dispatch(getComplaintDetails(`${item.id}/`));
      setComplaintDetailsModalVisible(true);
    } else {
      setClickedOrder(item);
      setReportComplaintModalVisible(true);
    }
  };

  return (
    <CustomContainer disableGutters maxWidth="lg">
      {addRecordModal && (
        <AddNewDropRecord
          itemToEdit={clickedOrder}
          closeModal={() => {
            setAddRecordModal(false);
            setClickedOrder(null);
          }}
        />
      )}
      {reportComplaintModalVisible && (
        <ReportComplaintModal
          closeModal={() => {
            setReportComplaintModalVisible(false);
            setClickedOrder(null);
          }}
          itemToEdit={clickedOrder}
        />
      )}
      {complaintDetailsModalVisible && (
        <ComplaintDetailModal
          close={() => {
            setComplaintDetailsModalVisible(false);
            dispatch({ type: types.RESET_COMPLAINT });
          }}
        />
      )}
      {clickedOrderDetails && clickedOrder && (
        <OrderDetails
          item={clickedOrder}
          close={() => {
            setClickedOrder(null);
            setClickedOrderDetail(false);
          }}
          deleteRecord={(item: any) => {
            handleVisible(item);
          }}
          editRecord={() => {
            setClickedOrderDetail(false);
            setAddRecordModal(true);
          }}
          reportComplaint={(item: HistoryDropItemInterface) => {
            setClickedOrderDetail(false);
            handleReportComplaint(item);
          }}
        />
      )}
      <CustomPaper>
        {isModalVisible && (
          <DeleteRecord
            item={modalToDelete}
            closeModal={() => {
              setIsModalVisible(false);
            }}
            loading={loading}
            label="Usuń wywóz"
          />
        )}
        <TopBar>
          <GradientButton
            sx={{ m: 0 }}
            onClick={() => {
              setAddRecordModal(true);
              dispatch(resetCompanies());
            }}
            disabled={clientProperty.length === 0}
          >
            + Dodaj wywóz
          </GradientButton>
        </TopBar>
        <ScrollMe>
          <Typography sx={{ fontSize: '12px', color: 'gray' }}>
            Przesuń w bok
          </Typography>
          <SvgIcon
            component={scrollArrow}
            viewBox="0 0 75.414 20.452"
            sx={{
              fontSize: '4rem',
              margin: '-25px 0',
            }}
          />
        </ScrollMe>
        <RecordsWrapper>
          <Records>
            <Title />
            <Box>
              {historyDrop.results &&
                historyDrop.results.map((item) => (
                  <SingleRecord
                    key={item.id}
                    item={item}
                    onClick={(item: HistoryDropItemInterface) => {
                      handleClickOnRecord(item);
                    }}
                    deleteRecord={(item: HistoryDropItemInterface) => {
                      handleVisible(item);
                    }}
                    reportOrOpenComplaint={(item: HistoryDropItemInterface) => {
                      handleReportComplaint(item);
                    }}
                  />
                ))}
              {loading && historyDrop.results.length === 0 && <DropsSkeleton />}
              {!loading && historyDrop.results.length === 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, textAlign: 'center', mt: 2 }}
                >
                  Brak historycznych wywozów do wyświetlenia
                </Typography>
              )}
            </Box>
          </Records>
        </RecordsWrapper>
        <FooterWrapper>
          <PrevPageBox>
            <NextPageButton
              onClick={() => handleChangePrevPage()}
              disabled={!historyDrop.previous}
            >
              Poprzednia strona
            </NextPageButton>
          </PrevPageBox>
          <Box sx={{ flex: 1, textAlign: 'center' }}>
            <Typography>
              Strona {currentPage} z {totalPages ? totalPages : 1}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: 'right' }}>
            <NextPageButton
              onClick={() => handleChangeNextPage()}
              disabled={!historyDrop.next}
            >
              Następna strona
            </NextPageButton>
          </Box>
        </FooterWrapper>
      </CustomPaper>
    </CustomContainer>
  );
};

export default DropsGrid;
