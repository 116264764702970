import { Box, Typography } from '@mui/material';
import React from 'react';
import BillingDataForm from '@forms/BillingDataForm';

type CompleteBillingDataT = {
  nextStep: () => void;
  prevStep: () => void;
};
const CompleteBillingData = ({ nextStep, prevStep }: CompleteBillingDataT) => {
  return (
    <Box sx={{ p: 5, width: '100%' }}>
      <Box>
        <Typography sx={{ fontWeight: 'bold' }}>
          Dane billingowe są niezbędne do wystawienia faktury i wygenerowania
          umowy na wywóz
        </Typography>
        <BillingDataForm isInOrder prevStep={prevStep} nextStep={nextStep} />
      </Box>
    </Box>
  );
};

export default CompleteBillingData;
