import React from 'react';
import { useSelector } from 'react-redux';
import { AuthApiResult, RootState } from '@redux/interfaces';
import { styled } from '@mui/material/styles';
import { CustomPaper } from '@styles/styled';
import { Typography } from '@mui/material';

const FileLink = styled(Typography)`
  color: #a8c300;
  font-weight: bold;
  font-size: 1.125rem;
  padding: 0.5rem;
  text-decoration: none;

  @media (max-width: 660px) {
    font-size: 1rem;
  }
`;

const Paper = styled(CustomPaper)`
  padding: 10px;
`;

const PolicyLinks = () => {
  const { documents } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const getDocumentUri = (type: string) => {
    const item = documents.find((item) => item.type_code === type);

    return item?.uri ? item.uri : '/';
  };

  return (
    <Paper>
      <a
        target="_blank"
        rel="noreferrer"
        href={getDocumentUri('DOC_PROCESSING_PERSONAL_DATA')}
      >
        <FileLink>Polityka prywatności</FileLink>
      </a>

      <a
        target="_blank"
        rel="noreferrer"
        href={getDocumentUri('DOC_APP_TERMS_AND_CONDITIONS')}
      >
        <FileLink>Regulamin strony</FileLink>
      </a>
    </Paper>
  );
};

export default PolicyLinks;
