import { styled } from '@mui/material/styles';
import { useState } from 'react';

const Wrapper = styled('div')`
  min-height: 50px;
  width: 100%;
  padding: 0 15px;
  border-top: 1px solid #ddd;
`;

const Info = styled('p')`
  font-size: 12px;
`;

const GreenLink = styled('a')`
  font-weight: bold;
  color: #009e25;
`;

const ShowMore = styled('p')`
  font-weight: bold;
  display: inline;
  cursor: pointer;
`;

const Inline = styled('p')`
  display: inline;
`;

const PayuInfo = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Wrapper>
      <Info>
        Zlecenie realizacji płatności: Zlecenie wykonuje PayU SA;
        {showMore && (
          <Inline>
            {' '}
            Dane odbiorcy, tytuł oraz kwota płatności dostarczane są PayU SA
            przez odbiorcę; Zlecenie jest przekazywane do realizacji po
            otrzymaniu przez PayU SA Państwa wpłaty. Płatność udostępniana jest
            odbiorcy w ciągu 1 godziny, nie później niż do końca następnego dnia
            roboczego; PayU SA nie pobiera opłaty od realizacji usługi. Płacąc
            akceptujesz{' '}
            <GreenLink
              href="https://static.payu.com/sites/terms/files/payu_terms_of_service_single_transaction_pl_pl.pdf"
              target="_blank"
            >
              Zasady płatności PayU.
            </GreenLink>
            Administratorem Twoich danych osobowych jest PayU S.A. z siedzibą w
            Poznaniu (60-166), przy ul. Grunwaldzkiej 186. Twoje dane osobowe
            będą przetwarzane w celu realizacji transakcji płatniczej,
            powiadamiania Cię o statusie realizacji Twojej płatności,
            rozpatrywania reklamacji, a także w celu wypełnienia obowiązków
            prawnych ciążących na PayU. Odbiorcami Twoich danych osobowych mogą
            być podmioty współpracujące z PayU w procesie realizacji płatności.
            W zależności od wybranej przez Ciebie metody płatności mogą to być:
            banki, instytucje płatnicze, instytucje pożyczkowe, organizacje kart
            płatniczych, schematy płatnicze), ponadto podmioty wspierające
            działalność PayU tj. dostawcy infrastruktury IT, dostawcy narzędzi
            do analizy ryzyka płatności a także podmiotom uprawnionym do ich
            otrzymania na mocy obowiązujących przepisów prawa, w tym właściwym
            organom wymiaru sprawiedliwości. Twoje dane mogą zostać udostępnione
            akceptantom celem poinformowania ich o statusie realizacji
            płatności. Przysługuje Tobie prawo dostępu do danych, a także do ich
            sprostowania, ograniczenia ich przetwarzania, zgłoszenia sprzeciwu
            wobec ich przetwarzania, niepodlegania zautomatyzowanemu
            podejmowaniu decyzji w tym profilowania oraz do przenoszenia i
            usunięcia danych. Podanie danych jest dobrowolne jednak niezbędne do
            realizacji płatności, a brak podania danych może skutkować
            odrzuceniem płatności. Więcej informacji o zasadach przetwarzania
            Twoich danych osobowych przez PayU znajdziesz{' '}
            <GreenLink
              href="https://static.payu.com/sites/terms/files/payu_privacy_policy_pl_pl.pdf"
              target="_blank"
            >
              w Polityce Prywatności.
            </GreenLink>
          </Inline>
        )}
        <ShowMore onClick={() => setShowMore((prevState) => !prevState)}>
          {showMore ? ' Pokaż mniej' : ' Pokaż więcej'}
        </ShowMore>
      </Info>
    </Wrapper>
  );
};

export default PayuInfo;
