import React from 'react';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import { DeleteCard } from '@components/elements/DeleteCard';
import { CreditCardInterface } from '@redux/interfaces';

type Props = {
  exit: () => void;
  cardToDelete: CreditCardInterface | null;
};

const AddCardPopUp = ({ exit, cardToDelete }: Props) => {
  return (
    <AuthBackgroundLayout
      additionalStyle={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <DeleteCard exit={exit} cardToDelete={cardToDelete} />
    </AuthBackgroundLayout>
  );
};

export default AddCardPopUp;
