export const types = {
  LEVEL_START: 'level_start',
  LEVEL_END: 'level_end',
  SET_CHECKOUT_OPTIONS: 'set_checkout_option',
  SELECT_ITEM: 'select_item',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  REMOVE_FROM_CART: 'remove_from_cart',
  SELECT_CONTENT: 'select_content',
  PAGE_VIEW: 'page_view',

  STEP_0: 'open_order_modal',
  STEP_1: 'select_driver',

  LEVEL_NAME_APP: [
    'select_app_driver',
    'select_order_date_app',
    'check_order_details',
    'confirm_order',
  ],
  LEVEL_NAME_NUM: [
    'select_num_driver',
    'call_to_operator',
    'select_order_date_num',
    'set_order_price',
    'confirm_num_order_data',
  ],
};
