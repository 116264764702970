import React, { useEffect } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GradientButton } from '@styles/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  ClientData,
  CreditCardInterface,
  PaymentInterface,
  RootState,
} from '@redux/interfaces';
import { getCreditCard } from '@redux/payment/actions';
import {
  getClientContactData,
  updateClient,
} from '@redux/userSettings/myAccount/actions';
import CustomTextField from '@components/elements/CustomTextField';
import { useFormik } from 'formik';
import validator from '@components/elements/YourCreditCard/validator';
import { SingleCard } from '@components/elements/YourCreditCard/SingleCard';
import { CardSkeleton } from '@components/elements/YourCreditCard/CardSkeleton';
import PayuInfo from '@components/elements/PayuInfo';
import { resetPayu } from '@utils/payuHelpers';

const CustomGradientButton = styled(GradientButton)`
  margin: 20px 0 15px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  @media (max-width: 905px) {
    justify-content: center;
  }
`;

type Props = {
  openAddModal: () => void;
  handleDeleteVisible: (card: CreditCardInterface) => void;
};

const YourCreditCard = ({ handleDeleteVisible, openAddModal }: Props) => {
  const dispatch = useDispatch();

  const { creditCard, loading } = useSelector<RootState, PaymentInterface>(
    (state) => state.payment
  );

  const { client, clientLoaded } = useSelector<RootState, ClientData>(
    (state) => state.contact
  );

  const formik = useFormik({
    initialValues: { first_name: '', last_name: '', isCorrectData: false },
    validationSchema: validator,
    onSubmit: (values) => {
      if (client.first_name === '') {
        dispatch(
          updateClient({
            first_name: values.first_name,
            last_name: values.last_name,
          })
        );
      }
      if (client.first_name) {
        openAddModal();
        resetPayu();
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('first_name', client.first_name);
    formik.setFieldValue('last_name', client.last_name);

    if (client.first_name) formik.setFieldValue('isCorrectData', true);
    else formik.setFieldValue('isCorrectData', false);
  }, [client]);

  useEffect(() => {
    dispatch(getCreditCard());
    dispatch(getClientContactData());
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper
        sx={{
          mt: 4,
          p: 3,
          borderRadius: '25px',
          boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontWeight: 'bold',
            fontSize: '20px',
            mb: 1,
          }}
        >
          Dane Twojej karty płatniczej
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '14px',
            mb: 1,
          }}
        >
          {creditCard.length >= 1
            ? 'Możesz teraz płacić kartą za wywóz.'
            : 'Uzupełnij, jeżeli chcesz korzystać z wygodnych płatności online.'}
        </Typography>

        {!client.first_name && clientLoaded && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '15px',
              gap: '10px',
            }}
          >
            <CustomTextField
              id="first_name"
              label="IMIE"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              sx={{ m: 0 }}
            />
            <CustomTextField
              id="last_name"
              label="NAZWISKO"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              sx={{ m: 0 }}
            />
          </Box>
        )}

        {creditCard && creditCard.length >= 1 && (
          <>
            <div>
              <SingleCard />
              {creditCard.map((card) => (
                <SingleCard
                  card={card}
                  key={`${card.number_masked}/${card.expiration_month}/${card.expiration_year}`}
                  deleteCard={(card: CreditCardInterface) =>
                    handleDeleteVisible(card)
                  }
                />
              ))}
            </div>
          </>
        )}
        {client.first_name && creditCard.length === 0 && (
          <p>Nie masz jeszcze dodanej karty płatniczej</p>
        )}
        {creditCard.length === 0 && loading && <CardSkeleton />}
        <PayuInfo />
        <ButtonWrapper>
          <CustomGradientButton type="submit" disabled={creditCard.length >= 3}>
            {client.first_name
              ? '+ Dodaj kartę płatniczą'
              : 'Zapisz swoje dane'}
          </CustomGradientButton>
        </ButtonWrapper>
      </Paper>
    </form>
  );
};

export default YourCreditCard;
