import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormikProps } from 'formik';

type PropsT = {
  formik: FormikProps<any>;
};

const IsCompanyRadioSelect = ({ formik }: PropsT) => {
  return (
    <FormControl fullWidth margin="normal" id="clientType">
      <RadioGroup row sx={{ mb: 1 }}>
        <FormControlLabel
          value="T_PER"
          control={<Radio />}
          checked={formik.values.clientType === 'T_PER'}
          onChange={() => {
            formik.setFieldValue('clientType', 'T_PER');
          }}
          label="Osoba fizyczna"
        />
        <FormControlLabel
          value="T_COM"
          control={<Radio />}
          checked={formik.values.clientType === 'T_COM'}
          onChange={() => {
            formik.setFieldValue('clientType', 'T_COM');
          }}
          label="Firma"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default IsCompanyRadioSelect;
