import { Box, SvgIcon, Typography } from '@mui/material';
import download from '../../../../public/icons/download.svg';
import theme from '@styles/theme';
import React from 'react';
import { styled } from '@mui/material/styles';
import { BoxProps } from '@mui/system/Box';

const Wrapper = styled(Box)`
  display: inline-flex;
  flex-direction: row;
  cursor: pointer;
`;

type DownloadButtonPropsT = BoxProps & {
  downloadFn?: () => void;
};

const DownloadButton = ({
  downloadFn,
  ...otherProps
}: DownloadButtonPropsT) => (
  <Wrapper {...otherProps} onClick={downloadFn}>
    <SvgIcon component={download} viewBox="0 0 18 18" sx={{ mr: 0.5 }} />
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: 'bold',
        '-webkit-user-select': 'none',
        userSelect: 'none',
      }}
      color={theme.palette.primary.dark}
    >
      Pobierz
    </Typography>
  </Wrapper>
);

export default DownloadButton;
