import React from 'react';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  children: React.ReactNode;
  additionalStyle?: object;
  dataCy?: string;
};

const Wrapper = styled(Container)`
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const SemiTransparentBackground = ({
  children,
  additionalStyle,
  dataCy,
}: Props) => (
  <Wrapper maxWidth={false} sx={{ ...additionalStyle }} data-cy={dataCy}>
    {children}
  </Wrapper>
);

export default SemiTransparentBackground;
