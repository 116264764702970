'use client';

import * as yup from 'yup';

export default yup.object({
  tankType: yup.string().nullable(true).required('Wybierz rodzaj zbiornika'),
  tankModel: yup.string().nullable(true).required('Wybierz typ zbiornika'),
  capacity: yup
    .number()
    .typeError('Pojemność musi się składać z samych cyfr')
    .required('Pojemność jest wymagana')
    .min(1, 'Wprowadź prawidłową wartość')
    .max(50, 'Wprowadź prawidłową wartość'),
  dailyCapacity: yup.number().when('tankType', {
    is: 'OSSF',
    then: yup
      .number()
      .typeError('Przepustowość musi się składać z samych cyfr')
      .transform((_value, originalValue) =>
        Number(originalValue.replace(/,/, '.'))
      )
      .min(0.1, 'Wprowadź prawidłową wartość')
      .max(10, 'Wprowadź prawidłową wartość'),
  }),

  emptyingFrequency: yup
    .string()
    .matches(/^[1-9][0-9]*$/, 'Częstotliwość musi być większa od 0')
    .when('tankType', {
      is: 'OSSF',
      then: yup.string().required('Częstotliwość jest wymagana'),
    }),
  launchDate: yup
    .date()
    .nullable()
    .when('tankType', {
      is: 'OSSF',
      then: yup
        .date()
        .nullable()
        .typeError('Wprowadź poprawną datę')
        .required('Data uruchomienia jest wymagana'),
    }),
  residentsAmount: yup.string().required('Ilość mieszkańców jest wymagana'),
  sewageType: yup.string().required('Rodzaj ścieków jest wymagany'),
  sewageSystemConnectionDate: yup
    .date()
    .nullable()
    .when('sewageSystem', {
      is: true,
      then: yup
        .date()
        .nullable()
        .typeError('Wprowadź poprawną datę')
        .required('Data podłączenia jest wymagana'),
    }),
});
