'use client';

import { NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import DashboardLayout from '@components/layouts/DashboardLayout';
import { styled } from '@mui/material/styles';
import TopBar from '@components/elements/TopBar/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardInfo } from '@redux/dashboard/actions';
import {
  DashboardInterface,
  PaymentInterface,
  RootState,
} from '@redux/interfaces';
import useWindowDimensions from '@hooks/useWindowDimension';
import { TankSelector } from '@components/elements/TankSelector';
import { getUserId } from '@redux/auth/actions';
import { getClientProperty } from '@redux/userCesspools/actions';
import { resetIsPaid } from '@redux/payment/actions';
import DashboardComponents from '@components/modules/DashboardComponents';
import { DashboardProvider } from '../../context/DashboardContext';
import DashboardModals from '@components/modules/DashboardModals';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 20px 90px 20px;
  gap: 16px;

  @media (max-width: 1200px) {
    height: auto;
  }

  @media (max-width: 660px) {
    padding: 0;
    margin: 0 auto;
    width: 95%;
  }
`;

const SelectorElement = styled('div')`
  width: 100%;

  @media (min-width: 1200px) {
    display: none;
  }

  @media (max-width: 300px) {
    max-width: 280px;
  }
`;

const Dashboard: NextPage = () => {
  const [componentOrder, setComponentOrder] = useState<Array<number>>([
    0, 1, 2,
  ]);

  const { isPaid } = useSelector<RootState, PaymentInterface>(
    (state) => state.payment
  );
  const { data, currentTank } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );
  const { active_orders, invoice_unpaid, client_properties } = data;

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboardInfo());
    dispatch(getUserId());
    dispatch(getClientProperty());

    if (isPaid) {
      dispatch(resetIsPaid());
    }
  }, []);

  const findPropertyGaugeValue = (start: number, end: number) => {
    if (currentTank) {
      return (
        currentTank.estimated_sewage !== null &&
        currentTank.estimated_sewage >= start &&
        currentTank.estimated_sewage <= end
      );
    }
  };

  const haveActiveOrUnpaidOrder = () => {
    const active = active_orders.find(
      (order) => order.client_property_id === currentTank?.id
    );
    const unpaid = invoice_unpaid.find(
      (inv) => inv.client_property_id === currentTank?.id
    );

    return !(active === undefined && unpaid === undefined);
  };

  const newOrderOfComponents = () => {
    const haveUnpaid = haveActiveOrUnpaidOrder();

    if (width <= 1200) {
      if (client_properties.length === 0) {
        setComponentOrder([0, 2, 1]);
      } else if (findPropertyGaugeValue(80, 100) && !haveUnpaid) {
        setComponentOrder([2, 0, 1]);
      } else if (haveUnpaid) {
        setComponentOrder([1, 2, -1]);
      } else if (findPropertyGaugeValue(0, 79) && !haveUnpaid) {
        setComponentOrder([0, 2, 1]);
      } else {
        setComponentOrder([0, 1, 2]);
      }
    } else {
      setComponentOrder([0, 2, 1]);
    }
  };

  useEffect(() => {
    newOrderOfComponents();
  }, [data, width, currentTank]);

  return (
    <DashboardProvider>
      <DashboardLayout>
        <TopBar />
        <Wrapper>
          {client_properties.length >= 2 && (
            <SelectorElement>
              <TankSelector />
            </SelectorElement>
          )}
          <DashboardComponents componentsOrder={componentOrder} />
        </Wrapper>
        <DashboardModals />
      </DashboardLayout>
    </DashboardProvider>
  );
};

export default Dashboard;
