import React, { useEffect, useState } from 'react';
import { BorderButton, ErrorGradientButton } from '@styles/styled';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { ExitIcon } from '@components/elements/ExitIcon';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteOrder,
  resetOrderDeleteAfterFail,
  resetOrderIdToDelete,
} from '@redux/order/actions';
import { OrderInterface, RootState } from '@redux/interfaces';
import { getDashboardInfo } from '@redux/dashboard/actions';

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: left;
  }
`;

type Props = {
  exit: () => void;
};

export const CancelPickup = ({ exit }: Props) => {
  const [isNotDeleted, setIsNotDeleted] = useState(true);

  const { loading, cancelFailure } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  const dispatch = useDispatch();

  const handleCancel = () => {
    exit();
    dispatch(resetOrderIdToDelete());
    dispatch(resetOrderDeleteAfterFail());
    if (!isNotDeleted) dispatch(getDashboardInfo());
  };

  useEffect(() => {
    if (cancelFailure !== null) setIsNotDeleted(false);
  }, [cancelFailure]);

  const returnMessage = () => {
    if (isNotDeleted)
      return 'Czy na pewno chcesz zrezygnować z zamówionego wywozu?';
    else if (!isNotDeleted && cancelFailure)
      return 'Nie udało się odwołać Twojego wywozu';
    else if (!isNotDeleted && !cancelFailure)
      return 'Twój wywóz został odwołany.';
  };

  return (
    <Box>
      <Paper
        sx={{
          p: 3,
          borderRadius: '25px',
          boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
        }}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={0} md={2}></Grid>
          <Grid
            item
            xs={isNotDeleted ? 8 : 12}
            sx={{ textAlign: 'center' }}
            color="black"
          >
            <Title variant="h3">Odwołaj wywóz</Title>
          </Grid>
          <Exit
            item
            xs={4}
            md={2}
            sx={{ textAlign: 'right' }}
            onClick={() => {
              handleCancel();
            }}
          >
            {isNotDeleted && <ExitIcon />}
          </Exit>
        </Grid>
        <Typography sx={{ textAlign: 'center', mt: 1, fontWeight: 'bold' }}>
          {returnMessage()}
        </Typography>

        <Row>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <BorderButton
              sx={{ margin: '10px 0' }}
              loading={loading}
              onClick={() => {
                handleCancel();
              }}
              data-cy="exit_cancel_pickup"
            >
              {isNotDeleted ? 'Nie odwołuj' : 'Zamknij okno'}
            </BorderButton>
          </Box>
          {isNotDeleted && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ErrorGradientButton
                sx={{ margin: '10px 0' }}
                data-cy="cancel_pickup"
                loading={loading}
                onClick={() => {
                  dispatch(deleteOrder());
                }}
              >
                Odwołaj wywóz
              </ErrorGradientButton>
            </Box>
          )}
        </Row>
      </Paper>
    </Box>
  );
};
