import React, { useState } from 'react';
import { useFormik } from 'formik';

import { Typography, Box } from '@mui/material';
import { ChangePasswordInterface } from '@forms/Settings/ChangePassword/interface';
import validator from '@forms/Settings/ChangePassword/validator';
import theme from '@styles/theme';
import { useDispatch } from 'react-redux';
import { changePassword } from '@redux/userSettings/myAccount/actions';
import { styled } from '@mui/material/styles';
import {
  CardDescription,
  CardTitle,
  GradientButton,
  JustRow,
} from '@styles/styled';
import HideComponentArrow from '@components/elements/HideComponentArrow';
import CustomTextField from '@components/elements/CustomTextField';

const initialValues: ChangePasswordInterface = {
  old_password: '',
  new_password1: '',
  new_password2: '',
};

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 990px) {
    flex-direction: column;
    gap: 0;
  }
  @media (max-width: 905px) {
    flex-direction: row;
    gap: 30px;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 0;
  }
`;

const ContactForm: React.FC = () => {
  const [isHided, setIsHided] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: (values, { resetForm }) => {
      dispatch(changePassword(values));
      resetForm();
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <JustRow>
        <Box>
          <CardTitle sx={{ mt: 1 }}>Zmiana hasła</CardTitle>
          {isHided && (
            <CardDescription sx={{ mb: 3 }}>
              Zmień swoje hasło logowania do aplikacji.
            </CardDescription>
          )}
        </Box>
        <HideComponentArrow
          ishided={isHided}
          handleHide={() => setIsHided((prevState) => !prevState)}
        />
      </JustRow>

      {isHided && (
        <>
          <Row>
            <Box sx={{ flex: 1 }}>
              <CustomTextField
                id="old_password"
                label="STARE HASŁO"
                type="password"
                value={formik.values.old_password}
                onChange={formik.handleChange}
                error={
                  formik.touched.old_password &&
                  Boolean(formik.errors.old_password)
                }
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <CustomTextField
                id="new_password1"
                label="NOWE HASŁO"
                type="password"
                value={formik.values.new_password1}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password1 &&
                  Boolean(formik.errors.new_password1)
                }
                helperText={
                  formik.touched.new_password1 && formik.errors.new_password1
                }
              />
              <Typography
                color={theme.palette.text.disabled}
                variant="subtitle2"
                sx={{ width: '90%', margin: '0 auto' }}
              >
                (min. 8 znaków w tym przynajmniej 1 cyfra i 1 duża litera)
              </Typography>
              <CustomTextField
                id="new_password2"
                label="POWTÓRZ HASŁO"
                type="password"
                value={formik.values.new_password2}
                onChange={formik.handleChange}
                error={
                  formik.touched.new_password2 &&
                  Boolean(formik.errors.new_password2)
                }
                helperText={
                  formik.touched.new_password2 && formik.errors.new_password2
                }
              />
            </Box>
          </Row>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GradientButton
              type="submit"
              variant="contained"
              sx={{ mt: 6, mb: 0 }}
              disabled={false}
              loading={false}
              data-cy="submit_save"
            >
              Zapisz zmiany
            </GradientButton>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ContactForm;
