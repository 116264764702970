const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: 'szambo-online-react.firebaseapp.com',
  projectId: 'szambo-online-react',
  storageBucket: 'szambo-online-react.appspot.com',
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
  measurementId: process.env.MEASUREMENT_ID,
};

export { firebaseConfig };
