import { CardDescription, GradientButton } from '@styles/styled';
import { Box, FormHelperText, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadButton from '@components/elements/DownloadButton';
import { DropzoneComponent } from '@components/modules/DropZoneModals/DropzoneComponent';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { uploadSignedProtocol } from '@redux/userCesspools/actions';
import { RootState, UserTanksInterface } from '@redux/interfaces';
import validator from './validator';

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

type PropsT = {
  downloadProtocol: () => void;
};

const SignProtocol = ({ downloadProtocol }: PropsT) => {
  const [compressedFile, setCompressedFile] = useState<File | null>(null);
  const [imageCompressionInProgress, setImageCompressionInProgress] =
    useState<boolean>(false);

  const {
    currentTank: { virtual_control_protocol },
    selectedTankId,
    loading,
  } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const dispatch = useDispatch();

  const { handleSubmit, values, errors, setFieldValue } = useFormik({
    initialValues: { file: false },
    validationSchema: validator,
    onSubmit: (values) => {
      const formData = new FormData();
      if (compressedFile) {
        const blobFile = new File([compressedFile], compressedFile.name);
        formData.append('protocol', blobFile);
        dispatch(
          uploadSignedProtocol(`${selectedTankId}/documents/`, formData)
        );
      }
    },
  });

  useEffect(() => {
    if (compressedFile && !values.file) {
      setFieldValue('file', true);
    }
  }, [compressedFile]);

  return (
    <form onSubmit={handleSubmit}>
      <CardDescription sx={{ fontWeight: 'bold' }}>
        Twój urząd prosi Cię o weryfikację i podpisanie protokołu kontroli
        Twojego zbiornika.
      </CardDescription>
      <Typography fontSize="14px" mt={2}>
        - Pobierz podpisany przez urzędnika protokół kontroli{' '}
        <DownloadButton downloadFn={() => downloadProtocol()} />
      </Typography>
      <Typography fontSize="14px" mt={2}>
        - Podpisz protokół elektronicznie - wykorzystaj podpis zaufany na{' '}
        <Link
          href="https://www.gov.pl/web/gov/podpisz-dokument-elektronicznie-wykorzystaj-podpis-zaufany"
          target="_blank"
        >
          gov.pl
        </Link>
      </Typography>
      <Typography fontSize="14px" mt={2}>
        - Podpisany protokół wgraj poniżej a następnie wyślij go do swojego
        urzędu
      </Typography>
      <Typography fontSize="14px" mt={2}>
        Gotowe!
      </Typography>

      <Box mt={2}>
        <Link href="https://szambo.online/ewidencja/" target="_blank">
          Sprawdź jak podpisać cyfrowo protokół &gt;&gt;
        </Link>
      </Box>

      <Typography fontSize="14px" fontWeight="bold" mt={2}>
        PROTOKÓŁ KONTROLI dla adresu:
      </Typography>
      <Typography fontSize="14px" fontWeight="bold" mb={3}>
        {virtual_control_protocol?.address}
      </Typography>
      <DropzoneComponent
        label="ZAŁĄCZ PROTOKÓŁ PODPISANY CYFROWO (PDF):"
        compressedFile={compressedFile}
        setCompressedFile={(f: any) => setCompressedFile(f)}
        imageCompressionInProgress={imageCompressionInProgress}
        setImageCompressionInProgress={setImageCompressionInProgress}
        error={errors.file}
        fileTypes={{ 'application/pdf': ['.pdf'] }}
      />
      {errors.file && (
        <FormHelperText error data-cy="dropzone_error">
          {errors.file}
        </FormHelperText>
      )}
      <ButtonWrapper>
        <GradientButton
          type="submit"
          sx={{ marginTop: 3, marginBottom: 2 }}
          loading={loading}
          disabled={loading}
        >
          Wyślij do urzędu
        </GradientButton>
      </ButtonWrapper>
    </form>
  );
};

export default SignProtocol;
