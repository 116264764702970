import { SvgIcon, Box, SvgIconProps } from '@mui/material';
import arrow from '../../../../public/icons/angle-down-solid.svg';
import React from 'react';
import { styled } from '@mui/material/styles';

type Props = {
  ishided: boolean;
  handleHide: () => void;
};

interface IRotatedArrow extends SvgIconProps {
  ishided: number;
  component: any;
}

const RotatedArrow = styled(SvgIcon)<IRotatedArrow>`
  font-size: 40px;
  transition: 0.3s ease-in-out;
  transform: rotate(${(p) => (p.ishided ? '180deg' : '0deg')});
`;

const HideComponentArrow = ({ ishided, handleHide }: Props) => (
  <Box>
    <RotatedArrow
      component={arrow}
      viewBox="0 0 384 512"
      ishided={ishided ? 1 : 0}
      onClick={() => {
        handleHide();
      }}
    />
  </Box>
);

export default HideComponentArrow;
