import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';
import { Breakpoint, styled } from '@mui/material/styles';
import { Container, Paper } from '@mui/material';
import { ReactNode } from 'react';

const CenterContainer = styled(Container)`
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: ${(p: { modalPosition: 'flex-start' | 'center' }) =>
    p.modalPosition};
  padding: 30px 0;
  height: 100vh;
  overflow: auto;
  background-image: linear-gradient(
    120deg,
    rgba(87, 140, 34, 0.95),
    rgba(168, 195, 0, 0.95)
  );

  @media (max-width: 660px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const CustomPaper = styled(Paper)`
  padding: 24px;
  border-radius: 25px;
  box-shadow: 0px 16px 24px rgba(10, 60, 70, 0.16);

  @media (max-width: 660px) {
    margin-bottom: 120px;
  }
`;

const UniversalModalLayout = ({
  children,
  containerSize = 'md',
  modalPosition = 'center',
}: {
  children: ReactNode;
  containerSize?: Breakpoint;
  modalPosition?: 'flex-start' | 'center';
}) => {
  return (
    <SemiTransparentBackground>
      <CenterContainer maxWidth={false} modalPosition={modalPosition}>
        <Container maxWidth={containerSize}>
          <CustomPaper>{children}</CustomPaper>
        </Container>
      </CenterContainer>
    </SemiTransparentBackground>
  );
};

export default UniversalModalLayout;
