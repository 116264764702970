import { Box, TextField, Typography } from '@mui/material';
import theme from '@styles/theme';
import React from 'react';
import {
  ButtonWrapper,
  RedStar,
} from '@components/modules/DropZoneModals/styled';
import { useFormik } from 'formik';
import { GradientButton } from '@styles/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  HistoryDropItemInterface,
  MyExportsInterface,
  RootState,
} from '@redux/interfaces';
import validator from '@forms/ReportComplaintForm/validator';
import { reportComplaint } from '@redux/myExports/actions';

type PropsT = {
  orderRecord: HistoryDropItemInterface | null;
  closeModal: () => void;
};

const ReportComplaintForm = ({ orderRecord, closeModal }: PropsT) => {
  const { complaintLoading } = useSelector<RootState, MyExportsInterface>(
    (state) => state.myExports
  );

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      complaint: '',
    },
    validationSchema: validator,
    onSubmit: (values) => {
      if (orderRecord) {
        dispatch(reportComplaint(`${orderRecord.id}/`, values));
        closeModal();
      }
    },
  });

  return (
    <Box sx={{ mt: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          color={theme.palette.secondary.main}
        >
          <RedStar /> Treść reklamacji dla firmy asenizacyjnej
        </Typography>
        <TextField
          multiline
          placeholder="Wpisz treść reklamacji do 600 znaków..."
          minRows={3}
          fullWidth
          value={formik.values.complaint}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.complaint && Boolean(formik.errors.complaint)}
          helperText={formik.touched.complaint && formik.errors.complaint}
          inputProps={{ maxlength: 600, 'data-cy': 'complaint' }}
          name="complaint"
          id="complaint"
          InputProps={{
            endAdornment: (
              <Typography color="gray">
                {formik.values.complaint.length}/600
              </Typography>
            ),
          }}
        />
        <Typography
          sx={{ pl: '10px', mt: 3, mb: 3, fontSize: '14px' }}
          color={theme.palette.secondary.main}
        >
          <RedStar /> Pola obowiązkowe
        </Typography>
        <ButtonWrapper>
          <GradientButton
            sx={{ m: 0 }}
            type="submit"
            loading={complaintLoading}
            disabled={complaintLoading}
            data-cy="submit_complaint"
          >
            Wyślij reklamację
          </GradientButton>
        </ButtonWrapper>
      </form>
    </Box>
  );
};

export default ReportComplaintForm;
