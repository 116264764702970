import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SmallerGradientButton, SmallerBorderButton } from '@styles/styled';
import {
  OrderInterface,
  PanoramaInterface,
  RootState,
} from '@redux/interfaces';
import {
  levelEndFA,
  levelStartFA,
  selectedItemFA,
} from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';
import { useSelector } from 'react-redux';

const LogoRow = styled(Box)`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
  padding: 5px 5px 5px 0;
`;

const Row = styled(LogoRow)`
  justify-content: space-between;
  padding: 10px;

  &:nth-child(odd) {
    background-color: #f7f8f9;
  }

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

type LSPropsT = {
  center: boolean;
};

const LeftSide = styled(Box)`
  width: ${(p: LSPropsT) => (p.center ? '100%' : '68%')};

  @media (max-width: 660px) {
    width: 100%;
    padding: 10px;
  }
`;

type StyledProps = {
  driver: boolean;
};

const DriverName = styled(Typography)`
  font-size: ${(p: StyledProps) => (p.driver ? '20px' : '16px')};
  font-weight: ${(p: StyledProps) => (p.driver ? 'normal' : 'bold')};
  min-height: ${(p: StyledProps) => (p.driver ? '70px' : 'auto')};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  item: PanoramaInterface;
  nextStep: (arg1: 'NUM' | 'APP', arg2?: PanoramaInterface) => void;
  appClient: boolean;
};

const SingleDriver = ({ item, nextStep, appClient }: Props) => {
  const {
    companies: { has_permission_to_find_company },
  } = useSelector<RootState, OrderInterface>((state) => state.order);

  const selectDriver = () => {
    nextStep(appClient ? 'APP' : 'NUM', item);
    analyticsLogOnSelect();
  };

  const analyticsLogOnSelect = () => {
    const { id, name } = item;

    selectedItemFA(name, String(id));
    levelEndFA(types.STEP_1, true);
    levelStartFA(appClient ? types.LEVEL_NAME_APP[0] : types.LEVEL_NAME_NUM[0]);
    levelEndFA(
      appClient ? types.LEVEL_NAME_APP[0] : types.LEVEL_NAME_NUM[0],
      true
    );
    levelStartFA(appClient ? types.LEVEL_NAME_APP[1] : types.LEVEL_NAME_NUM[1]);
  };

  return (
    <Row>
      <LeftSide center={!appClient && has_permission_to_find_company}>
        {appClient && (
          <LogoRow>
            <img src="/images/so_logo_long.png" alt="Szambo.online logo" />
          </LogoRow>
        )}
        <DriverName variant="h6" driver={Boolean(appClient)}>
          {item.name}
        </DriverName>
      </LeftSide>
      <Box>
        {appClient ? (
          <SmallerGradientButton onClick={() => selectDriver()}>
            Zamów wywóz
          </SmallerGradientButton>
        ) : has_permission_to_find_company ? null : (
          <SmallerBorderButton onClick={() => selectDriver()}>
            Pokaż numer
          </SmallerBorderButton>
        )}
      </Box>
    </Row>
  );
};

export default SingleDriver;
