import { Box, Skeleton, Typography } from '@mui/material';
import theme from '@styles/theme';
import React from 'react';

type PropsT = {
  left: string;
  right?: string | number | null;
  loading?: boolean;
};

const OneRowTextInfo = ({ left, right, loading = false }: PropsT) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {loading ? (
        <>
          <Skeleton
            variant="rectangular"
            width="35%"
            height={15}
            sx={{ mt: 1 }}
          />
          <Skeleton variant="rectangular" width="30%" height={15} />
        </>
      ) : (
        <>
          <Typography color={theme.palette.secondary.main} fontWeight="bold">
            {left}
          </Typography>
          {right && <Typography textAlign="right">{right}</Typography>}
        </>
      )}
    </Box>
  );
};

export default OneRowTextInfo;
