import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { OrderInterface, PaymentInterface, RootState } from '@redux/interfaces';
import SingleLine from '@components/elements/PayForOrderModal/SingleLine';
import download from '../../../../public/icons/download.svg';
import theme from '@styles/theme';
import { getInvoice } from '@redux/myExports/actions';
import { SmallRow } from '@components/elements/DropsGrid/styled';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { toast } from 'react-toastify';

const StyledContainer = styled(Container)`
  width: 90%;
  border-bottom: 1px solid #ddd;

  @media (max-width: 660px) {
    width: 100%;
    padding: 0 10px;
    border-bottom: none;
  }
`;

const WrapAccountNumber = styled(Typography)`
  font-weight: 600;
  color: gray;
  text-align: right;

  @media (max-width: 400px) {
    padding: 0 15% 0 10px;
  }
`;

export const RowWithPointer = styled(SmallRow)`
  cursor: pointer;
`;

const PaymentSummary = () => {
  const dispatch = useDispatch();

  const {
    invoiceToPay: {
      done_date,
      payment_deadline,
      account_number,
      operator,
      invoice_value,
      has_first_order_discount,
      cesspool_removed_amount_id,
    },
  } = useSelector<RootState, OrderInterface>((state) => state.order);

  const { isPaid } = useSelector<RootState, PaymentInterface>(
    (state) => state.payment
  );

  const formatAccountNumber = () => {
    const withoutPL = account_number.slice(4, account_number.length);
    const PL = account_number.slice(0, 2);
    const firstTwo = account_number.slice(2, 4);

    // @ts-ignore
    const res = [...withoutPL]
      .map((d, num) => (num % 4 == 0 ? ' ' + d : d))
      .join('')
      .trim();

    return `${PL} ${firstTwo} ${res}`;
  };

  async function copyAccountNumber() {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(account_number);
    }
  }

  return (
    <StyledContainer maxWidth={false}>
      <Box>
        <Typography variant="h6" sx={{ pl: 0, fontWeight: 'bold' }}>
          Twoje zamówienie:
        </Typography>
        <Box>
          <SingleLine label="Data wywozu: " value={done_date} />
          <SingleLine label="Wybrany operator: " value={operator} />
          <SingleLine
            label="Koszt: "
            value={`${
              has_first_order_discount
                ? invoice_value - 10 > 0
                  ? invoice_value - 10
                  : 0
                : invoice_value
            } zł`}
          />
          <SingleLine
            label="Faktura: "
            element={
              <RowWithPointer
                onClick={() => {
                  dispatch(getInvoice(`${cesspool_removed_amount_id}`));
                }}
              >
                <SvgIcon
                  component={download}
                  viewBox="0 0 18 18"
                  sx={{ mr: 0.5 }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'bold' }}
                  color={theme.palette.primary.dark}
                >
                  Pobierz
                </Typography>
              </RowWithPointer>
            }
          />
          {!isPaid && (
            <>
              <SingleLine
                label="Numer rachunku: "
                element={
                  <RowWithPointer
                    onClick={() =>
                      copyAccountNumber().then(() =>
                        toast.success('Pomyślnie skopiowano numer rachunku.')
                      )
                    }
                  >
                    <CopyAllIcon
                      sx={{ mr: 0.5, color: 'gray' }}
                      key="copyAccountNumber"
                    />
                    <WrapAccountNumber>
                      {formatAccountNumber()}
                    </WrapAccountNumber>
                  </RowWithPointer>
                }
              />
              <SingleLine
                label="Termin płatności: "
                element={
                  <Typography color="gray" sx={{ fontWeight: 'bold' }}>
                    {payment_deadline}
                  </Typography>
                }
              />
            </>
          )}
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default PaymentSummary;
