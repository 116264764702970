import React from 'react';
import { Box, Typography } from '@mui/material';
import { GradientButton } from '@styles/styled';
import { useNavigate } from 'react-router-dom';

const VerifyMailTemplate = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h5" sx={{ textAlign: 'center', mb: 2 }}>
        Zweryfikuj swój email
      </Typography>
      <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
        Aby móc skorzystać z aplikacji najpierw musisz zweryfikować swój adres
        email
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <GradientButton
          variant="outlined"
          onClick={() => {
            navigate('/login', { replace: true });
          }}
          data-cy="backToLogin"
        >
          Wróć do logowania
        </GradientButton>
      </Box>
    </Box>
  );
};

export default VerifyMailTemplate;
