import { Box } from '@mui/material';
import CustomTextField from '@components/elements/CustomTextField';

type PropsT = {
  formik: any;
};

const AdditionalContactData = ({ formik }: PropsT) => {
  return (
    <Box sx={{ marginTop: '-16px', width: '40%' }}>
      <CustomTextField
        id="phoneNumber"
        label="TELEFON"
        value={formik.values.phoneNumber}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.phoneNumber)}
        helperText={formik.errors.phoneNumber}
      />
    </Box>
  );
};

export default AdditionalContactData;
