import React, { ReactNode } from 'react';
import { BorderButton, ErrorGradientButton } from '@styles/styled';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { ExitIcon } from '@components/elements/ExitIcon';
import { styled } from '@mui/material/styles';
import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: left;
  }
`;

type Props = {
  exit: () => void;
  cancelAdd: () => void;
  warningMessage: ReactNode | string;
  backButtonText: ReactNode | string;
  cancelButtonText: ReactNode | string;
  loading?: boolean;
};

export const WarningModal = ({
  exit,
  cancelAdd,
  warningMessage,
  backButtonText,
  cancelButtonText,
  loading = false,
}: Props) => (
  <SemiTransparentBackground
    additionalStyle={{
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1001,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage:
        'linear-gradient(120deg,rgba(87, 140, 34, 0.95),rgba(168, 195, 0, 0.95))',
    }}
  >
    <Box>
      <Paper
        sx={{
          p: 3,
          borderRadius: '25px',
          boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
        }}
      >
        <Grid container sx={{ p: 2 }}>
          <Grid item xs={0} md={2}></Grid>
          <Grid item xs={8} sx={{ textAlign: 'center' }} color="black">
            <Title variant="h3">Uwaga</Title>
          </Grid>
          <Exit
            item
            xs={4}
            md={2}
            sx={{ textAlign: 'right' }}
            onClick={() => {
              exit();
            }}
          >
            <ExitIcon />
          </Exit>
        </Grid>
        <Typography sx={{ textAlign: 'center', mt: 1, fontWeight: 'bold' }}>
          {warningMessage}
        </Typography>

        <Row>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <BorderButton
              sx={{ margin: '10px 0' }}
              onClick={() => exit()}
              data-cy="exit_warning_modal"
              loading={loading}
            >
              {backButtonText}
            </BorderButton>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ErrorGradientButton
              sx={{ margin: '10px 0' }}
              data-cy="cancel_add_button"
              loading={loading}
              onClick={() => {
                cancelAdd();
              }}
            >
              {cancelButtonText}
            </ErrorGradientButton>
          </Box>
        </Row>
      </Paper>
    </Box>
  </SemiTransparentBackground>
);
