'use client';

import * as yup from 'yup';

export default yup.object({
  addAgreement: yup.boolean(),
  date: yup
    .date()
    .nullable(true)
    .when('addAgreement', {
      is: true,
      then: yup.date().required('Data umowy jest wymagana'),
      otherwise: yup.date().required('Data odbioru jest wymagana'),
    }),
  companyName: yup
    .object()
    .shape({
      name: yup.string().required('Nazwa operatora jest wymagana'),
    })
    .nullable()
    .required('Nazwa operatora jest wymagana'),
  file: yup
    .boolean()
    .required('Plik jest wymagany')
    .oneOf([true], 'Plik jest wymagany'),
});
