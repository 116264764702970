import { Container, FormHelperText, Paper, Typography } from '@mui/material';
import ModalHeader from '@components/elements/ModalHeader';
import {
  ButtonWrapper,
  Description,
  RedStar,
  Row,
} from '@components/modules/DropZoneModals/styled';

import { DropzoneComponent } from '@components/modules/DropZoneModals/DropzoneComponent';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { GradientButton } from '@styles/styled';
import contractValidator from '@components/modules/DropZoneModals/AddNewContractModal/contractValidator';

import { useDispatch, useSelector } from 'react-redux';
import {
  AgreementsInterface,
  RootState,
  SingleAgreementInterface,
  UserTanksInterface,
} from '@redux/interfaces';
import { addNewAgreement, editAgreement } from '@redux/agreements/actions';
import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';
import CompanySelector from '@components/modules/DropZoneModals/CompanySelector';
import DateSelector from '@components/modules/DropZoneModals/DateSelector';
import theme from '@styles/theme';
import { types } from '@redux/agreements/types';

type PropsT = {
  close: () => void;
  selectedAgreement: SingleAgreementInterface | null;
};

const initialValues = {
  date: '',
  companyName: { id: '', name: '' },
  file: false,
  addAgreement: true,
};

const AddNewContractModal = ({ close, selectedAgreement }: PropsT) => {
  const [compressedFile, setCompressedFile] = useState<File | null>(null);
  const [imageCompressionInProgress, setImageCompressionInProgress] =
    useState<boolean>(false);

  const { selectedTankId } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { loading, agreementAddedOrEdited } = useSelector<
    RootState,
    AgreementsInterface
  >((state) => state.agreements);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: contractValidator,
    onSubmit: (values) => {
      const { date, companyName } = values;

      const dateObject = new Date(date);
      const fixedDate =
        dateObject.getFullYear() +
        '-' +
        (dateObject.getMonth() + 1) +
        '-' +
        dateObject.getDate();

      const formData = new FormData();
      if (compressedFile) {
        const blobFile = new File([compressedFile], compressedFile.name);
        formData.append('file_path', blobFile);
      }
      formData.append('client_property', String(selectedTankId));
      formData.append('conclusion_date', fixedDate);
      formData.append('company_name', companyName.name);
      companyName.id && formData.append('company', companyName.id);

      if (selectedAgreement) {
        dispatch(editAgreement(`${selectedAgreement.id}/`, formData));
      } else {
        dispatch(addNewAgreement(formData));
      }
    },
  });

  useEffect(() => {
    if (!loading && agreementAddedOrEdited) {
      close();
      dispatch({ type: types.RESET_ADD_AGREEMENT });
    }
  }, [loading, agreementAddedOrEdited]);

  useEffect(() => {
    if (selectedAgreement) {
      const { conclusion_date, company, company_name } = selectedAgreement;

      formik.setFieldValue('date', conclusion_date);
      formik.setFieldValue('companyName', { name: company_name, id: company });
    }
  }, [selectedAgreement]);

  useEffect(() => {
    if ((compressedFile && !formik.values.file) || selectedAgreement?.id) {
      formik.setFieldValue('file', true);
    }
  }, [compressedFile]);

  return (
    <SemiTransparentBackground
      dataCy="add_agreement_modal"
      additionalStyle={{
        backgroundImage:
          'linear-gradient(120deg,rgba(87, 140, 34, 0.95),rgba(168, 195, 0, 0.95))',
      }}
    >
      <Container maxWidth="md">
        <Paper
          sx={{
            p: 3,
            borderRadius: '25px',
            boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <ModalHeader
              title={selectedAgreement ? 'Edycja umowy' : 'Dodawanie umowy'}
              handleExit={close}
            />
            <Description>Wgraj zdjęcie lub skan umowy</Description>
            <Row>
              <DateSelector
                formik={formik}
                label="DATA UMOWY"
                fieldName="date"
              />
              <CompanySelector
                formik={formik}
                tankId={selectedTankId}
                agreementModal
              />
            </Row>
            <DropzoneComponent
              data-cy="dropzone-input"
              label="ZDJĘCIE/SKAN UMOWY*"
              error={formik.errors.file}
              compressedFile={compressedFile}
              setCompressedFile={(f: any) => setCompressedFile(f)}
              imageCompressionInProgress={imageCompressionInProgress}
              setImageCompressionInProgress={setImageCompressionInProgress}
            />
            {formik.errors.file && (
              <FormHelperText error sx={{ pl: 2 }} data-cy="dropzone_error">
                {formik.errors.file}
              </FormHelperText>
            )}
            <Typography
              sx={{ pl: '10px', mt: 3, mb: 3, fontSize: '14px' }}
              color={theme.palette.secondary.main}
            >
              <RedStar /> Pola obowiązkowe
            </Typography>
            <ButtonWrapper>
              <GradientButton
                sx={{ mt: 3, mb: 0 }}
                type="submit"
                loading={loading || imageCompressionInProgress}
                disabled={loading || imageCompressionInProgress}
                data-cy="submit_agreement"
              >
                {selectedAgreement ? 'Zapisz zmiany' : 'Dodaj umowę'}
              </GradientButton>
            </ButtonWrapper>
          </form>
        </Paper>
      </Container>
    </SemiTransparentBackground>
  );
};

export default AddNewContractModal;
