import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { Box, Paper, Typography } from '@mui/material/';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';

import { RootState, AuthApiResult } from '@redux/interfaces';
import LoginForm from '@forms/LoginForm';

import logo from '../../../../public/images/szambo.online.png';
import Link from 'next/link';

const Row = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 660px) {
    flex-direction: column;
  }
`;

const LoginPageTemplate: React.FC = () => {
  const { error, loading } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  return (
    <Box>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          borderRadius: 10,
          padding: 5,
        }}
      >
        <Image src={logo} alt={'logo'} />

        <LoginForm error={error} loading={loading} />

        <Row>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 'bold', textAlign: 'center', mr: 1 }}
          >
            Nie masz jeszcze konta?{' '}
          </Typography>
          <Link href="/register">
            <Typography
              sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
            >
              Zarejestruj się
            </Typography>
          </Link>
        </Row>
      </Paper>
    </Box>
  );
};
export default LoginPageTemplate;
