'use client';

import { types } from '@redux/userSettings/myAccount/types';
import { ChangePasswordInterface } from '@forms/RegisterForm/interface';
import { UpdateClientInterface } from '@forms/Settings/ContactForm/interface';
import { BillingDataI } from '@redux/interfaces';

export const getClientContactData = () => {
  return {
    method: 'GET',
    type: types.GET_CONTACT_DATA_REQUEST,
  };
};

export const updateClient = (data: UpdateClientInterface) => {
  return {
    method: 'PUT',
    type: types.UPDATE_CONTACT_DATA_REQUEST,
    data,
  };
};

export const changePassword = (data: ChangePasswordInterface) => {
  return {
    method: 'POST',
    type: types.CHANGE_PASSWORD_REQUEST,
    data,
  };
};

export const getClientBillingData = () => {
  return {
    method: 'GET',
    type: types.GET_BILLING_DATA_REQUEST,
  };
};

export const updateClientBillingData = (data: BillingDataI) => {
  return {
    method: 'POST',
    type: types.UPDATE_BILLING_DATA_REQUEST,
    data,
  };
};
