'use client';

const apiKey = process.env.GOOGLE_MAP_KEY;
const googleUrl = 'https://maps.google.com/maps/api/geocode/json';

export default {
  async getGeolocation(address: string) {
    const url = `${googleUrl}?key=${apiKey}&address=${address}`;

    const response = await fetch(url).catch(() => {
      return Promise.reject(new Error('Error fetching data'));
    });

    const json = await response.json().catch(() => {
      return Promise.reject(new Error('Error parsing server response'));
    });

    const data = Promise.resolve(json.results[0].geometry.location);
    const resolved = await data;

    if (json.status === 'OK') {
      return resolved;
    }
  },
};
