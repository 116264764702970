export const ExitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="37"
    viewBox="0 0 37 37"
  >
    <g id="Group_1323" data-name="Group 1323" transform="translate(-290 -724)">
      <rect
        id="Rectangle_1029"
        data-name="Rectangle 1029"
        width="6"
        height="46"
        rx="3"
        transform="translate(327.385 756.527) rotate(135)"
        fill="#061b1f"
      />
      <rect
        id="Rectangle_1030"
        data-name="Rectangle 1030"
        width="6"
        height="46"
        rx="3"
        transform="translate(332 739) rotate(90)"
        fill="#fff"
        opacity="0"
      />
      <rect
        id="Rectangle_1032"
        data-name="Rectangle 1032"
        width="6"
        height="46"
        rx="3"
        transform="translate(323.142 724) rotate(45)"
        fill="#061b1f"
      />
    </g>
  </svg>
);
