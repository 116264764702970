let payu: any;
let secureForm: any;

export const getPayu = (posId: string | null) => {
  if (payu) {
    return payu;
  }
  //  @ts-ignore - external sdk
  // eslint-disable-next-line new-cap
  payu = PayU(`${posId}`);
  return payu;
};

export const getSecureForm = (posId: string | null) => {
  if (secureForm) {
    return secureForm;
  }

  const _payu = getPayu(posId);
  secureForm = _payu.secureForms();
  return secureForm;
};

export const resetPayu = () => {
  payu = undefined;
  secureForm = undefined;
};
