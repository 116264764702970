import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, SvgIcon, Typography } from '@mui/material';
import exit from '../../../../public/icons/exit.svg';

const RowTitle = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const Title = styled(Typography)`
  width: 100%;
  text-align: center;
  margin-left: 42px;

  @media (max-width: 660px) {
    font-size: 30px;
    margin-left: 0;
    text-align: left;
  }
`;

type PropsT = {
  title: string;
  handleExit: () => void;
};

const ModalHeader = ({ title, handleExit }: PropsT) => {
  return (
    <RowTitle>
      <Title variant="h1">{title}</Title>
      <SvgIcon
        onClick={handleExit}
        component={exit}
        viewBox="0 0 64 36.77"
        sx={{ fontSize: '42px', cursor: 'pointer' }}
      />
    </RowTitle>
  );
};

export default ModalHeader;
