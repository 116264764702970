import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CardDescription, CardTitle, JustRow } from '@styles/styled';
import HideComponentArrow from '@components/elements/HideComponentArrow';
import { useDispatch } from 'react-redux';
import { getClientBillingData } from '@redux/userSettings/myAccount/actions';
import BillingDataForm from '@forms/BillingDataForm';

const BillingData: React.FC = () => {
  const [isHided, setIsHided] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientBillingData());
  }, []);

  return (
    <>
      <JustRow>
        <Box>
          <CardTitle sx={{ mt: 1 }}>Twoje dane billingowe</CardTitle>
          {isHided && (
            <CardDescription sx={{ mb: 3 }}>
              Faktury za wywóz będą wystawione na poniższe dane
            </CardDescription>
          )}
        </Box>
        <HideComponentArrow
          ishided={isHided}
          handleHide={() => setIsHided((prevState) => !prevState)}
        />
      </JustRow>

      {isHided && <BillingDataForm />}
    </>
  );
};

export default BillingData;
