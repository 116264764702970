'use client';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { StyledComponent } from '@mui/styles';

export const TableWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const GridContainer: StyledComponent<any> = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  flex: 1;
  width: 100%;
  cursor: ${(p: { clickable?: boolean }) =>
    p.clickable ? 'pointer' : 'default'};

  @media (max-width: 550px) {
    flex-direction: row;
    border: none;
    border-bottom: 1px solid #ddd;
  }
`;

export const GridItem = styled('div')`
  width: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  @media (max-width: 660px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    border: none;
  }
`;

export const ShortertItem = styled(GridItem)`
  width: 55%;
  justify-content: center;

  @media (max-width: 660px) {
    width: 50%;
    justify-content: flex-end;
  }
`;

export const DisabledOnMobile = styled(ShortertItem)`
  @media (max-width: 660px) {
    display: none;
  }
`;

export const UltraShort = styled(ShortertItem)`
  @media (max-width: 660px) {
    width: 10%;
  }
`;

export const SmallRow = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ScrollBox = styled(Box)`
  @media (max-width: 660px) {
    margin: 0 auto;
    width: 75vw;
    overflow: auto;
  }
`;
