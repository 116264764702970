import UniversalModalLayout from '@components/layouts/UniversalModalLayout';
import ModalHeader from '@components/elements/ModalHeader';
import { Description } from '@components/modules/DropZoneModals/styled';
import { MyExportsInterface, RootState } from '@redux/interfaces';
import { Box, Skeleton, Typography } from '@mui/material';
import OneRowTextInfo from '@components/elements/OneRowTextInfo';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';
import React from 'react';
import { getFormatedDate, getFormatedTime } from '@utils/formatDate';

const ComplaintBox = styled(Box)`
  border: dashed 2px ${theme.palette.primary.main};
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding: 10px;
  margin-top: 5px;
`;

const ComplaintDescriptionText = styled(Typography)`
  overflow-y: hidden;
  word-wrap: break-word;
`;

type PropsT = {
  close: () => void;
};

const ComplaintDetailModal = ({ close }: PropsT) => {
  const { complaintDetail, complaintLoading } = useSelector<
    RootState,
    MyExportsInterface
  >((state) => state.myExports);

  return (
    <UniversalModalLayout>
      <ModalHeader title="Szczegóły reklamacji" handleExit={close} />
      {complaintLoading ? (
        <Box display="flex" justifyContent="center">
          <Skeleton
            variant="rectangular"
            width="40%"
            height={25}
            sx={{ mb: 4, mt: 2 }}
          />
        </Box>
      ) : (
        <Description>
          <>
            Reklamacja z dnia{' '}
            {complaintDetail.created_at &&
              getFormatedDate(complaintDetail.created_at)}
          </>
        </Description>
      )}
      <Box>
        <OneRowTextInfo
          left="Informacje podstawowe:"
          loading={complaintLoading}
        />
        <OneRowTextInfo
          left="Data wywozu:"
          right={getFormatedDate(complaintDetail.order_date)}
          loading={complaintLoading}
        />
        <OneRowTextInfo
          left="Odebrana ilość ścieków:"
          right={`${complaintDetail.amount}m3`}
          loading={complaintLoading}
        />
        <OneRowTextInfo
          left="Koszt usług:"
          right={`${complaintDetail.price}zł`}
          loading={complaintLoading}
        />
        <OneRowTextInfo
          left="Operator:"
          right={complaintDetail.sanitation_company_name}
          loading={complaintLoading}
        />
        <OneRowTextInfo
          left="Zbiornik:"
          right={complaintDetail.client_property_name}
          loading={complaintLoading}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        {complaintLoading ? (
          <Skeleton
            variant="rectangular"
            width="45%"
            height={15}
            sx={{ mb: 1 }}
          />
        ) : (
          <Typography>
            {complaintDetail.created_at &&
              `Wysłano: ${getFormatedDate(
                complaintDetail.created_at
              )} g.${getFormatedTime(complaintDetail.created_at)}`}
          </Typography>
        )}
        <ComplaintBox>
          {complaintLoading ? (
            <Box width="100%">
              <Skeleton variant="rectangular" width="100%" height={15} />
              <Skeleton
                variant="rectangular"
                width="100%"
                height={15}
                sx={{ mt: 2 }}
              />
              <Skeleton
                variant="rectangular"
                width="75%"
                height={15}
                sx={{ mt: 2 }}
              />
            </Box>
          ) : (
            <ComplaintDescriptionText>
              {complaintDetail.complaint}
            </ComplaintDescriptionText>
          )}
        </ComplaintBox>
      </Box>
    </UniversalModalLayout>
  );
};

export default ComplaintDetailModal;
