import React from 'react';
import { Box, Container } from '@mui/material';

import LowerFooter from '@components/elements/Footer/LowerFooter';
import { styled } from '@mui/material/styles';
import UpperFooter from '@components/elements/Footer/UpperFooter';

const FooterWrapper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
`;

const LeftSpace = styled(Box)`
  flex: 1;
  height: 10px;

  @media (max-width: 660px) {
    display: none;
  }
`;

const Footer = () => (
  <footer>
    <FooterWrapper disableGutters maxWidth={false}>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1 }}>
        <LeftSpace />
        <Box sx={{ flex: 3 }}>
          <UpperFooter />
        </Box>
      </Box>
      <Box sx={{ backgroundColor: '#506266' }}>
        <LowerFooter />
      </Box>
    </FooterWrapper>
  </footer>
);

export default Footer;
