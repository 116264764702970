import React, { useEffect, useState, ReactElement } from 'react';
import { TextField } from '@mui/material';
import theme from '@styles/theme';

type Props = {
  id: string;
  label?: ReactElement | string;
  shortLabel?: string;
  autoComplete?: string;
  value?: string | null;
  error?: boolean | undefined;
  helperText?: string | false | undefined;
  onChange?: any;
  sx?: any;
  otherProps?: any;
  inputProps?: any;
  type?: string;
  disabled?: boolean;
  onBlur?: () => void;
  focused?: boolean;
};

const CustomTextField = ({
  id,
  label,
  shortLabel,
  autoComplete,
  sx,
  value,
  onChange,
  error,
  helperText,
  otherProps,
  inputProps,
  type,
  disabled,
  onBlur,
  focused,
}: Props) => {
  const [focus, setFocus] = useState({ color: 'gray' });
  const [currentLabel, setCurrentLabel] = useState(shortLabel);

  useEffect(() => {
    if (shortLabel && value && label && typeof label === 'string') {
      if (value?.length > 0) setCurrentLabel(label);
    }
  }, [focus, value]);

  const onFocus = () => {
    setFocus({ color: theme.palette.primary.main });
    if (label && shortLabel && typeof label === 'string')
      setCurrentLabel(label);
  };

  const onBlurInside = () => {
    if (onBlur) onBlur();
    setFocus({ color: 'gray' });
    if (shortLabel) setCurrentLabel(shortLabel);
  };

  return (
    <TextField
      focused={focused}
      fullWidth
      disabled={disabled}
      margin="normal"
      id={id}
      type={type}
      name={id}
      label={shortLabel ? currentLabel : label}
      autoComplete={autoComplete ? autoComplete : id}
      inputProps={{ 'data-cy': `${id}` }}
      sx={[{ flex: 1 }, sx && { ...sx }]}
      value={value}
      onChange={onChange}
      {...otherProps}
      error={error}
      // @ts-ignore
      FormHelperTextProps={{ 'data-cy': id + '_error' }}
      helperText={helperText}
      InputLabelProps={{
        style: { fontWeight: '700', ...focus },
      }}
      InputProps={{ ...inputProps }}
      onFocus={() => onFocus()}
      onBlur={onBlurInside}
    />
  );
};

export default CustomTextField;
