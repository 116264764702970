import React, { ReactElement } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { BorderButton } from '@styles/styled';
import { confirmIsAdded } from '@redux/myExports/actions';
import { resetOrderAdded } from '@redux/order/actions';
import { getDashboardInfo } from '@redux/dashboard/actions';
import { useDispatch } from 'react-redux';

type PropsT = {
  close: () => void;
  title: string;
  message: string;
  Icon: ReactElement;
};

const SuccessInfo = ({ close, title, message, Icon }: PropsT) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{ pt: 2, pb: 2 }}>
      <Box>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item>{Icon}</Grid>
        </Grid>

        <Typography
          variant="h6"
          sx={{ textAlign: 'center', mt: 3, fontWeight: 'bold' }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: 'center',
            width: '70%',
            margin: '15px auto 0 ',
          }}
        >
          {message}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <BorderButton
            onClick={() => {
              dispatch(confirmIsAdded());
              dispatch(resetOrderAdded());
              dispatch(getDashboardInfo());
              close();
            }}
          >
            Powrót do strony głównej
          </BorderButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessInfo;
