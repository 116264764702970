import { Box, SvgIcon, Typography } from '@mui/material';
import search from '../../../public/icons/searching.svg';
import React from 'react';

const NoCompanies = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      p: 2,
    }}
  >
    <Typography textAlign="center">
      Przykro nam, ale nie posiadamy listy firm asenizacyjnych z Twojej gminy.
    </Typography>
    <Typography textAlign="center">
      Jeśli chcesz pomóc nam ją uzupełnić, zaproponuj wpisanie firmy pisząc na
    </Typography>
    <Typography
      color="#A8C300"
      fontWeight="700"
      textAlign="center"
      pt={2}
      fontSize={20}
    >
      pomoc@sciekipolskie.org
    </Typography>
    <SvgIcon
      component={search}
      viewBox="0 0 552.81023 515.45882"
      sx={{
        fontSize: '280px',
        margin: '10px auto 0 auto',
      }}
    />
  </Box>
);

export default NoCompanies;
