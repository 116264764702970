'use client';

import React from 'react';
import AddNewCard from '@components/elements/AddNewCard';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import process from 'process';
import Script from 'next/script';

const ENV = process.env.ENV_TYPE;
const payuLink =
  ENV === 'PROD'
    ? 'https://secure.payu.com/javascript/sdk'
    : 'https://secure.snd.payu.com/javascript/sdk';

const AddCardPopUp = () => {
  return (
    <AuthBackgroundLayout
      additionalStyle={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        justifyContent: 'center',
      }}
    >
      <Script type="text/javascript" src={payuLink} />

      <AddNewCard />
    </AuthBackgroundLayout>
  );
};

export default AddCardPopUp;
