import React, { useState } from 'react';
import { BorderButton, ErrorGradientButton } from '@styles/styled';
import { Grid, Box, Paper, Typography } from '@mui/material';
import { ExitIcon } from '@components/elements/ExitIcon';
import { styled } from '@mui/material/styles';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, UserTanksInterface } from '@redux/interfaces';
import { resetTankDelete } from '@redux/userCesspools/actions';

const Exit = styled(Grid)`
  &:hover {
    cursor: pointer;
  }
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: left;
  }
`;

type Props = {
  exit: () => void;
  deleteTank: () => void;
  tankName: string | null;
};

export const DeleteTankModal = ({ exit, deleteTank, tankName }: Props) => {
  const [isNotDeleted, setIsNotDeleted] = useState(true);

  const { tankDeleted, loading } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const dispatch = useDispatch();

  return (
    <AuthBackgroundLayout
      additionalStyle={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1001,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box>
        <Paper
          sx={{
            p: 3,
            borderRadius: '25px',
            boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          }}
        >
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={0} md={2}></Grid>
            <Grid
              item
              xs={isNotDeleted ? 8 : 12}
              sx={{ textAlign: 'center' }}
              color="black"
            >
              <Title variant="h3">Usuwanie zbiornika</Title>
            </Grid>
            <Exit
              item
              xs={4}
              md={2}
              sx={{ textAlign: 'right' }}
              onClick={() => {
                exit();
              }}
            >
              {isNotDeleted && <ExitIcon />}
            </Exit>
          </Grid>
          <Typography sx={{ textAlign: 'center', mt: 1, fontWeight: 'bold' }}>
            {tankDeleted === 'TO_DELETE'
              ? `Czy na pewno chcesz usunąć zbiornik: ${tankName}`
              : tankDeleted === 'DELETED'
              ? 'Zbiornik pomyślnie usunięty'
              : 'Nie udało się usunąć zbiornika'}
          </Typography>

          <Row>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <BorderButton
                sx={{ margin: '10px 0' }}
                onClick={() => {
                  exit();
                  dispatch(resetTankDelete());
                }}
                data-cy="exit_delete_credit_card"
                loading={loading}
              >
                {isNotDeleted ? 'Nie usuwaj' : 'Zamknij okno'}
              </BorderButton>
            </Box>
            {isNotDeleted && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <ErrorGradientButton
                  sx={{ margin: '10px 0' }}
                  data-cy="delete_credit_card"
                  onClick={() => {
                    setIsNotDeleted((prevState) => !prevState);
                    deleteTank();
                  }}
                  loading={loading}
                >
                  Usuń
                </ErrorGradientButton>
              </Box>
            )}
          </Row>
        </Paper>
      </Box>
    </AuthBackgroundLayout>
  );
};
