import React, { useRef, useState } from 'react';
import { Badge, Box, Grid, Typography } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import moment from 'moment';
import 'moment/locale/pl';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';

import Logo from '@components/elements/Logo';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardInterface, RootState } from '@redux/interfaces';
import { NotificationModal } from '@components/elements/NotificationModal';
import {
  getUnreadNotifications,
  setFirebaseToken,
  setReadNotifications,
} from '@redux/dashboard/actions';
import { types } from '@redux/dashboard/types';
import { firebaseCloudMessaging } from '@utils/webPush';
// @ts-ignore
import { isSafari } from '@firebase/util';

const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 905px) {
    justify-content: space-around;
  }
  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Hello = styled(Box)`
  margin: 0 0 0 20px;

  @media (max-width: 905px) {
    margin-left: 0;
    text-align: right;
  }
  @media (max-width: 660px) {
    margin-top: 10px;
  }
`;

const Title = styled(Typography)`
  @media (max-width: 660px) {
    text-align: center;
  }

  @media (max-width: 905px) {
    font-size: 2.5rem;
  }
`;

const Date = styled(Typography)`
  @media (max-width: 905px) {
    font-size: 0.8rem;
  }

  @media (max-width: 660px) {
    text-align: center;
  }
`;

const LogoWrapper = styled(Box)`
  padding-bottom: 30px;
  width: 80%;

  @media (min-width: 660px) {
    display: none;
  }
`;

const PushWrapper = styled(Box)`
  border: 2px solid ${theme.palette.primary.main};
  border-radius: 10px;
  padding: 10px;
  margin-right: 4px;
  cursor: pointer;

  @media (max-width: 1200px) {
    margin-right: 20px;
  }

  @media (max-width: 660px) {
    display: ${(p: { mobile: number }) => (p.mobile ? 'none' : 'block')};
    border: none;
    margin-left: 40px;
  }
`;

const TopBar = () => {
  const [notificationModal, setNotificationModal] = useState(false);
  const [readedId, setReadedId] = useState<Array<string>>([]);

  const { data, notifications, fcmToken } = useSelector<
    RootState,
    DashboardInterface
  >((state) => state.dashboard);

  const dispatch = useDispatch();

  const unreaded = data.unread_notifications;

  moment.locale('pl');
  moment.updateLocale('pl', {
    months: [
      'stycznia',
      'lutego',
      'marca',
      'kwietnia',
      'maja',
      'czerwca',
      'lipca',
      'sierpnia',
      'września',
      'października',
      'listopada',
      'grudnia',
    ],
  });

  const day = moment().format('dddd');
  let dateDay = moment().format('Do');
  const dateMonth = moment().format('MMMM');
  const year = moment().format('YYYY');

  dateDay = dateDay.substring(0, dateDay.length - 1);
  const ringRef = useRef(null);
  const secondRingRef = useRef(null);

  const clickOnRing = () => {
    if (
      'Notification' in window &&
      fcmToken.length === 0 &&
      Notification.permission === 'granted' &&
      isSafari()
    )
      getFcmToken();

    setNotificationModal((prevState) => !prevState);
    if (notifications.length === 0) {
      dispatch(getUnreadNotifications());
    }

    if (notificationModal) {
      setReaded();
    }
  };

  async function getFcmToken() {
    try {
      const token = await firebaseCloudMessaging.init();
      if (token) {
        dispatch(setFirebaseToken({ registration_token: token }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const arrayContains = (item: string, is_unread: boolean) => {
    if (is_unread) {
      const found = readedId.findIndex((val) => val === item);
      if (found === -1) {
        setReadedId((prevState) => [...prevState, item]);
      }
    }
  };

  const closeModal = () => {
    setNotificationModal((prevState) => !prevState);
    setReaded();
  };

  const setReaded = () => {
    if (readedId.length > 0) {
      dispatch(setReadNotifications({ id_list: readedId }));
    }
    dispatch({ type: types.CLEAR_READ_NOTIFICATIONS });
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <Grid container>
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Logo />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PushWrapper
              onClick={() => clickOnRing()}
              mobile={0}
              ref={secondRingRef}
            >
              <Badge badgeContent={unreaded} color="error" overlap="circular">
                <NotificationsNoneIcon
                  color="primary"
                  sx={{
                    fontSize: '35px',
                  }}
                />
              </Badge>
            </PushWrapper>
          </Grid>
        </Grid>
      </LogoWrapper>
      <Hello>
        <Title variant="h1">
          Dzień dobry
          {data?.first_name ? ` ${data.first_name}` : ''}
        </Title>
        <Date variant="subtitle1">
          Dziś jest{' '}
          <Box component="span" fontWeight="fontWeightBold">
            {day}, {dateDay} {dateMonth}
          </Box>{' '}
          {year} r.
        </Date>
      </Hello>
      <Box sx={{ position: 'relative' }}>
        <PushWrapper onClick={() => clickOnRing()} mobile={1} ref={ringRef}>
          <Badge badgeContent={unreaded} color="error" overlap="circular">
            <NotificationsNoneIcon
              color="primary"
              sx={{
                fontSize: '35px',
              }}
            />
          </Badge>
        </PushWrapper>

        {notificationModal && (
          <NotificationModal
            close={() => closeModal()}
            ringRef={ringRef}
            secondRingRef={secondRingRef}
            arrayContains={arrayContains}
          />
        )}
      </Box>
    </Wrapper>
  );
};

export default TopBar;
