'use client';

import * as yup from 'yup';

export default yup.object({
  file: yup
    .boolean()
    .required('Plik jest wymagany')
    .oneOf([true], 'Plik jest wymagany'),
});
