import React from 'react';
import { Box, Typography } from '@mui/material';
import { BorderButton, CardDescription, CardTitle, Bold } from '@styles/styled';
import CustomTextField from '@components/elements/CustomTextField';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { assignVirtualControlCode } from '@redux/userCesspools/actions';
import { RootState, UserTanksInterface } from '@redux/interfaces';

const AssignVirtualControl = () => {
  const {
    currentTank: { virtual_control_auth_code, virtual_control_commune_name },
    selectedTankId,
  } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const dispatch = useDispatch();

  const { handleSubmit, values, handleChange, touched, errors } = useFormik({
    initialValues: {
      virtualControlCode: virtual_control_auth_code
        ? virtual_control_auth_code
        : '',
    },
    onSubmit: ({ virtualControlCode }) => {
      dispatch(
        assignVirtualControlCode(
          { authcode: virtualControlCode },
          `${selectedTankId}/`
        )
      );
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <CardTitle sx={{ mb: 3 }}>Cyfrowa ankieta mieszkańca</CardTitle>
      <CardDescription sx={{ fontWeight: 'bold' }}>
        {!!virtual_control_auth_code
          ? `Twoja aplikacja została zautoryzowana przez urząd miasta/gminy ${virtual_control_commune_name}`
          : 'Nie masz czasu na wizytę w urzędzie?'}
      </CardDescription>
      {!!virtual_control_auth_code ? (
        <Typography fontSize="14px">
          Od teraz dane twojego zbiornika oraz informacje o zrealizowanych
          wywozach, fakturach i umowach będą przekazywane do urzędu. Jeśli
          chcesz dokonać korekty adresu twojego zbiornika skontaktuj się z
          pracownikiem urzędu odpowiadającym za gospodarowanie nieczystościami
          ciekłymi w twojej gminie/mieście.
        </Typography>
      ) : (
        <Typography fontSize="14px">
          Wprowadź kod autoryzujący wysłany przez gminę i weź udział w{' '}
          <Bold>cyfrowej ankiecie</Bold> . Następnie uzupełnij dane dotyczące
          zbiornika w szambo.online, które przekażemy w ramach cyfrowej ankiety
          do gminy {virtual_control_commune_name}. I masz to już z głowy. Jeżeli
          nie otrzymałeś kodu, możesz pozyskać go w urzędzie miasta/gminy, na
          terenie której znajduje się Twój zbiornik.
        </Typography>
      )}
      <CustomTextField
        id="virtualControlCode"
        label="KOD AUTORYZUJĄCY"
        value={values.virtualControlCode}
        onChange={handleChange}
        disabled={!!virtual_control_auth_code}
        error={touched.virtualControlCode && Boolean(errors.virtualControlCode)}
        helperText={touched.virtualControlCode && errors.virtualControlCode}
      />
      {!virtual_control_auth_code && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <BorderButton
            type="submit"
            loading={false}
            data-cy="sumbit_virtual_controle_code"
          >
            Zatwierdź kod
          </BorderButton>
        </Box>
      )}
    </form>
  );
};

export default AssignVirtualControl;
