import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { CalendarPicker, PickersDayProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/';

import plLocale from 'date-fns/locale/pl';

import { BorderButton, GradientButton, OrderRow } from '@styles/styled';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyDates, resetDates } from '@redux/order/actions';
import { OrderInterface, RootState } from '@redux/interfaces';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import useWindowDimensions from '@hooks/useWindowDimension';
import { levelEndFA, levelStartFA } from '../../../analytics/analyticFunctions';
import { types } from '../../../analytics/types';

const CalendarWrapper = styled(Box)`
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface CustomPickerDayProps extends PickersDayProps<any> {
  isAvailable: boolean;
  screenWidth: number;
}

const CustomPickerDay = styled(PickersDay)<CustomPickerDayProps>(
  ({ isAvailable, screenWidth }) => ({
    ...(isAvailable && {
      color: 'black',
      backgroundColor: '#EDEDED',

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(screenWidth <= 310 && {
      height: '30px',
      width: '30px',
    }),
  })
);

const StyledCalendarPicker = styled(CalendarPicker)`
  @media (max-width: 310px) {
    width: 240px;
  }
`;

type FirstStepType = {
  nextStep: () => void;
  prevStep: () => void;
  formik: any;
  appOrder: boolean;
};

const SecondStep = ({
  nextStep,
  prevStep,
  formik,
  appOrder,
}: FirstStepType) => {
  const [value, setValue] = useState<Date | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [availableDays, setAvailableDays] = useState([0]);
  const [lastSavedMonth, setLastSavedMonth] = useState<number>(0);
  const [lastSavedYear, setLastSavedYear] = useState<number>(0);

  const dispatch = useDispatch();

  const { width } = useWindowDimensions();

  const { dates, loading } = useSelector<RootState, OrderInterface>(
    (state) => state.order
  );

  const date = new Date();
  const hour = date.getHours();
  const minutes = date.getMinutes();

  const getStartDay = (readedMonth?: number, readedYear?: number) => {
    const isCurrentMonth =
      date.getMonth() + 1 === readedMonth && date.getFullYear() === readedYear;

    return readedMonth && readedYear && !isCurrentMonth
      ? 0
      : date.getDate() + 1;
  };

  const getMonthDays = (readedMonth?: number, readedYear?: number) => {
    const {
      driver: { id },
    } = formik.values;
    setAvailableDays([0]);

    const month = readedMonth || date.getMonth() + 1;
    const year = readedYear || date.getFullYear();
    const today = date.getDate();

    let monthLength = new Date(year, month, 0).getDate();

    if (id === -1) {
      if (month <= date.getMonth() + 2) {
        let startDay = getStartDay(readedMonth, readedYear);

        const maxDate = new Date();
        maxDate.setDate(today + 30);

        if (readedMonth && readedMonth !== date.getMonth() + 1) {
          monthLength = maxDate.getDate();
        }

        if (hour > 15 || (hour === 15 && minutes >= 30)) {
          if (date.getDay() === 5) {
            startDay = startDay + 3;
          } else {
            startDay = startDay + 1;
          }
        }

        for (let i = startDay; i <= monthLength; i++) {
          const day = `${year}-${addZero(month)}-${addZero(i)}`;

          if (![6, 0].includes(new Date(day).getDay())) {
            setAvailableDays((prevState) => [...prevState, i]);
          }
        }
      } else {
        setAvailableDays([0]);
      }
    } else {
      setAvailableDays([...Array(monthLength + 1)].map((_, i) => i));
    }
  };

  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      formik.setFieldValue('fullDate', newValue);
      findEstimatedCost(newValue);
      setShowError(false);
    }

    setValue(newValue);
  };

  const handleNextStep = () => {
    if (formik.values.fullDate !== null) {
      nextStep();
      levelEndFA(
        appOrder ? types.LEVEL_NAME_APP[1] : types.LEVEL_NAME_NUM[2],
        true
      );
      levelStartFA(
        appOrder ? types.LEVEL_NAME_APP[2] : types.LEVEL_NAME_NUM[3]
      );
    } else {
      setShowError(true);
    }
  };

  const testDatesObject = (year?: number, month?: number) => {
    const date = new Date();

    const selectedYear = year ? year : date.getFullYear();
    const selectedMonth = month ? month : date.getMonth() + 1;

    // eslint-disable-next-line guard-for-in
    for (const key in dates) {
      const separated = key.split('-');
      if (
        selectedMonth === Number(separated[1]) &&
        selectedYear === Number(separated[0])
      )
        setAvailableDays((prevState) => [...prevState, Number(separated[2])]);
    }
  };

  const addZero = (date: number) => {
    if (String(date).length === 1) {
      return `0${date}`;
    }

    return date;
  };

  const findEstimatedCost = (e: Date) => {
    const month = e.getMonth() + 1;
    const day = e.getDate();

    const date = `${e.getFullYear()}-${addZero(month)}-${addZero(day)}`;

    // eslint-disable-next-line guard-for-in
    for (const key in dates) {
      if (date === key) {
        formik.setFieldValue('estimatedCost', dates[key]);
      }
    }
  };

  useEffect(() => {
    const { selectedTank, driver } = formik.values;

    setValue(null);
    formik.setFieldValue('fullDate', null);

    if (!appOrder || driver.id === -1) {
      getMonthDays();
    } else {
      dispatch(getCompanyDates(`${selectedTank}/${driver.id}/`));
    }

    if (formik.values.fullDate !== null) {
      setValue(formik.values.fullDate);
    } else {
      setValue(new Date());
    }
  }, []);

  useEffect(() => {
    const selectedDate = formik.values.fullDate;
    const formattedDate = selectedDate ? new Date(selectedDate) : null;

    if (formattedDate) {
      testDatesObject(
        formattedDate?.getFullYear(),
        formattedDate?.getMonth() + 1
      );
    } else {
      testDatesObject();
    }
  }, [dates]);

  const monthOrYearChange = (e: any) => {
    const {
      driver: { id },
    } = formik.values;

    const readedMonth = e.getMonth() + 1;
    const readedYear = e.getFullYear();

    if (lastSavedMonth !== readedMonth) setLastSavedMonth(readedMonth);
    if (lastSavedYear !== readedYear) setLastSavedYear(readedYear);

    if (!appOrder || id === -1) {
      getMonthDays(readedMonth, readedYear);
    } else {
      if (lastSavedMonth !== readedMonth || lastSavedYear !== readedYear) {
        setAvailableDays([0]);
        testDatesObject(readedYear, readedMonth);
      }
    }
  };

  const handleBackButton = () => {
    levelEndFA(
      appOrder ? types.LEVEL_NAME_APP[1] : types.LEVEL_NAME_NUM[2],
      false
    );
    dispatch(resetDates());
    prevStep();
  };

  return (
    <CalendarWrapper>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={plLocale}
      >
        <StyledCalendarPicker
          date={value}
          onChange={(e: any) => {
            handleChange(e);
          }}
          disablePast={appOrder}
          onMonthChange={(e) => {
            monthOrYearChange(e);
          }}
          onYearChange={(e) => {
            monthOrYearChange(e);
          }}
          minDate={appOrder ? date : null}
          renderDay={(day: any, _value, DayComponentProps) => {
            const date = new Date(day);
            const isSelected =
              !DayComponentProps.outsideCurrentMonth &&
              availableDays.indexOf(date.getDate()) > 0;

            return (
              // @ts-ignore
              <CustomPickerDay
                {...DayComponentProps}
                isAvailable={isSelected}
                screenWidth={width}
                disabled={!isSelected}
              />
            );
          }}
        />
      </LocalizationProvider>
      {showError && (
        <Box>
          <Typography variant="subtitle2" color={theme.palette.error.main}>
            Wybierz datę aby przejść dalej
          </Typography>
        </Box>
      )}
      <OrderRow sx={{ width: '100%' }}>
        <BorderButton
          onClick={() => handleBackButton()}
          loading={loading}
          disabled={loading}
        >
          Wstecz
        </BorderButton>
        <GradientButton
          sx={{ margin: 0 }}
          onClick={() => handleNextStep()}
          loading={loading}
          disabled={loading}
        >
          {formik.values.driver?.id === -1 ? 'Wyślij zapytanie' : 'Dalej'}
        </GradientButton>
      </OrderRow>
    </CalendarWrapper>
  );
};

export default SecondStep;
