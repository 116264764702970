'use client';

import { NextPage } from 'next';
import DashboardLayout from '@components/layouts/DashboardLayout';
import PaymentLayout from '@components/templates/PaymentLayout/PaymentLayout';

const PaymentsPage: NextPage = () => (
  <DashboardLayout>
    <PaymentLayout />
  </DashboardLayout>
);

export default PaymentsPage;
