import { keyframes, css, styled } from '@mui/material/styles';

import MenuConst from '@constants/MenuConst';
import MenuItem from '@components/elements/MenuItem';
import { MenuType } from '@customTypes/menu/MenuType';
import FallowUs from '@components/elements/FallowUs';
import { Turn as Hamburger } from 'hamburger-react';
import React, { useContext, useEffect, useState } from 'react';
import Logo from '@components/elements/Logo';
import { Typography } from '@mui/material';
import { WarningModal } from '@components/elements/CancelAddTankModal/WarningModal';
import { WarningModalContext } from '../../../context/MySewageContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardInterface,
  RootState,
  UserTanksInterface,
} from '@redux/interfaces';
import { deleteFirebaseToken } from '@redux/dashboard/actions';
import { logoutAction } from '@redux/auth/actions';
// @ts-ignore
import { isSafari } from '@firebase/util';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeNewTank } from '@redux/userCesspools/actions';

const Wrapper = styled('div')`
  margin: 0 0 0 20px;
  overflow: ${(p: Props) => (p.isopen ? 'hidden' : 'auto')};
  display: flex;
  flex-direction: column;
  z-index: 999;
  width: 200px;

  @media (max-width: 905px) and (min-width: 660px) {
    background-color: #fff;
    box-shadow: 4px 4px 8px gray;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    margin: 35px 0 0 0;
    border-radius: 20px;
    width: 150px;
  }
  @media (max-width: 660px) {
    position: absolute;
    width: 70%;
    margin: 0;
    display: ${(p: Props) => (p.isopen ? 'flex' : 'none')};
    top: 55%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  @media (max-height: 480px) {
    top: 58%;
    right: 50%;
    transform: translate(50%, -50%);
  }
`;

const Divider = styled('div')`
  height: 2px;
  width: 80%;
  margin: 2rem auto;
  transform: translate(172, 245);
  background: linear-gradient(
    90deg,
    rgba(248, 249, 250, 1) 0%,
    rgb(229, 229, 229) 50%,
    rgb(248, 249, 250) 100%
  );

  @media (max-width: 905px) and (min-width: 660px) {
    display: none;
  }

  @media (max-width: 660px) {
    background: linear-gradient(to right, #a8cc1f, #5b9627);
    width: 100%;
    display: ${(p: Props) => (p.isopen ? 'block' : 'none')};
  }

  @media (max-height: 480px) {
    margin: 10px 0 0 0;
  }
`;

type Props = {
  isopen?: number;
};

const Grow = keyframes`
  0% {height: 100px; width: 100px;}
  100% {height: 100%; width: 100%;}
`;

const GrowForward = keyframes`
  0% {height: 100%; width: 100%;}
  100% {height: 100px; width: 100px;}
`;

const ButtonBackground = styled('div')`
  background-image: ${(p: Props) =>
    p.isopen
      ? 'linear-gradient(to bottom, white, white)'
      : 'linear-gradient(to bottom, #a8cc1f, #5b9627)'};
  position: fixed;
  padding: 15px 25px 25px 25px;
  right: 0;
  bottom: 0;
  z-index: 1001;

  animation: ${(p: Props) =>
    p.isopen
      ? css`
          ${Grow} .1s ease-in
        `
      : css`
          ${GrowForward} .1s ease-in
        `};

  border-radius: ${(p: Props) => (p.isopen ? ' 0' : ' 50px 50px 0 50px')};
  height: ${(p: Props) => (p.isopen ? '120%' : '100px')};
  width: ${(p: Props) => (p.isopen ? '100%' : '100px')};

  @media (min-width: 660px) {
    position: unset;
    background-image: unset;
    height: auto;
    width: auto;
  }
`;

const Box = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: flex-end;
  z-index: 100;
  @media (min-width: 660px) {
    display: none;
  }
`;

const LogoWrapper = styled('div')`
  @media (min-width: 660px) {
    display: none;
  }
  @media (max-height: 480px) {
    transform: scale(0.7);
  }
`;

const FollowWrap = styled('div')`
  @media (max-width: 905px) {
    display: none;
  }
`;

const HamburgerLabel = styled(Typography)`
  color: ${(p: Props) => (p.isopen ? 'black' : 'white')};
  line-height: 0.75rem;
  font-weight: 600;
  text-align: left;
`;

type MenuItemT = { title: string; path: string };

const SideBar = () => {
  const [isOpen, setOpen] = useState(false);
  const [warningModalVisible, setWarningModalVisible] =
    useState<boolean>(false);
  const [savedItem, setSavedItem] = useState<MenuItemT | null>(null);

  const {
    tankLocationDifferent,
    tankDataDifferent,
    tankNameDifferent,
    billingFormDifferent,
  } = useContext(WarningModalContext);

  const { fcmToken } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );

  const { selectedTankId, clientProperty } = useSelector<
    RootState,
    UserTanksInterface
  >((state) => state.userCesspools);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChangePage = ({
    title,
    path,
  }: {
    title: string;
    path: string;
  }) => {
    if (title === 'Wyloguj') {
      if (!isSafari()) {
        dispatch(deleteFirebaseToken({ registration_token: fcmToken }));
      }
      dispatch(logoutAction());
    }
    navigate(path);
  };

  const handleMenuPress = (item: MenuItemT) => {
    document.body.style.removeProperty('overflow-y');
    if (
      (selectedTankId === 0 &&
        clientProperty.length >= 1 &&
        pathname === '/my-sewage') ||
      tankLocationDifferent ||
      tankDataDifferent ||
      tankNameDifferent ||
      billingFormDifferent
    ) {
      setWarningModalVisible(true);
      setSavedItem(item);
    } else {
      handleChangePage(item);
    }
  };

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [isOpen]);

  return (
    <>
      {warningModalVisible && (
        <WarningModal
          warningMessage={
            billingFormDifferent ? (
              'Masz niezapisane zmiany, czy na pewno chcesz je anulować?'
            ) : (
              <>
                Masz niezapisane zmiany, czy na pewno chcesz
                {selectedTankId === 0
                  ? ' anulować dodawanie zbiornika?'
                  : ' je anulować?'}
              </>
            )
          }
          backButtonText={
            billingFormDifferent ? (
              'Wróć do edycji'
            ) : (
              <>Wróć do {selectedTankId === 0 ? 'dodawania' : 'zbiornika'}</>
            )
          }
          cancelButtonText={
            billingFormDifferent
              ? 'Anuluj zmiany'
              : selectedTankId === 0
              ? 'Anuluj dodawanie'
              : 'Anuluj zmiany'
          }
          exit={() => setWarningModalVisible(false)}
          cancelAdd={() => {
            setWarningModalVisible(false);
            dispatch(removeNewTank());
            savedItem && handleChangePage(savedItem);
          }}
        />
      )}
      <ButtonBackground isopen={isOpen ? 1 : 0}>
        <Wrapper isopen={isOpen ? 1 : 0}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Divider isopen={isOpen ? 1 : 0} />
          {MenuConst.map((item: MenuType) => (
            <div key={item.title}>
              <MenuItem item={item} handleMenuPress={handleMenuPress} />
            </div>
          ))}
          <FollowWrap>
            <FallowUs />
          </FollowWrap>
        </Wrapper>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: `${isOpen ? 'none' : 'center'}`,
          }}
        >
          <Box>
            <Hamburger
              size={46}
              color={isOpen ? 'black' : 'white'}
              rounded
              toggled={isOpen}
              toggle={() => {
                setOpen((prevState) => !prevState);
              }}
            />
          </Box>
          <HamburgerLabel
            variant="subtitle2"
            color="white"
            isopen={isOpen ? 1 : 0}
          >
            MENU
          </HamburgerLabel>
        </Box>
      </ButtonBackground>
    </>
  );
};

export default SideBar;
