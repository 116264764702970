import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import lastOrder from '../../../../public/images/lastOrder.svg';

import LastOrdersGrid from '@components/elements/LastOrdersGrid';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActiveOrdersInterface,
  DashboardInterface,
  InvoiceUnpaidInterface,
  RootState,
} from '@redux/interfaces';
import { setSelectedTankIdAction } from '@redux/userCesspools/actions';
import { DashboardContext } from '../../../context/DashboardContext';

type Props = {
  data: boolean;
};

const CustomPaper = styled(Paper)`
  display: flex;
  flex-direction: ${(p: Props) => (p.data ? 'column' : 'row')};
  align-items: center;

  @media (max-width: 660px) {
    min-height: auto;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

const CustomTypography = styled(Typography)`
  padding: ${(p: Props) => (p.data ? '10px' : '10px 0 0 0')};
  font-weight: 700;

  @media (max-width: 750px) {
    text-align: center;
  }
`;

const LeftSide = styled(Box)`
  position: absolute;
  width: ${(p: { data: [any] }) => (p.data.length >= 1 ? '90%' : '50%')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 720px) {
    position: unset;
    width: auto;
  }
`;

const CenteredText = styled(Typography)`
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  @media (max-width: 750px) {
    text-align: center;
    margin-top: 10px;
  }
`;

const ImageWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 300px) {
    max-width: 200px;
  }
  @media (max-width: 720px) {
    justify-content: center;
  }
`;

const LastOrder = () => {
  const [clientOrders, setClientOrders] = useState<any>([]);
  const [selectedInitValue, setSelectedInitValue] = useState<boolean>(false);

  const { handleCancelOrderLayoutVisible } = useContext(DashboardContext);

  const dispatch = useDispatch();

  const { data, currentTank } = useSelector<RootState, DashboardInterface>(
    (state) => state.dashboard
  );

  const { invoice_unpaid, active_orders } = data;

  const setUnpaidTank = (invoices: Array<InvoiceUnpaidInterface>) => {
    const id = invoices[0].client_property_id;

    dispatch(setSelectedTankIdAction(id));
    setSelectedInitValue(true);
  };

  const setOrdersList = () => {
    let unpaid: Array<InvoiceUnpaidInterface> = [];
    let active: Array<ActiveOrdersInterface> = [];

    if (invoice_unpaid.length >= 1 && !selectedInitValue) {
      setUnpaidTank(invoice_unpaid);
    }

    invoice_unpaid.map((item: InvoiceUnpaidInterface) => {
      if (item.client_property_id === currentTank.id) {
        unpaid = [item];
      } else {
        unpaid = [...unpaid];
      }
    });

    active_orders.map((item: ActiveOrdersInterface) => {
      if (item.client_property_id === currentTank.id) {
        active = [item];
      } else {
        active = [...active];
      }
    });

    setClientOrders([...active, ...unpaid]);
  };

  useEffect(() => {
    setOrdersList();
  }, [data, currentTank]);

  useEffect(() => {
    if (invoice_unpaid.length !== 0) {
      setOrdersList();
    }
  }, [currentTank, data]);

  const isPaymentDeadline = () => {
    if (clientOrders.length >= 1) {
      return 'payment_deadline' in clientOrders[0]
        ? 'Zamówienie oczekujące na płatność'
        : 'Zamówienie oczekujące na realizację';
    }
    return 'Zamówienie oczekujące na realizację';
  };

  return (
    <Container maxWidth="md" disableGutters sx={{ borderRadius: '20px' }}>
      <CustomPaper
        data={clientOrders.length >= 1}
        sx={{
          p: 2,
          borderRadius: '25px',
          boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          height: '100%',
        }}
      >
        <LeftSide data={clientOrders}>
          <Box>
            <CustomTypography variant="h6" data={clientOrders.length >= 1}>
              {isPaymentDeadline()}
            </CustomTypography>
            {clientOrders.length >= 1 ? (
              <LastOrdersGrid
                data={clientOrders[0]}
                cancelOrder={() => handleCancelOrderLayoutVisible(true)}
              />
            ) : (
              <CenteredText
                variant="subtitle1"
                sx={{ fontWeight: 'bold', mt: 5, mb: 1 }}
              >
                Brak danych na temat najbliższych wywozów.
              </CenteredText>
            )}
          </Box>
        </LeftSide>
        <ImageWrapper>
          {clientOrders.length === 0 && (
            <img
              src={lastOrder}
              alt="Człowiek z roślinką czeka na odbiór szamba"
            />
          )}
        </ImageWrapper>
      </CustomPaper>
    </Container>
  );
};

export default LastOrder;
