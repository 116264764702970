import { TankDataInterface } from '@forms/TankDataForm/interface';

export const ossfTankTypes = [
  { name: 'Z drenażem rozsączającym', id: 'DRNG' },
  { name: 'Z filtrem piaskowym', id: 'SAND' },
  { name: 'Gruntowo-roślinna', id: 'SOIL' },
  { name: 'Z osadem czynnym', id: 'SLDG' },
  { name: 'Ze złożem biologicznym', id: 'BIOL' },
];

export const cessTankTypes = [
  { name: 'Zalewany betonem', id: 'CNC_P' },
  { name: 'Poliestrowy', id: 'PLSTR' },
  { name: 'Kręgi betonowe', id: 'CNC_R' },
  { name: 'Metalowy', id: 'MTL' },
  { name: 'Inny ', id: 'OTHR' },
];

export const residents = [
  { name: '0', id: 0 },
  { name: '1', id: 1 },
  { name: '2', id: 2 },
  { name: '3', id: 3 },
  { name: '4', id: 4 },
  { name: '5', id: 5 },
  { name: '6', id: 6 },
  { name: '7', id: 7 },
  { name: '8', id: 8 },
  { name: '9', id: 9 },
  { name: '10', id: 10 },
  { name: '11', id: 11 },
  { name: '12', id: 12 },
];

export const sewageTypes = [
  { id: 'CESS', name: 'bezodpływowy' },
  { id: 'OSSF', name: 'POŚ' },
];

export const initialValues: TankDataInterface = {
  tankType: '',
  tankModel: '',
  capacity: '',
  dailyCapacity: '',
  emptyingFrequency: '',
  launchDate: null,
  residentsAmount: '',
  sewageType: '',
  waterSource: false,
  registeredPersons: '',
  cesspoolTankInstallationDate: '',
  sewageSystem: false,
  sewageSystemConnectionDate: '',
};
