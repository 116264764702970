import React from 'react';
import { styled } from '@mui/material/styles';

const Offline = styled('div')`
  background-color: #f44336;
  min-height: 30px;
  text-align: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
`;

const DesktopText = styled('p')`
  display: none;

  @media (min-width: 660px) {
    display: inline-block;
    font-weight: bold;
    color: white;
  }
`;

const MobileText = styled('p')`
  display: none;

  @media (max-width: 660px) {
    display: inline-block;
    font-weight: bold;
    color: white;
  }
`;

const IsBackendOffline = () => (
  <Offline>
    <DesktopText>
      Szambo.Online jest obecnie niedostępne. Spróbuj ponownie za chwilę.{' '}
    </DesktopText>
    <MobileText>Aplikacja działa w trybie offline</MobileText>
  </Offline>
);

export default IsBackendOffline;
