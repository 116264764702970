'use client';

import * as yup from 'yup';

export default yup.object({
  email: yup.string().when('isTokenSaved', {
    is: false,
    then: yup
      .string()
      .email('Podaj poprawny adres e-mail.')
      .required('Adres e-mail jest wymagany.'),
  }),
  password: yup.string().when('isTokenSaved', {
    is: false,
    then: yup.string().required('Hasło jest wymagane.'),
  }),
  securePin: yup.string().when('rememberMe', {
    is: true,
    then: yup
      .string()
      .matches(/^[0-9]*$/, 'Pin musi składać się z samych cyfr')
      .min(4, 'Wprowadź minimum 4 cyfry')
      .max(8, 'Pin może mieć maksymalnie 8 cyfr')
      .required(
        'Wprowadź PIN od 4 do 8 cyfr szybkiego logowania i kliknij w "Zaloguj się", by zapisać.'
      ),
  }),
});
