import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { confirmIsAdded } from '@redux/myExports/actions';
import ModalHeader from '@components/elements/ModalHeader';
import UniversalModalLayout from '@components/layouts/UniversalModalLayout';
import { Description } from '@components/modules/DropZoneModals/styled';
import AddNewDropRecordForm from '@forms/AddNewDropRecordForm';
import { DropRecordModalsT } from '@customTypes/types';
import { MyExportsInterface, RootState } from '@redux/interfaces';

const AddNewDropRecord = ({ closeModal, itemToEdit }: DropRecordModalsT) => {
  const dispatch = useDispatch();

  const { isAdded } = useSelector<RootState, MyExportsInterface>(
    (state) => state.myExports
  );

  return (
    <UniversalModalLayout>
      <ModalHeader
        title={itemToEdit ? 'Edycja wywozu' : 'Dodawanie wywozu'}
        handleExit={() => {
          closeModal();
          dispatch(confirmIsAdded());
        }}
      />
      {!isAdded && <Description>Podaj dane z faktury</Description>}
      <AddNewDropRecordForm closeModal={closeModal} itemToEdit={itemToEdit} />
    </UniversalModalLayout>
  );
};

export default AddNewDropRecord;
