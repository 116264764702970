import React from 'react';
import { Box } from '@mui/material';
import TitleBar from '@components/elements/TitleBar';
import DropsGrid from '@components/elements/DropsGrid';

const DropsGridLayout = () => (
  <Box>
    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
      <TitleBar>Moje wywozy</TitleBar>
    </Box>
    <DropsGrid />
  </Box>
);

export default DropsGridLayout;
