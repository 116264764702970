import SemiTransparentBackground from '@components/layouts/SemiTransparentBackground';
import OrderLayout from '@components/layouts/OrderLayout';
import React, { useContext } from 'react';
import { DashboardContext } from '../../../context/DashboardContext';
import { CancelPickupModal } from '@components/elements/LastOrder/Modals';

const DashboardModals = () => {
  const {
    orderLayoutVisible,
    cancelOrderLayoutVisible,
    handleOrderLayoutVisible,
    handleCancelOrderLayoutVisible,
  } = useContext(DashboardContext);

  return (
    <>
      {orderLayoutVisible && (
        <SemiTransparentBackground>
          <OrderLayout
            close={() => {
              document.body.style.removeProperty('overflow-y');
              handleOrderLayoutVisible(false);
            }}
          />
        </SemiTransparentBackground>
      )}
      {cancelOrderLayoutVisible && (
        <CancelPickupModal
          cancelOrder={() => handleCancelOrderLayoutVisible(false)}
        />
      )}
    </>
  );
};

export default DashboardModals;
