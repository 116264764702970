import SingleLine from '@components/elements/LastOrdersGrid/SingleLine';
import {
  ActiveOrdersInterface,
  InvoiceUnpaidInterface,
} from '@redux/interfaces';
import Lottie from 'lottie-react';
import waiting from '../../../../public/lottie/waiting.json';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';

const LottieWrapper = styled('div')`
  position: relative;
  bottom: 150px;
  left: 0;
  z-index: 998;

  @media (min-width: 715px) {
    display: none;
  }
`;

const TextOnMobile = styled('p')`
  display: none;
  text-align: center;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  font-size: 14px;
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  left: 35px;
  right: 35px;
  z-index: 999;
  color: ${theme.palette.secondary.main};

  @media all and (display-mode: standalone) and (max-width: 660px) {
    display: block;
  }
`;

const LastOrdersGrid = ({
  data,
  cancelOrder,
}: {
  data: ActiveOrdersInterface | InvoiceUnpaidInterface;
  cancelOrder: () => void;
}) => {
  return (
    <div>
      <SingleLine first payment={data && 'payment_deadline' in data} />
      <SingleLine data={data} cancelOrder={cancelOrder} />
      {data?.payment_in_progress && (
        <TextOnMobile>Oczekujemy na przetworzenie płatności</TextOnMobile>
      )}

      <LottieWrapper>
        <Lottie
          animationData={waiting}
          loop={true}
          style={{ height: '400px' }}
        />
      </LottieWrapper>
    </div>
  );
};

export default LastOrdersGrid;
