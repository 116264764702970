import React, { useState, useMemo, useEffect } from 'react';
import useMeasure from 'react-use-measure';
import { useTransition, a } from '@react-spring/web';
import styles from './styles.module.css';

import useMedia from '@utils/useMedia';
import OrderExport from '@components/elements/OrderExport';
import LastOrder from '@components/elements/LastOrder';
import SewageCounter from '@components/elements/ProgressBarComponent';
import useWindowDimensions from '@hooks/useWindowDimension';

const data = [
  {
    id: '0',
    height: 600,
    scaledSize: 300,
    component: <OrderExport />,
  },
  {
    id: '1',
    height: 600,
    scaledSize: 450,
    component: <LastOrder />,
  },
  {
    id: '2',
    height: 1200,
    scaledSize: 400,
    component: <SewageCounter />,
  },
];

const DashboardComponents = ({ componentsOrder }: { componentsOrder: any }) => {
  const columns = useMedia(
    ['(min-width: 1200px)', '(min-width: 50px)'],
    [2, 1],
    1
  );

  const [ref, { width }] = useMeasure();

  const { width: screenWidth } = useWindowDimensions();

  const [items, set] = useState(data);

  const [heights, gridItems] = useMemo(() => {
    const heights = new Array(columns).fill(0);
    const gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights));
      const x = (width / columns) * column;
      const height =
        screenWidth <= 720 && child?.scaledSize !== undefined
          ? child.scaledSize * 2
          : child.height;
      const y = (heights[column] += height / 2) - height / 2;
      return {
        ...child,
        x,
        y,
        width: width / columns,
        height: child.height / 2,
      };
    });
    return [heights, gridItems];
  }, [columns, items, width]);

  type ItemT = {
    x: number;
    y: number;
    width: number;
    height: number;
    scaledSize: number;
  };

  const transitions = useTransition(gridItems, {
    key: (item: { id: string; height: number }) => item.id,
    from: ({ x, y, width, height }: ItemT) => ({
      x,
      y,
      width,
      height,
      opacity: 0,
    }),
    enter: ({ x, y, width, height }: ItemT) => ({
      x,
      y,
      width,
      height,
      opacity: 1,
    }),
    update: ({ x, y, width, height }: ItemT) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });

  useEffect(() => {
    let output = componentsOrder.map((i: number) => {
      return data[i];
    });
    output = output.filter(
      (item: undefined | { id: string }) => item !== undefined
    );
    set(output);
  }, [componentsOrder]);

  return (
    <div
      ref={ref}
      className={styles.list}
      style={{ height: Math.max(...heights) }}
    >
      {transitions((style, item) => (
        <a.div
          style={{
            ...style,
            height: `${
              screenWidth <= 720 && item?.scaledSize !== undefined
                ? item.scaledSize
                : item.height
            }px`,
          }}
          key={item.id}
        >
          {item.component}
        </a.div>
      ))}
    </div>
  );
};

export default DashboardComponents;
