import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import SingleDriver from '@components/modules/OrderStepper/SingleDriver';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import {
  OrderInterface,
  PanoramaInterface,
  RootState,
} from '@redux/interfaces';
import NoCompanies from '@components/elements/NoCompanies';
import { SmallerGradientButton } from '@styles/styled';

const FindPickupWrapper = styled(Box)`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 0;
  background-color: #f7f8f9;
`;

const DriversWrapper = styled(Box)`
  background-color: transparent;
`;

const Wrapper = styled(Box)`
  width: 90%;
  margin: 20px auto;
  min-height: 50vh;

  @media (max-width: 660px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`;

const TextSeparator = styled('p')`
  font-size: 24px;
  text-align: center;
  padding: 12px 0;
  border-top: 2px solid #b3c6c0;
  border-bottom: 2px solid #b3c6c0;
`;

type SecondStepType = {
  checkType: (arg1: 'NUM' | 'APP', arg2?: PanoramaInterface) => void;
};

const FirstStep = ({ checkType }: SecondStepType) => {
  const {
    companies: { has_permission_to_find_company, online_companies, panorama },
    loading,
    askOrderLoading,
    error,
    askOrder,
  } = useSelector<RootState, OrderInterface>((state) => state.order);

  return (
    <Wrapper>
      {!error &&
        !loading &&
        !askOrderLoading &&
        online_companies.length > 0 && (
          <DriversWrapper>
            {online_companies.map((item) => (
              <SingleDriver
                key={`${item.id}/${item.name}`}
                item={item}
                nextStep={checkType}
                appClient={true}
              />
            ))}
          </DriversWrapper>
        )}

      {!error &&
        !loading &&
        !askOrderLoading &&
        (online_companies.length >= 1 || panorama.length >= 1) && (
          <TextSeparator>Firmy dostępne w gminie</TextSeparator>
        )}

      {!loading && !askOrderLoading && panorama.length > 0 && (
        <DriversWrapper>
          {has_permission_to_find_company ? (
            <FindPickupWrapper>
              <SmallerGradientButton
                onClick={() =>
                  checkType('APP', {
                    id: -1,
                    name: 'Wielu operatorów',
                    phone_nb: null,
                  })
                }
              >
                Znajdź dla mnie wywóz
              </SmallerGradientButton>
            </FindPickupWrapper>
          ) : null}
          {panorama.map((item) => (
            <SingleDriver
              key={`${item.id}/${item.name}`}
              item={item}
              nextStep={checkType}
              appClient={false}
            />
          ))}
        </DriversWrapper>
      )}
      {!error &&
        !loading &&
        !askOrderLoading &&
        !askOrder &&
        panorama.length <= 0 && <NoCompanies />}
      {(loading || askOrderLoading) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Typography>Wystąpił błąd podczas wczytywania danych</Typography>
      )}
    </Wrapper>
  );
};

export default FirstStep;
