import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const PaymentTitle = styled(Typography)`
  background-color: #e2eaef;
  padding: 20px;
  border-radius: 15px;
  font-weight: 700;
  width: 100%;
  margin-right: 20px;

  @media (max-width: 905px) {
    border-radius: 25px 0 0 25px;
    width: 70%;
    text-align: left;
    margin: 0;
  }
  @media (max-width: 660px) {
    width: 100%;
    border-radius: 0;
    text-align: center;
  }
`;

type Props = {
  children: React.ReactNode;
};

const TitleBar: React.FC<Props> = ({ children }) => (
  <PaymentTitle variant="h4">{children}</PaymentTitle>
);

export default TitleBar;
