'use client';

import { NextPage } from 'next';
import VerifyMailLayout from '@components/layouts/VerifyMail/VerifyMailLayout';
import VerifyMailTemplate from '@components/templates/VerifyMailTemplate/VerifyMailTemplate';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';

const FirstVerify: NextPage = () => (
  <AuthBackgroundLayout>
    <VerifyMailLayout>
      <VerifyMailTemplate />
    </VerifyMailLayout>
  </AuthBackgroundLayout>
);

export default FirstVerify;
