import { Autocomplete, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import CustomTextField from '@components/elements/CustomTextField';
import { useSelector } from 'react-redux';
import { RootState, TerytInterface } from '@redux/interfaces';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';

const Box = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const OptionName = styled('p')`
  margin: 0;
  padding: 0;
`;

const OptionDescription = styled(OptionName)`
  font-size: 12px;
`;

type Props = {
  readonly options:
    | Array<{
        name: string;
        code?: string;
        id?: number;
        locality_sym?: string;
        commune_description?: string;
      }>
    | [];

  id: string;
  disabled?: boolean;
  label: any;
  autoComplete?: string;
  error?: boolean | undefined;
  helperText?: any;
  callOnChange?: any;
  inputOnChange?: any;
  sx?: any;
  loadOnOpen?: any;
  inptValue?: string | undefined;
  value?: any;
  onClear?: () => void;
  fullWidth?: boolean;
  forcePopUp?: boolean;
  noOptionsText?: string;
};

const CustomAutocomplete = ({
  options,
  label,
  loadOnOpen,
  inputOnChange,
  callOnChange,
  error,
  helperText,
  inptValue,
  disabled,
  id,
  sx,
  value,
  onClear,
  fullWidth,
  forcePopUp,
  noOptionsText,
}: Props) => {
  const [open, setOpen] = useState(false);

  const { terytLoading } = useSelector<RootState, TerytInterface>(
    (state) => state.teryt
  );

  if (disabled === undefined) disabled = false;

  return (
    <Autocomplete
      data-cy={id}
      sx={{ flex: 2 }}
      fullWidth={fullWidth}
      options={options}
      disabled={disabled}
      open={open}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => (option?.name ? option.name : '')}
      loading={terytLoading}
      inputValue={inptValue}
      onChange={(e: any, newValue: any) => {
        callOnChange(newValue);
      }}
      forcePopupIcon={forcePopUp}
      onOpen={() => {
        setOpen(true);
        if (loadOnOpen && options !== undefined && options !== null) {
          if (options.length <= 0) loadOnOpen();
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      clearIcon={<ClearIcon onClick={onClear && onClear} />}
      noOptionsText={noOptionsText ? noOptionsText : 'Brak opcji'}
      loadingText="Wczytuję..."
      renderInput={(params) => (
        <CustomTextField
          id={id}
          otherProps={{ ...params }}
          label={label}
          error={error}
          onChange={inputOnChange}
          helperText={helperText}
          autoComplete="none"
          inputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {terytLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={sx}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Box>
            <OptionName key={`${option.name}-${option.id}`}>
              {option.name}
            </OptionName>
            {option?.commune_description && (
              <OptionDescription>
                {option.commune_description}
              </OptionDescription>
            )}
          </Box>
        </li>
      )}
    />
  );
};

export default CustomAutocomplete;
