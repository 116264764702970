'use client';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material/';
import { Button, Typography } from '@mui/material';
import theme from '@styles/theme';

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
`;

export const Column = styled(Box)`
  flex: 1;
  text-align: left;
`;

export const MobileColumn = styled(Column)`
  @media (max-width: 660px) {
    display: none;
  }
`;

export const CancelButton = styled(Button)`
  background-color: #e85858;
  border-radius: 25px;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 10px;
  font-weight: bold;
  z-index: 999;

  &:hover {
    background-color: #e85858;
    color: #ffffff;
  }
`;

export const TextLabel = styled(Typography)<{ first: number; center?: number }>`
  font-weight: ${(p) => (p.first ? 700 : 'normal')};
  font-size: 14px;
  text-align: center;
`;

export const PayForButton = styled(CancelButton)<{ waitingPayment?: boolean }>`
  z-index: 999;

  background-color: ${({ waitingPayment }) =>
    waitingPayment ? '#f1c40f' : theme.palette.primary.main};

  &:hover {
    background-color: ${theme.palette.primary.dark};
  }
`;
