'use client';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

type Props = {
  noWrap?: boolean;
};

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 1500px) {
    flex-direction: column;
    gap: 0;
  }

  @media (max-width: 660px) {
    flex-direction: ${(p: Props) => (p.noWrap ? 'row' : 'column')};
    justify-content: center;
  }
`;

export const CityWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 905px) {
    flex-direction: row-reverse;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const SecondRow = styled(Row)`
  margin-bottom: 16px;

  @media (max-width: 905px) {
    flex-direction: row;
    gap: 30px;
  }
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 0;
  }
`;

export const NumbersWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;
  flex: 2;
`;
