import { styled } from '@mui/material/styles';
import { Box, Select, Typography } from '@mui/material';

export const SelectedTankTitle = styled(Typography)`
  font-size: 14px;

  @media (max-width: 660px) {
    font-size: 20px;
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 400px) {
    font-size: 16px;
    margin-top: 10px;
  }
`;

export const TankSelectorWrapper = styled(Box)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 660px) {
    max-width: 60%;
  }
`;

export const CustomSelect = styled(Select)`
  margin-left: 8px;
  width: unset;

  @media (max-width: 660px) {
    width: 150px;
  }
`;
