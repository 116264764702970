import { createContext, ReactNode, useState } from 'react';
import { DashboardContextI } from './interface';

const defaultValue = {
  handleOrderLayoutVisible: (status: boolean) => {},
  orderLayoutVisible: false,
  handleCancelOrderLayoutVisible: (status: boolean) => {},
  cancelOrderLayoutVisible: false,
};

const DashboardContext = createContext<DashboardContextI>(defaultValue);

const DashboardProvider = ({ children }: { children: ReactNode }) => {
  const [orderLayoutVisible, setOrderLayoutVisible] = useState(false);
  const [cancelOrderLayoutVisible, setCancelOrderLayoutVisible] =
    useState(false);

  const handleOrderLayoutVisible = (status: boolean) =>
    setOrderLayoutVisible(status);

  const handleCancelOrderLayoutVisible = (status: boolean) =>
    setCancelOrderLayoutVisible(status);

  const storeObject = {
    orderLayoutVisible,
    handleOrderLayoutVisible,
    cancelOrderLayoutVisible,
    handleCancelOrderLayoutVisible,
  };

  return (
    <DashboardContext.Provider value={storeObject}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };
