import React, { useState } from 'react';
import { Box } from '@mui/material';

import YourCreditCard from '@components/elements/YourCreditCard';
import TitleBar from '@components/elements/TitleBar';
import DeleteCardPopUp from '@components/modules/DeleteCardPopUp';

import { LayoutWrapper } from '@styles/styled';
import {
  CreditCardInterface,
  PaymentInterface,
  RootState,
} from '@redux/interfaces';
import Script from 'next/script';
import process from 'process';
import { globalNavigate } from '../../../navigation/globalHistory';
import { actionDone } from '@redux/payment/actions';
import { useDispatch, useSelector } from 'react-redux';

const ENV = process.env.ENV_TYPE;
const payuLink =
  ENV === 'PROD'
    ? 'https://secure.payu.com/javascript/sdk'
    : 'https://secure.snd.payu.com/javascript/sdk';

const PaymentLayout = () => {
  const [deleteCardVisible, setDeleteCardVisible] = useState<boolean>(false);
  const [cardToDelete, setCardToDelete] = useState<CreditCardInterface | null>(
    null
  );

  const dispatch = useDispatch();

  const { actionNeeded } = useSelector<RootState, PaymentInterface>(
    (state) => state.payment
  );

  const handleDeleteVisible = (card: CreditCardInterface) => {
    setCardToDelete(card);
    setDeleteCardVisible((prevState) => !prevState);
  };

  return (
    <Box>
      <Script type="text/javascript" src={payuLink} />

      {deleteCardVisible && (
        <DeleteCardPopUp
          exit={() => setDeleteCardVisible((prevState) => !prevState)}
          cardToDelete={cardToDelete}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
        }}
      >
        <TitleBar>Dane płatności</TitleBar>
      </Box>
      <LayoutWrapper>
        <Box sx={{ flex: 0.5 }}>
          {!deleteCardVisible && (
            <YourCreditCard
              handleDeleteVisible={(card: CreditCardInterface) =>
                handleDeleteVisible(card)
              }
              openAddModal={() => {
                globalNavigate('payments/add-new-card', { replace: true });
                if (actionNeeded) dispatch(actionDone());
              }}
            />
          )}
        </Box>
      </LayoutWrapper>
    </Box>
  );
};

export default PaymentLayout;
