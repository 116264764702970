import {
  Wrapper,
  Column,
  TextLabel,
  PayForButton,
  CancelButton,
  MobileColumn,
} from './styled';
import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { orderIdToDelete, setInvoiceToPay } from '@redux/order/actions';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import {
  ActiveOrdersInterface,
  InvoiceUnpaidInterface,
} from '@redux/interfaces';
import { globalNavigate } from '../../../navigation/globalHistory';

const SingleLine = ({
  data,
  first,
  cancelOrder,
  payment,
}: {
  payment?: boolean;
  data?: ActiveOrdersInterface | InvoiceUnpaidInterface;
  first?: boolean;
  cancelOrder?: () => void;
}) => {
  const [cost, setCost] = useState(0);
  const [date, setDate] = useState('');
  const [dateTitle, setDateTitle] = useState('Data realizacji');
  const [isCancelPossible, setIsCancelPossible] = useState(false);

  const dispatch = useDispatch();

  const checkDate = () => {
    if (data && 'date' in data) {
      const orderDate = new Date(data.date);
      const today = new Date();

      orderDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      setIsCancelPossible(orderDate.getTime() <= today.getTime());
    }
  };

  useEffect(() => {
    if (data) {
      // Koszt usługi
      if ('invoice_value' in data) {
        const invoiceValue = data.has_first_order_discount
          ? data.invoice_value - 10
          : data.invoice_value;
        setCost(invoiceValue);
      } else if ('estimated_cost' in data) {
        setCost(data.estimated_cost);
      }

      // data realizacji/deadline opłaty
      if ('date' in data) {
        setDate(data.date);
      } else if ('payment_deadline' in data) {
        setDate(data.payment_deadline);
      }

      checkDate();
    }
  }, [data]);

  useEffect(() => {
    if (payment) {
      setDateTitle('Termin płatności');
    } else {
      setDateTitle('Data realizacji');
    }
  }, [payment]);

  const cancel = () => (
    <CancelButton
      disabled={isCancelPossible}
      onClick={() => {
        if (cancelOrder && data) {
          cancelOrder();
          dispatch(orderIdToDelete(data.id));
        }
      }}
    >
      Anuluj
    </CancelButton>
  );

  const payForCurrentOrder = () => {
    return (
      <Tooltip
        title={
          data?.payment_in_progress
            ? 'Oczekujemy na przetworzenie płatności'
            : ''
        }
        placement="bottom"
      >
        <span>
          <PayForButton
            disabled={data?.payment_in_progress}
            waitingPayment={data?.payment_in_progress}
            onClick={() => {
              if (data) {
                globalNavigate('dashboard/pay-for-order', { replace: true });
                dispatch(setInvoiceToPay(data));
              }
            }}
            sx={{
              '&.Mui-disabled': {
                color: 'white',
              },
            }}
          >
            Opłać
            {data?.payment_in_progress && (
              <>
                ... <HourglassTopRoundedIcon />
              </>
            )}
          </PayForButton>
        </span>
      </Tooltip>
    );
  };

  const returnButton = () => {
    if (data) {
      if ('invoice_value' in data) {
        return payForCurrentOrder();
      } else if ('estimated_cost' in data) {
        return cancel();
      }
    }
  };

  return (
    <Wrapper>
      <Column sx={{ flex: 1.2 }}>
        <TextLabel first={first ? 1 : 0}>{first ? dateTitle : date}</TextLabel>
      </Column>
      <MobileColumn sx={{ flex: 2 }}>
        <TextLabel first={first ? 1 : 0}>
          {first ? 'Operator' : data?.operator}
        </TextLabel>
      </MobileColumn>
      <Column sx={{ flex: 1.4 }}>
        <TextLabel first={first ? 1 : 0}>
          {first ? 'Koszt usługi' : `${cost}zł`}
        </TextLabel>
      </Column>
      <Column sx={{ flex: 0.8 }}>
        {first ? (
          <TextLabel first={first ? 1 : 0}>Opcje</TextLabel>
        ) : (
          returnButton()
        )}
      </Column>
    </Wrapper>
  );
};

export default SingleLine;
