import Image from 'next/image';
import { Link } from 'react-router-dom';

import logo from '../../../../public/images/logo.svg';

const Logo = () => (
  <Link to="/dashboard">
    <Image src={logo} alt={'szambo.online'} width={230} height={66.24} />
  </Link>
);

export default Logo;
