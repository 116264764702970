'use client';

import { types } from './types';

export const getPosId = () => {
  // odbieramy pos_id z którego będziemy generować token
  return {
    type: types.GET_POS_ID_REQUEST,
  };
};

export const addCreditCard = (data: { card_token: string }) => {
  // Wysyłamy token zwrócony nam przez payu
  return {
    type: types.ADD_CREDIT_CARD_REQUEST,
    data,
  };
};

export const deleteCreditCard = (id: string) => {
  return {
    type: types.DELETE_CREDIT_CARD_REQUEST,
    id,
  };
};

export const resetIsPaid = () => {
  return {
    type: types.INVOICE_PAID_RESET,
  };
};

export const setAddCardRedirectedOff = () => {
  return {
    type: types.ADD_CARD_REDIRECTED_OFF,
  };
};

export const getCreditCard = () => {
  return {
    type: types.GET_CREDIT_CARD_REQUEST,
  };
};

export const actionDone = () => {
  return {
    type: types.ACTION_DONE,
  };
};

export const deleteCardDone = () => {
  return {
    type: types.DELETE_CARD_DONE,
  };
};

export const invoicePaidSuccessfully = () => {
  return {
    type: types.INVOICE_PAID_SUCCESSFULLY,
  };
};

export const payForInvoice = (id: string, data: { card_id: string | null }) => {
  return {
    type: types.PAY_FOR_INVOICE_REQUEST,
    id,
    data,
  };
};
