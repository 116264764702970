import { Box } from '@mui/material';
import { BorderButton } from '@styles/styled';
import React, { useState } from 'react';
import { firebaseCloudMessaging } from '@utils/webPush';
import { setFirebaseToken } from '@redux/dashboard/actions';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';

const GreenText = styled('p')`
  color: #a8c300;
  display: inline;
`;

const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  margin: 0 auto;
`;

const Text = styled('p')`
  padding-bottom: 20px;
  text-align: center;
  font-weight: 600;
`;

const SmallerBorderButton = styled(BorderButton)`
  font-size: 14px;
`;

const RegisterPush = () => {
  const [isAccessGranted, setIsAccessGranted] = useState(false);

  const dispatch = useDispatch();

  const { permission } = Notification;

  async function getFcmToken() {
    try {
      const token = await firebaseCloudMessaging.init();
      if (token) {
        dispatch(setFirebaseToken({ registration_token: token }));
      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleSetNotification = () => {
    if (permission === 'default') {
      Notification.requestPermission().then(function (permission) {
        if (permission === 'granted') {
          setIsAccessGranted(true);
        }
      });
    }
    if (permission === 'granted') {
      getFcmToken();
    }
  };

  return (
    <Container>
      {!isAccessGranted ? (
        <Text>
          Włącz powiadomienia a informacje o nowych wiadomościach z{' '}
          <GreenText>Szambo.Online</GreenText> wyświetlimy w przeglądarce.
        </Text>
      ) : (
        <Text>
          To już ostatni krok - zarejestruj swoją przeglądarkę w usłudze
          notyfikacji <GreenText>Szambo.Online</GreenText> (wystarczy kliknąć).
        </Text>
      )}

      <SmallerBorderButton onClick={() => handleSetNotification()}>
        {!isAccessGranted
          ? 'Włącz powiadomienia'
          : 'Zarejestruj usługę notyfikacji'}
      </SmallerBorderButton>
    </Container>
  );
};

export default RegisterPush;
