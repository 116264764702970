import { BorderButton, GradientButton } from '@styles/styled';
import React, { useContext, useEffect } from 'react';
import { Box, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { WarningModalContext } from '../../../context/MySewageContext';
import {
  getProtocolDetails,
  sendProtocolComment,
} from '@redux/userCesspools/actions';
import { ClientData, RootState, UserTanksInterface } from '@redux/interfaces';
import { toast } from 'react-toastify';

export const Bold = styled('span')`
  font-weight: ${(p: { disabled?: boolean }) => (p.disabled ? 'unset' : 800)};
`;

const ProtocolDetails = () => {
  const { setControlDetailsModalVisible, setControlAdditionalInfo } =
    useContext(WarningModalContext);

  const {
    billingData: { full_name },
  } = useSelector<RootState, ClientData>((state) => state.contact);

  const { selectedTankId, protocolDetails, tankDataValues, loading } =
    useSelector<RootState, UserTanksInterface>((state) => state.userCesspools);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(getProtocolDetails(`${selectedTankId}/`));
  };

  const { handleSubmit, values, handleChange, handleBlur, touched, errors } =
    useFormik({
      initialValues: { additionalInfo: '' },
      onSubmit: (values) => {
        if (!full_name) {
          toast.error(
            'Aby móc zgłosić dane do kontroli wymagane jest uzupełnione imię i nazwisko w danych billingowych.'
          );
        } else if (tankDataValues) {
          const {
            residentsAmount,
            registeredPersons,
            cesspoolTankInstallationDate,
            capacity,
            dailyCapacity,
            tankModel,
            tankType,
            emptyingFrequency,
            launchDate,
          } = tankDataValues;
          const allRequiredFieldsPresent =
            residentsAmount !== null &&
            registeredPersons !== null &&
            capacity &&
            tankModel &&
            cesspoolTankInstallationDate;
          const posTankConditionsMet =
            tankType === 'OSSF' &&
            dailyCapacity.length &&
            emptyingFrequency &&
            launchDate;

          if (
            allRequiredFieldsPresent &&
            (posTankConditionsMet || tankType !== 'OSSF')
          ) {
            dispatch(
              sendProtocolComment(`${selectedTankId}/`, {
                additional_info: values.additionalInfo,
              })
            );
          } else {
            toast.error(
              'Aby móc zgłosić dane do kontroli wymagane jest uzupełnienie wszystkich danych technicznych zbiornika.'
            );
          }
        }
      },
    });

  useEffect(() => {
    if (protocolDetails) {
      setControlDetailsModalVisible(true);
      document.body.style.overflowY = 'hidden';
    }
  }, [protocolDetails]);

  return (
    <form onSubmit={handleSubmit}>
      <Typography fontSize="14px" fontWeight="bold" mt={3}>
        Twój urząd miasta/gminy prosi Cię o przekazanie danych do protokołu
        kontroli Twojego zbiornika.
      </Typography>
      <Typography fontSize="14px" mt={2}>
        Wypełnij dane techniczne zbiornika, dodaj skan umowy oraz dowody
        uiszczania opłat, sprawdź kompletność formularza i <Bold>wyślij</Bold>{' '}
        informacje do urzędu gminy przez aplikację Szambo.online. Po
        zweryfikowaniu informacji przez urzędnika otrzymasz gotowy protokół
        wykonanej kontroli, o czym poinformujemy Cię w aplikacji.
      </Typography>
      <Typography fontSize="14px" mt={2}>
        Uzupełnieniem do protokołu kontroli są dodatkowe dokumenty, które możesz
        przekazać za pośrednictwem aplikacji Szambo.online:
      </Typography>
      <ul>
        <li>
          <Typography fontSize="14px">
            dowody uiszczania opłat za wywóz (kwity, faktury itd.) - dodasz je w
            zakładce{' '}
            <Bold
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/my-exports')}
            >
              &quot;Moje wywozy&quot;
            </Bold>
          </Typography>
        </li>
        <li>
          <Typography fontSize="14px">
            umowy na wywóz nieczystości ciekłych - skan umowy załączysz w
            zakładce{' '}
            <a href="#agreements">
              <Bold>&quot;Mój zbiornik&quot;</Bold> w sekcji{' '}
              <Bold>Umowy z firmami asenizacyjnymi</Bold>
            </a>
          </Typography>
        </li>
      </ul>
      <Box>
        <Typography fontSize="14px">
          Dodatkowe informacje dla urzędnika:
        </Typography>
        <TextField
          multiline
          placeholder="Wpisz treść wiadomości do 500 znaków..."
          minRows={5}
          sx={{ mt: 2 }}
          fullWidth
          value={values.additionalInfo}
          onChange={(e) => {
            handleChange(e);
            setControlAdditionalInfo(e.target.value);
          }}
          onBlur={handleBlur}
          error={touched.additionalInfo && Boolean(errors.additionalInfo)}
          helperText={touched.additionalInfo && errors.additionalInfo}
          inputProps={{ maxlength: 500, 'data-cy': 'additionalInfo' }}
          name="additionalInfo"
          id="additionalInfo"
          InputProps={{
            endAdornment: (
              <Typography color="gray">
                {values.additionalInfo.length}/500
              </Typography>
            ),
          }}
        />
      </Box>
      <Box mt={2}>
        <Link href="https://szambo.online/ewidencja/" target="_blank">
          Dowiedz się więcej o Cyfrowej kontroli mieszkańca &gt;&gt;&gt;
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          gap: '30px',
        }}
      >
        <GradientButton type="submit" loading={loading}>
          Wyślij
        </GradientButton>
        <BorderButton type="button" onClick={handleOpen} loading={loading}>
          Podgląd
        </BorderButton>
      </Box>
    </form>
  );
};

export default ProtocolDetails;
