'use client';

import React from 'react';
import DashboardLayout from '@components/layouts/DashboardLayout';
import TitleBar from '@components/elements/TitleBar';
import { CustomPaper } from '@styles/styled';
import Image from 'next/image';
import logo from '../../../public/images/logo.svg';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import FallowUs from '@components/elements/FallowUs';

import getConfig from 'next/config';
import PolicyLinks from '@components/elements/PolicyLinks';
const { publicRuntimeConfig } = getConfig();

const Bolder = styled('span')`
  font-weight: 700;
`;

const DisabledOnDesktop = styled('div')`
  @media (min-width: 600px) {
    display: none;
  }
`;

const Help = () => {
  return (
    <DashboardLayout>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <TitleBar>Pomoc</TitleBar>
      </Box>

      <CustomPaper sx={{ p: 2, mb: 2 }}>
        <Image src={logo} alt={'logo'} height={70} width={200} />
        <Typography sx={{ pt: 2, borderTop: '2px solid gray' }}>
          <Bolder>Adres:</Bolder> ul. Feliksa Nowowiejskiego 39, 61-733 Poznań
        </Typography>
        <Typography sx={{ pt: 1 }}>
          <Bolder>Email:</Bolder> pomoc@sciekipolskie.org
        </Typography>
        <Typography sx={{ pt: 1 }}>
          <Bolder>Wersja:</Bolder> {publicRuntimeConfig.version}
        </Typography>
      </CustomPaper>
      <DisabledOnDesktop>
        <FallowUs />
        <PolicyLinks />
      </DisabledOnDesktop>
    </DashboardLayout>
  );
};

export default Help;
