'use client';

import * as yup from 'yup';

const nipValidation = (nip: string) => {
  if (typeof nip !== 'string') return false;
  if (nip === '0000000000') return false;

  nip = nip.replace(/[\ \-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export default yup.object({
  companyName: yup.string().when('clientType', {
    is: 'T_COM',
    then: yup
      .string()
      // @ts-ignore
      .when(['isInOrder', 'clientType'], (isInOrder, clientType, schema) => {
        return schema
          .required(
            isInOrder
              ? 'Wprowadź nazwę firmy, dla której zamawiany jest wywóz.'
              : 'To pole nie może być puste.'
          )
          .min(5, 'Miniamlna długość to 5 znaków.')
          .max(55, 'Maksymalna długość to 55 znaków.');
      }),
    otherwise: yup.string().nullable(),
  }),
  nip: yup.mixed().when('clientType', {
    is: 'T_COM',
    then: yup
      .mixed()
      .test(
        'nip',
        'Wprowadź prawidłowy NIP',
        (value) => nipValidation(value) === true
      ),
    otherwise: yup.string().nullable(),
  }),
  flatNumber: yup.string().max(10, 'Maksymalna długość to 10 znaków'),
  name: yup
    .string()
    // @ts-ignore
    .when(['isInOrder', 'clientType'], (isInOrder, clientType, schema) => {
      const newSchema = schema
        .required(
          isInOrder ? 'Wprowadź imię zamawiającego' : 'Imię nie może być puste.'
        )
        .min(3, 'Imię nie może być krótsze niż 3 znaki')
        .max(25, 'Imię nie może być dłuższe niż 25 znaków');

      if (clientType === 'T_PER' || (clientType === 'T_COM' && isInOrder)) {
        return newSchema;
      } else {
        return schema.nullable();
      }
    }),
  surname: yup
    .string()
    // @ts-ignore
    .when(['isInOrder', 'clientType'], (isInOrder, clientType, schema) => {
      const newSchema = schema
        .required(
          isInOrder
            ? 'Wprowadź nazwisko zamawiającego'
            : 'Nazwisko nie może być puste.'
        )
        .min(3, 'Nazwisko nie może być krótsze niż 3 znaki')
        .max(25, 'Nazwisko nie może być dłuższe niż 25 znaków');

      if (clientType === 'T_PER' || (clientType === 'T_COM' && isInOrder)) {
        return newSchema;
      } else {
        return schema.nullable();
      }
    }),
  phoneNumber: yup.string().when('isInOrder', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('Numer telefonu jest wymagany')
      .matches(/^[0-9]+$/, 'Numer telefonu musi składać się z cyfr')
      .min(9, 'Numer telefonu jest za krótki')
      .max(11, 'Numer telefonu jest za długi'),
    otherwise: yup.string().nullable(),
  }),
});
