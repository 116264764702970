import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { ErrorGradientButton } from '@styles/styled';
import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { WarningModalContext } from '../../../context/MySewageContext';
import { useDispatch, useSelector } from 'react-redux';
import { ClientData, RootState, UserTanksInterface } from '@redux/interfaces';
import { getProtocolDetails } from '@redux/userCesspools/actions';
import { CenterContainer } from '@components/layouts/OrderLayout';
import { Bold } from '@components/modules/VirtualControl/ProtocolDetails';
import { formatNumbers } from '@utils/formatNumbers';
import { getFormatedDate } from '@utils/formatDate';

const ModalContainer = styled(CenterContainer)`
  left: 0;
  z-index: 1002;
  top: 0;
  position: fixed;
  min-height: 100%;
`;

const TextWithMargin = styled(Typography)`
  margin-top: 16px;
`;

const Row = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const ControlDetailsModal = () => {
  const [isCess, setIsCess] = useState(false);

  const { setControlDetailsModalVisible, controlAdditionalInfo } =
    useContext(WarningModalContext);

  const dispatch = useDispatch();

  const {
    billingData: { full_name },
  } = useSelector<RootState, ClientData>((state) => state.contact);

  const { protocolDetails, selectedTankId, loading, tankDataValues } =
    useSelector<RootState, UserTanksInterface>((state) => state.userCesspools);

  const handleClose = () => {
    setControlDetailsModalVisible(false);
    document.body.style.removeProperty('overflow-y');
  };

  useEffect(() => {
    dispatch(getProtocolDetails(`${selectedTankId}/`));
  }, []);

  useEffect(() => {
    setIsCess(tankDataValues?.tankType === 'CESS');
  }, [tankDataValues]);

  return (
    <ModalContainer maxWidth={false}>
      <Container maxWidth="md">
        <Paper
          sx={{
            p: 3,
            borderRadius: '25px',
            boxShadow: '0px 16px 24px rgba(10,60,70,0.16)',
          }}
        >
          <Typography fontWeight="bolder" textAlign="center">
            Deklaracja właściciela nieruchomości wyposażonej w zbiornik
            bezodpływowy lub przydomową oczyszczalnię ścieków
          </Typography>
          {loading && protocolDetails !== null ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress sx={{ marginTop: 3, marginBottom: 3 }} />
            </Box>
          ) : (
            <Box>
              <Typography mt={5}>
                Imię, nazwisko, właściciela/ domownika/ dzierżawcy:
              </Typography>
              <TextWithMargin fontWeight="800">{full_name}</TextWithMargin>
              <TextWithMargin>Zakres przedmiotowy kontroli:</TextWithMargin>
              <TextWithMargin>
                a. stwierdzenie czy właściciel nieruchomości posiada zawartą
                umowę z przedsiębiorcą świadczącym usługi opróżniania zbiorników
                bezodpływowych i/lub osadników w instalacjach przydomowych
                oczyszczalni ścieków oraz transportu nieczystości ciekłych
              </TextWithMargin>
              <TextWithMargin>
                b. udokumentowanie wykonania obowiązku pozbywania się zebranych
                na terenie nieruchomości nieczystości ciekłych może nastąpić
                przez okazanie rachunków, faktur VAT, paragonów, dowodów zapłaty
                za usługi lub innych dowodów wystawionych każdorazowo po
                wykonaniu usługi przez przedsiębiorcę posiadającego zezwolenie
                na prowadzenie działalności w zakresie opróżniania zbiorników
                bezodpływowych i/lub osadników w instalacjach przydomowych
                oczyszczalni ścieków oraz transportu nieczystości ciekłych.
              </TextWithMargin>
              <TextWithMargin>Opis stanu faktycznego:</TextWithMargin>
              <TextWithMargin>
                Liczba osób zamieszkujących nieruchomość:{' '}
                {tankDataValues?.residentsAmount}
              </TextWithMargin>
              <TextWithMargin>
                Liczba osób zameldowanych na nieruchomości:{' '}
                {tankDataValues?.registeredPersons}
              </TextWithMargin>
              <TextWithMargin>
                Nieczystości ciekłe gromadzone są w:
              </TextWithMargin>
              <TextWithMargin
                sx={{ textDecoration: isCess ? 'none' : 'line-through' }}
              >
                a. Zbiorniku bezodpływowym zamontowanym w{' '}
                {isCess && tankDataValues?.cesspoolTankInstallationDate && (
                  <Bold>
                    {new Date(
                      tankDataValues.cesspoolTankInstallationDate
                    ).getFullYear()}
                  </Bold>
                )}{' '}
                roku o pojemności{' '}
                <Bold disabled={!isCess}>
                  {isCess && formatNumbers(tankDataValues?.capacity)}
                </Bold>{' '}
                m³.
              </TextWithMargin>
              <TextWithMargin
                sx={{ textDecoration: !isCess ? 'none' : 'line-through' }}
              >
                b. Przydomowej oczyszczalni ścieków o pojemności{' '}
                <Bold disabled={isCess}>
                  {!isCess && formatNumbers(tankDataValues?.capacity)}
                </Bold>{' '}
                m³, zgłoszonej do eksploatacji w dniu{' '}
                <Bold>
                  {!isCess && getFormatedDate(tankDataValues?.launchDate, true)}
                </Bold>{' '}
                o przepustowości{' '}
                <Bold>{!isCess && tankDataValues?.dailyCapacity}</Bold> m³ na
                dobę.
              </TextWithMargin>
              <TextWithMargin>
                c. Nieruchomość jest podłączona do sieci kanalizacyjnej od dnia:{' '}
                <Bold>
                  {tankDataValues?.sewageSystemConnectionDate &&
                  tankDataValues?.sewageSystem
                    ? getFormatedDate(
                        tankDataValues.sewageSystemConnectionDate,
                        true
                      )
                    : 'NIE DOTYCZY'}
                </Bold>
              </TextWithMargin>
              <TextWithMargin>
                d. Nieruchomość ma możliwość podłączenia do sieci
                kanalizacyjnej:{' '}
                <Bold>{tankDataValues?.sewageSystem ? 'TAK' : 'NIE'}</Bold>
              </TextWithMargin>
              <TextWithMargin>
                e. Nieruchomość posiada własne ujęcie wody:{' '}
                <Bold>{tankDataValues?.waterSource ? 'TAK' : 'NIE'}</Bold>
              </TextWithMargin>
              <TextWithMargin>
                Załączono dodatkowe dokumenty kontroli:
              </TextWithMargin>
              <TextWithMargin>
                a. Umowę zawartą z przedsiębiorcą (nazwa):
                {(protocolDetails?.agreements?.length ?? 0) > 0 ? (
                  protocolDetails?.agreements.map((item) => (
                    <p key={item.company + '-' + item.conclusion_date}>
                      <Bold>{item.company}</Bold> zawarta od dnia{' '}
                      <Bold>{getFormatedDate(item.conclusion_date, true)}</Bold>{' '}
                      na czas nieokreślony
                    </p>
                  ))
                ) : (
                  <Bold>BRAK UMÓW</Bold>
                )}
              </TextWithMargin>
              <TextWithMargin>
                b. Dowody uiszczania opłat z ostatnich 12 miesięcy w postaci
                (liczba){' '}
                <Bold>{protocolDetails?.received_sewage.received_count}</Bold>{' '}
                rachunków / faktur / innych dowodów, na łączną sumę wywiezionych
                nieczystości ciekłych (liczba){' '}
                <Bold>
                  {formatNumbers(
                    protocolDetails?.received_sewage.received_amount
                  )}{' '}
                </Bold>
                m³.
              </TextWithMargin>
              <TextWithMargin>
                *Załączniki (zdjęcia/inne dokumenty mające związek z przedmiotem
                kontroli) zostaną dołączone do protokołu poprzez umieszczenie
                ich w koncie aplikacji szambo.online dla kontrolowanej
                nieruchomości.
              </TextWithMargin>

              <Box>
                <TextWithMargin>
                  Dodatkowe informacje dla urzędnika:
                </TextWithMargin>
                <TextWithMargin>{controlAdditionalInfo}</TextWithMargin>
              </Box>
            </Box>
          )}
          <Row>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ErrorGradientButton
                sx={{ margin: '10px 0' }}
                data-cy="delete_credit_card"
                onClick={handleClose}
              >
                Zamknij
              </ErrorGradientButton>
            </Box>
          </Row>
        </Paper>
      </Container>
    </ModalContainer>
  );
};

export default ControlDetailsModal;
