import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Switch } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import CustomTextField from '@components/elements/CustomTextField';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';

import { CardTitle, GradientButton } from '@styles/styled';
import validator from '@forms/TankDataForm/validator';
import theme from '@styles/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTankDataValues,
  updateTankData,
} from '@redux/userCesspools/actions';
import {
  RootState,
  UserTanksInterface,
  SewageTypeInterface,
} from '@redux/interfaces';
import { WarningModalContext } from '../../context/MySewageContext';
import CustomSelect from '@components/elements/CustomSelect';
import DateSelector from '@components/modules/DropZoneModals/DateSelector';
import { prepareTankDataObject } from '@utils/prepareTankDataObject';
import {
  cessTankTypes,
  initialValues,
  ossfTankTypes,
  residents,
  sewageTypes,
} from '@forms/TankDataForm/consts';
import Skeletons from '@forms/TankDataForm/Skeletons';
import {
  CalendarWrapper,
  ResidentsForm,
  Row,
} from '@forms/TankDataForm/styled';

type Props = {
  error: boolean;
};

const CustomFormHelperText = styled(FormHelperText)`
  color: ${(p: Props) => (p.error ? theme.palette.error.main : '#6F706F')};
`;

const RedText = styled('p')`
  text-align: center;
  color: red;
  font-size: 14px;
`;

type PropsT = {
  tankHaveActiveOrder: boolean;
};

const TankDataForm = ({ tankHaveActiveOrder }: PropsT) => {
  const [isPos, setIsPos] = useState<boolean>(false);
  const [showSkeletons, setShowSkeletons] = useState<boolean>(true);

  const {
    currentTank,
    selectedTankId,
    addingNewTank,
    tankDetailsLoading,
    loading,
  } = useSelector<RootState, UserTanksInterface>(
    (state) => state.userCesspools
  );

  const { cesspool_name } = currentTank;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: (values) => {
      dispatch(
        updateTankData(prepareTankDataObject(values), `${selectedTankId}/`)
      );
    },
  });

  const dispatch = useDispatch();
  const { handleTankDataDifferent } = useContext(WarningModalContext);

  const setFieldValues = (baseValue: any, type: string) => {
    const newValues = {
      tankType: baseValue.sewage_type,
      tankModel: baseValue.tank_type === null ? '' : baseValue.tank_type,
      capacity: baseValue.cesspool_size === 0 ? '' : baseValue.cesspool_size,
      residentsAmount:
        baseValue.resident_count !== null ? baseValue.resident_count : '',
      sewageType: baseValue.sewage_group,
      dailyCapacity: '',
      emptyingFrequency: '',
      launchDate: '',
      waterSource: baseValue.water_source,
      sewageSystem: baseValue.sewage_system,
      registeredPersons: baseValue.registered_people_count,
      cesspoolTankInstallationDate: baseValue.cesspool_tank_installation_date,
      sewageSystemConnectionDate: baseValue.sewage_system_connection_date,
    };

    if (type === 'OSSF') {
      const correctDate = baseValue.launch_date
        ? baseValue.launch_date.replaceAll('-', '/')
        : '';

      const newOssf = {
        ...newValues,
        dailyCapacity:
          baseValue.flow_capacity !== null
            ? String(baseValue.flow_capacity)
            : '',
        emptyingFrequency:
          baseValue.frequency !== null ? baseValue.frequency : '',
        launchDate: correctDate,
      };

      formik.resetForm({ values: newOssf });
      dispatch(setTankDataValues(newOssf));
    } else {
      formik.resetForm({ values: newValues });
      dispatch(setTankDataValues(newValues));
    }
  };

  const renderTankOptions = () => {
    let tanks;
    const value = formik.values.tankType;

    if (value === 'OSSF') {
      tanks = ossfTankTypes;
    } else {
      tanks = cessTankTypes;
    }

    return tanks.map((tank: SewageTypeInterface) => (
      <MenuItem key={tank.id} value={tank.id} data-cy={`${tank.name}-option`}>
        {tank.name}
      </MenuItem>
    ));
  };

  useEffect(() => {
    if (currentTank && currentTank?.ossffeatures?.sewage_group) {
      setIsPos(true);
      setFieldValues(currentTank.ossffeatures, 'OSSF');
    } else if (
      (currentTank && currentTank?.cesspoolfeatures?.sewage_group) ||
      selectedTankId === 0
    ) {
      setFieldValues(currentTank.cesspoolfeatures, 'CESS');
      setIsPos(false);
    }
  }, [currentTank, selectedTankId]);

  useEffect(() => {
    const {
      values: { tankType },
      dirty,
    } = formik;
    setIsPos(tankType === 'OSSF');
    handleTankDataDifferent(dirty);
  }, [formik]);

  useEffect(() => {
    setShowSkeletons(
      tankDetailsLoading && !addingNewTank && cesspool_name.length === 0
    );
  }, [tankDetailsLoading, addingNewTank, cesspool_name]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <CardTitle sx={{ mb: 3 }}>Dane techniczne zbiornika</CardTitle>
      <Skeletons loading={showSkeletons} isPos={isPos}>
        {/* rodzaj zbiornika i typ zbiornika*/}
        <Row>
          <FormControl fullWidth margin="normal">
            <CustomSelect
              id="tankType"
              label="RODZAJ ZBIORNIKA *"
              disabled={selectedTankId === 0}
              value={formik.values.tankType}
              onChange={(e: any) => {
                formik.handleChange(e);
                formik.setFieldValue('tankModel', '');
              }}
              error={formik.errors.tankType}
            >
              {sewageTypes.map((tank: SewageTypeInterface) => (
                <MenuItem
                  key={tank.id}
                  value={tank.id}
                  data-cy={`${tank.name}-option`}
                >
                  {tank.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <CustomSelect
              id="tankModel"
              label="TYP ZBIORNIKA *"
              disabled={selectedTankId === 0}
              value={formik.values.tankModel}
              onChange={formik.handleChange}
              error={formik.errors.tankModel}
            >
              {renderTankOptions()}
            </CustomSelect>
          </FormControl>
        </Row>
        {/* Pojemność i liczba mieszkańców*/}
        <Row>
          <CustomTextField
            id="capacity"
            label="POJEMNOŚĆ *"
            value={formik.values.capacity}
            disabled={selectedTankId === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              formik.handleChange(e);
            }}
            error={formik.touched.capacity && Boolean(formik.errors.capacity)}
            helperText={formik.touched.capacity && formik.errors.capacity}
            inputProps={{
              endAdornment: (
                <>
                  <p>m³</p>
                </>
              ),
            }}
            sx={{ flex: 1 }}
          />
          <ResidentsForm margin="normal">
            <CustomSelect
              label="LICZBA MIESZKAŃCÓW P... *"
              value={formik.values.residentsAmount}
              error={formik.errors.residentsAmount}
              onChange={formik.handleChange}
              disabled={selectedTankId === 0}
              id="residentsAmount"
            >
              {residents &&
                residents.map(
                  (resident: {
                    id: number | string;
                    name: string | number;
                  }) => (
                    <MenuItem key={resident.id} value={resident.id}>
                      {resident.name}
                    </MenuItem>
                  )
                )}
            </CustomSelect>
          </ResidentsForm>
        </Row>
        {/* Przepustowość i częstotliwość*/}
        {isPos && (
          <Row>
            <CustomTextField
              id="dailyCapacity"
              label="PRZEPUSTOWOŚĆ"
              disabled={selectedTankId === 0}
              value={formik.values.dailyCapacity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.dailyCapacity &&
                Boolean(formik.errors.dailyCapacity)
              }
              helperText={
                formik.touched.dailyCapacity && formik.errors.dailyCapacity
              }
              inputProps={{
                endAdornment: (
                  <>
                    <p>m³/doba</p>
                  </>
                ),
              }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <CustomTextField
                id="emptyingFrequency"
                shortLabel="CZĘSTOTLIWOŚĆ O... *"
                label="CZĘSTOTLIWOŚĆ OPRÓŻNIANIA *"
                disabled={selectedTankId === 0}
                value={formik.values.emptyingFrequency}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.handleChange(e);
                }}
                error={
                  formik.touched.emptyingFrequency &&
                  Boolean(formik.errors.emptyingFrequency)
                }
                sx={{ flex: 1 }}
                inputProps={{
                  endAdornment: (
                    <>
                      <p>miesiące</p>
                    </>
                  ),
                }}
              />
              <CustomFormHelperText
                error={Boolean(formik?.errors?.emptyingFrequency)}
              >
                {formik.errors.emptyingFrequency
                  ? formik.errors.emptyingFrequency
                  : '* zgodnie z DTR'}
              </CustomFormHelperText>
            </Box>
          </Row>
        )}
        {/* Data uruchomienia zbiornika POS*/}
        <Row sx={{ justifyContent: 'flex-end' }}>
          {isPos && (
            <CalendarWrapper sx={{ flex: 1 }}>
              <DateSelector
                formik={formik}
                label="DATA URUCHOMIENIA *"
                fieldName="launchDate"
                required={false}
                disabled={selectedTankId === 0}
              />
            </CalendarWrapper>
          )}
          {/* Rodzaj ścieków*/}
          <FormControl sx={{ flex: 1 }} margin="normal">
            <CustomSelect
              label="RODZAJ ŚCIEKÓW *"
              value={formik.values.sewageType}
              error={formik.errors.sewageType}
              onChange={formik.handleChange}
              disabled={selectedTankId === 0}
              id="sewageType"
            >
              <MenuItem value="BYTOWE" data-cy={'bytowe-option'}>
                Bytowe
              </MenuItem>
              <MenuItem value="PRZEMYSŁOWE" data-cy={'przemysłowe-option'}>
                Przemysłowe
              </MenuItem>
            </CustomSelect>
          </FormControl>
        </Row>
        {/* Źródło wody i liczba zameldowanych*/}
        <Row>
          <FormControl fullWidth margin="normal">
            <CustomSelect
              label="ŹRÓDŁO WODY"
              value={formik.values.waterSource}
              onChange={formik.handleChange}
              disabled={selectedTankId === 0}
              id="waterSource"
            >
              {/* @ts-ignore */}
              <MenuItem key="waterworks" value={false}>
                Wodociąg
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem key="private" value={true}>
                Własne ujęcie wody
              </MenuItem>
            </CustomSelect>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <CustomSelect
              label="LICZBA ZAMELDOWANYCH"
              value={formik.values.registeredPersons}
              error={formik.errors.registeredPersons}
              onChange={formik.handleChange}
              disabled={selectedTankId === 0}
              id="registeredPersons"
            >
              {residents &&
                residents.map(
                  (resident: {
                    id: number | string;
                    name: string | number;
                  }) => (
                    <MenuItem key={resident.id} value={resident.id}>
                      {resident.name}
                    </MenuItem>
                  )
                )}
            </CustomSelect>
          </FormControl>
        </Row>
        <Row>
          <CalendarWrapper
            sx={{ flex: `${formik.values.sewageSystem ? 1 : 0.48}` }}
          >
            <DateSelector
              formik={formik}
              label="DATA MONTAŻU ZBIORNIKA"
              fieldName="cesspoolTankInstallationDate"
              required={false}
              disabled={selectedTankId === 0}
            />
          </CalendarWrapper>
          {formik.values.sewageSystem && (
            <CalendarWrapper sx={{ flex: 1 }}>
              <DateSelector
                formik={formik}
                label="DATA PODŁĄCZENIA DO KANALIZACJI"
                fieldName="sewageSystemConnectionDate"
                required={false}
                disabled={selectedTankId === 0}
              />
            </CalendarWrapper>
          )}
        </Row>
        <Row sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Switch
            id="sewageSystem"
            name="sewageSystem"
            color="primary"
            checked={formik.values.sewageSystem}
            onChange={formik.handleChange}
            data-cy="sewageSystem"
          />
          <p>Nieruchomość jest podłączona do systemu kanalizacji sanitarnej</p>
        </Row>
      </Skeletons>

      <Row
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GradientButton
          sx={{ marginTop: '1.5em', marginBottom: '1.5em' }}
          disabled={
            selectedTankId === 0 || tankHaveActiveOrder || showSkeletons
          }
          type="submit"
          loading={loading}
          data-cy="save_tank_data"
        >
          Zapisz zmiany
        </GradientButton>
      </Row>
      {tankHaveActiveOrder && formik.dirty && (
        <RedText>
          Odblokujemy edycję danych zbiornika po wykonaniu bieżącego zamówienia
        </RedText>
      )}
    </Box>
  );
};

export default TankDataForm;
