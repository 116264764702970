import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';

import { Box, Checkbox, FormLabel, Link } from '@mui/material';
import validator from '@forms/Settings/ContactForm/validator';
import {
  ContactDataValuesInterface,
  UpdateClientInterface,
} from '@forms/Settings/ContactForm/interface';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, ClientData, AuthApiResult } from '@redux/interfaces';
import {
  getClientContactData,
  updateClient,
} from '@redux/userSettings/myAccount/actions';
import {
  CardDescription,
  CardTitle,
  GradientButton,
  JustRow,
} from '@styles/styled';
import { styled } from '@mui/material/styles';
import HideComponentArrow from '@components/elements/HideComponentArrow';
import CustomTextField from '@components/elements/CustomTextField';
import IsCompanyRadioSelect from '@components/elements/IsCompanyRadioSelect';

const initialValues: ContactDataValuesInterface = {
  name: '',
  surname: '',
  email: '',
  phone: '',
  dataProcessing: false,
  clientCompanyName: null,
  clientType: 'T_PER',
};

const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: 990px) {
    flex-direction: column;
    gap: 0;
  }
  @media (max-width: 905px) {
    flex-direction: row;
    gap: 30px;
  }

  @media (max-width: 660px) {
    flex-direction: column;
    gap: 0;
  }
`;

const ContactForm: React.FC = () => {
  const [isHided, setIsHided] = useState(false);
  const [fullDescription, setFullDescription] = useState<boolean>(false);

  const { loading, client } = useSelector<RootState, ClientData>(
    (state) => state.contact
  );

  const { documents } = useSelector<RootState, AuthApiResult>(
    (state) => state.auth
  );

  const getDocumentUri = (type: string) => {
    const item = documents.find((item) => item.type_code === type);

    return item?.uri;
  };

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validator,
    onSubmit: (values) => {
      const {
        clientType,
        email,
        name,
        surname,
        phone,
        dataProcessing,
        clientCompanyName,
      } = values;

      const data: UpdateClientInterface = {
        username: email,
        first_name: name,
        last_name: surname,
        phone_nb: phone,
        promotions: dataProcessing,
        email: email,
        ...(clientCompanyName &&
          clientType === 'T_COM' && {
            client_company_name: clientCompanyName,
          }),
      };

      dispatch(updateClient(data));
    },
  });

  useEffect(() => {
    if (client.email) {
      const newState = {
        name: client.first_name,
        surname: client.last_name,
        email: client.email,
        phone: client.phone_nb,
        dataProcessing: client.promotions,
        clientCompanyName: client.client_company_name,
        clientType: client.client_company_name ? 'T_COM' : 'T_PER',
      };

      formik.setValues(newState);
    } else {
      dispatch(getClientContactData());
    }
  }, [client]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <JustRow>
        <Box>
          <CardTitle sx={{ mt: 1 }}>Dane kontaktowe</CardTitle>
          {isHided && (
            <CardDescription sx={{ mb: 3 }}>
              Uzupełnij lub edytuj swoje dane osobowe
            </CardDescription>
          )}
        </Box>
        <HideComponentArrow
          ishided={isHided}
          handleHide={() => setIsHided((prevState) => !prevState)}
        />
      </JustRow>
      {isHided && (
        <>
          <IsCompanyRadioSelect formik={formik} />
          {formik.values.clientType === 'T_COM' && (
            <CustomTextField
              id="clientCompanyName"
              label="NAZWA FIRMY"
              value={formik.values.clientCompanyName}
              onChange={formik.handleChange}
              error={
                formik.touched.clientCompanyName &&
                Boolean(formik.errors.clientCompanyName)
              }
              helperText={
                formik.touched.clientCompanyName &&
                formik.errors.clientCompanyName
              }
            />
          )}
          <Row>
            <CustomTextField
              id="name"
              label="IMIĘ"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <CustomTextField
              id="surname"
              label="NAZWISKO"
              value={formik.values.surname}
              onChange={formik.handleChange}
              error={formik.touched.surname && Boolean(formik.errors.surname)}
              helperText={formik.touched.surname && formik.errors.surname}
            />
          </Row>
          <Row>
            <CustomTextField
              id="phone"
              label="TELEFON"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <CustomTextField
              id="email"
              label="E-MAIL"
              disabled={true}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Row>
          <Row sx={{ mt: 2 }}>
            <Checkbox
              id="dataProcessing"
              name="dataProcessing"
              color="primary"
              checked={formik.values.dataProcessing}
              onChange={formik.handleChange}
              data-cy="dataProcessing"
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <FormLabel sx={{ fontSize: 14 }}>
              Wyrażam zgodę na przetwarzanie moich danych osobowych przez Ścieki
              Polskie sp. z o.o.
              {fullDescription &&
                ' w celach marketingowych i promocyjnych z wykorzystaniem urządzeń telekomunikacyjnych oraz środków komunikacji elektronicznej oraz oświadczam, że znam i akceptuję '}
              {fullDescription && (
                <Link
                  target="_blank"
                  data-cy="dataProcessing-url"
                  rel="noreferrer"
                  href={getDocumentUri('DOC_APP_TERMS_AND_CONDITIONS')}
                >
                  postanowienia regulaminu.
                </Link>
              )}{' '}
              <Link
                onClick={() => setFullDescription((prevState) => !prevState)}
                sx={{
                  color: 'black',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                {fullDescription ? 'Pokaż mniej' : 'Pokaż więcej'}
              </Link>
            </FormLabel>
          </Row>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <GradientButton
              type="submit"
              variant="contained"
              sx={{ mt: 6, mb: 0 }}
              disabled={loading}
              loading={loading}
              data-cy="submit_save"
            >
              Zapisz zmiany
            </GradientButton>
          </Box>{' '}
        </>
      )}
    </Box>
  );
};

export default ContactForm;
