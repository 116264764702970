import { CreditCardInterface } from '@redux/interfaces';
import { styled } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';

import creditCard from '../../../../public/icons/credit-card-solid.svg';
import visa from '../../../../public/icons/cc-visa.svg';
import mastercard from '../../../../public/icons/cc-mastercard.svg';
import theme from '@styles/theme';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: ${(p: { card?: boolean }) => (p.card ? 'unset' : '700')};
`;

type ItemProps = {
  small?: boolean;
  mobile?: boolean;
};

export const Item = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: ${(p: ItemProps) => (p.small ? '15%' : '35%')};

  @media (max-width: 660px) {
    display: ${(p: ItemProps) => (p.mobile ? 'none' : 'flex')};
    width: ${(p: ItemProps) => (p.small ? '20%' : '40%')};
  }
`;

const DeleteButton = styled('button')`
  background-color: ${theme.palette.error.main};
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  padding: 5px 10px;

  &:hover {
    background-color: ${theme.palette.error.dark};
  }
`;

export const SingleCard = ({
  card,
  deleteCard,
}: {
  card?: CreditCardInterface;
  deleteCard?: (card: CreditCardInterface) => void;
}) => {
  const cardIcon = (brand: string) => {
    switch (brand) {
      case 'VISA':
        return <SvgIcon component={visa} viewBox="0 0 576 512" />;
      case 'MASTERCARD':
        return <SvgIcon component={mastercard} viewBox="0 0 576 512" />;
      default:
        return <SvgIcon component={creditCard} viewBox="0 0 576 512" />;
    }
  };

  return (
    <Wrapper card={Boolean(card)}>
      <Item small mobile>
        <p>{!card ? 'Marka' : cardIcon(card.card_brand)}</p>
      </Item>
      <Item>
        <p>{!card ? 'Numer karty' : card.number_masked}</p>
      </Item>
      <Item>
        <p>
          {!card
            ? 'Data ważności'
            : `${card.expiration_month}/${card.expiration_year}`}
        </p>
      </Item>
      <Item small>
        {!deleteCard ? (
          <p>Opcje</p>
        ) : (
          <DeleteButton
            onClick={() => {
              if (card) deleteCard(card);
            }}
          >
            Usuń
          </DeleteButton>
        )}
      </Item>
    </Wrapper>
  );
};
