import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import theme from '@styles/theme';

const { primary } = theme.palette;

const BoxRowWithPadding = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;

  @media (max-width: 660px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
  }
`;

const CustomTypography = styled(Typography)`
  font-weight: bold;
  text-align: right;

  @media (max-width: 660px) {
    text-align: left;
    margin-bottom: 5px;
  }
`;

const TopTypography = styled(CustomTypography)`
  text-align: left;
`;

const Divider = styled('div')`
  display: none;

  @media (max-width: 660px) {
    display: block;
    border-top: 1px solid #ddd;
    width: 100%;
    margin: 10px 0;
  }
`;

const SingleLine = ({
  label,
  value,
  element,
}: {
  label: string;
  value?: string | number;
  element?: JSX.Element;
}) => (
  <BoxRowWithPadding>
    <TopTypography>{label}</TopTypography>

    <>
      {element ? (
        <Box> {element}</Box>
      ) : (
        <CustomTypography color={primary.dark}>{value}</CustomTypography>
      )}
    </>
    <Divider />
  </BoxRowWithPadding>
);

export default SingleLine;
