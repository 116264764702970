'use client';

import AuthLayout from '@components/layouts/AuthLayout';
import LoginPageTemplate from '@components/templates/LoginPageTemplate/LoginPageTemplate';
import AuthBackgroundLayout from '@components/layouts/AuthBackgroundLayout';
import { InstallAppFromStore } from '@components/elements/InstallAppFromStore';

const LoginPage = () => {
  return (
    <AuthBackgroundLayout
      additionalStyle={{
        flexDirection: 'column',
      }}
    >
      <InstallAppFromStore />
      <AuthLayout>
        <LoginPageTemplate />
      </AuthLayout>
    </AuthBackgroundLayout>
  );
};

export default LoginPage;
