import theme from '@styles/theme';
import { WarningAmber } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

const NonTerytDataWarning = () => (
  <Box
    sx={{
      backgroundColor: theme.palette.warning.main,
      p: 1,
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 2,
      alignItems: 'center',
    }}
  >
    <WarningAmber sx={{ fontSize: 24, mr: 1, color: 'black' }} />
    <Typography>
      Ze względu na zmiany w formularzu prosimy o ponowne uzupełnienie danych
      billingowych.
    </Typography>
  </Box>
);

export default NonTerytDataWarning;
