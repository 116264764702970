import { Skeleton } from '@mui/material';
import React from 'react';
import { Row } from '@forms/TankDataForm/styled';

type PropsT = {
  loading: boolean;
  isPos: boolean;
  children?: React.ReactNode;
};

const SingleSkeleton = () => (
  <Skeleton
    variant="rounded"
    width="100%"
    height={56}
    sx={{ marginTop: '15px' }}
  />
);

const DoubleSkeletonInRow = () => (
  <Row>
    <SingleSkeleton />
    <SingleSkeleton />
  </Row>
);

const Skeletons = ({ loading, isPos, children }: PropsT) => {
  if (!loading) return <>{children}</>;
  return (
    <>
      <DoubleSkeletonInRow />
      <DoubleSkeletonInRow />
      {isPos && <DoubleSkeletonInRow />}
      <Row sx={{ justifyContent: 'flex-end' }}>
        {isPos && <SingleSkeleton />}
        <SingleSkeleton />
      </Row>
      <DoubleSkeletonInRow />
      <Row>
        <SingleSkeleton />
      </Row>
      <Row>
        <Skeleton
          variant="rounded"
          width="100%"
          height={40}
          sx={{ marginTop: '15px' }}
        />
      </Row>
    </>
  );
};

export default Skeletons;
