import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import FallowUs from '@components/elements/FallowUs';

import EnvelopeSvg from '../../../../public/icons/envelope.svg';

const Wrapper = styled(Box)`
  color: #506266;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 36px;

  @media (max-width: 660px) {
    flex-direction: column;
    margin-left: 0;
    margin-top: -30px;
  }
`;
const Title = styled(Typography)`
  font-size: 2rem;

  @media (max-width: 660px) {
    text-align: center;
  }
`;
const SubTitle = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-weight: bold;
  margin-right: 1.2rem;

  @media (max-width: 660px) {
    text-align: center;
    justify-content: center;
  }
`;

const FollowWrap = styled('div')`
  width: 100%;

  @media (min-width: 905px) {
    display: none;
  }
`;

const ContactWrapper = styled(Box)`
  display: flex;
  flex-direction: row;

  @media (max-width: 905px) {
    flex-direction: column;
  }
`;

const UpperFooter = () => (
  <Wrapper>
    <FollowWrap>
      <FallowUs />
    </FollowWrap>
    <Box>
      <Box>
        <Title variant={'subtitle1'} sx={{ marginBottom: '10px' }}>
          Masz pytania?
        </Title>
      </Box>
      <ContactWrapper>
        <SubTitle variant={'subtitle1'}>
          <EnvelopeSvg />
          pomoc@sciekipolskie.org
        </SubTitle>
      </ContactWrapper>
    </Box>
  </Wrapper>
);

export default UpperFooter;
